import React from 'react';
import { Link } from 'react-router-dom';
import styles from './voltar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


export default function Voltar({link , action}) {
    return (
        <Link to={link}>
            <div className={styles.backButton}>
                <FontAwesomeIcon icon={faArrowLeft}/>
            </div>
        </Link>
    )
}