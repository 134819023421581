import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import "moment/locale/pt-br";
import styles from './styles.module.scss';
import { getQuizById } from '../../../services/api';
import { useDispatch } from "react-redux";
import { 
    showMsgW as showMsgWAction,
    showMsgE as showMsgEAction,
    showMsgS as showMsgSAction
} from '../../../store/actions/snackbarActions.js';
import ModalLoading from '../../../components/ModalLoading';
import TextField from '@material-ui/core/TextField';
import { Select, MenuItem, InputLabel, FormControlLabel, Radio, RadioGroup, FormLabel, FormGroup } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { postResponseQuiz, getResultados } from '../../../services/api';
import Modal from '../../../components/Modal/Modal';

const dataInit = {
    titulo: "",
    textoDestaque: "",
    periodoAtividade: 0,
    status: 1,
    dataInicio: null,
    dataFim: null,
    urlImagemMobile: "",
    urlImagemDesktop: "",
    tipo: {
        quizTipoId: "7D9C0637-CF9F-485E-8196-347F81E9E4AD"
    },
    ativo: true,
    perguntas: [{
        ordem: 1,
        texto: "",
        pontos: null,
        respostas: [
            {
                ordem: 1,
                texto: "",
                correta: true
            },
            {
                ordem: 2,
                texto: "",
                correta: false
            },
            {
                ordem: 3,
                texto: "",
                correta: false
            }]
        }]
}

const questions = {
    perguntas: [
      {
        quizPerguntaId: "",
        respostas: [
          {
            quizRespostaId: "",
            selecionada: false
          }
        ]
      }
    ]
  }


const QuizDetalhe = () => {
    const dispatch = useDispatch();
    const showMsgW = (msg) => dispatch(showMsgWAction(msg));
    const showMsgE = (msg) => dispatch(showMsgEAction(msg));
    const showMsgS = (msg) => dispatch(showMsgSAction(msg));

    const history = useHistory();
    const [data, setData] = useState(dataInit);
    const [dataQuestions, setDataQuestions] = useState(questions);
    const [dataQuestionsAnsewered, setDataQuestionsAnsewered] = useState({});
    const [modalLoading, setModalLoading] = useState(false);
    const [quizId, setQuizId] = useState();
    const [quizRespostaId, setQuizRespostaId] = useState("");
    const [pageQuestion, setPageQuestion] = useState(1);
    const [openModal, setOpenModal] = useState(false);

    const getQuizIfEdit = async (id) => {

        if(id) {
            setModalLoading(true);
            try {
                const info = await getQuizById(id);
                setData(info.data);

                if(info.data.respondido) {
                    const resp = await getResultados(id);
                    setDataQuestionsAnsewered(resp.data);
                    setOpenModal(true);
                }

                let quest = { perguntas: [] };

                info.data.perguntas.forEach(x => {
                    var q = {
                        quizPerguntaId: x.quizPerguntaId,
                        respostas: []
                      };

                    x.respostas.forEach(r => {
                        var resp = {
                            quizRespostaId: r.quizRespostaId,
                            selecionada: false
                          };
                        
                        q.respostas.push(resp);
                    });
                      
                    quest.perguntas.push(q);
                });

                setDataQuestions(quest);

            } catch(e){
                showMsgW('Não foi possível carregar os dados');
            }
            setModalLoading(false);
        }
    }

    const enviar = async() => {
        let formdata = dataQuestions;
        setModalLoading(true);
        try{
            let info = await postResponseQuiz(quizId, formdata);
            if(info.data) {

                showMsgS("Sua resposta foi enviada com sucesso!")
                
                const resp = await getResultados(info.data.quizId);
                setDataQuestionsAnsewered(resp.data);
                setOpenModal(true);
                setModalLoading(false);
            }
        } catch(e) {
            showMsgE(e?.response?.data?.message);
            setModalLoading(false);
        }
        
    }

    function paginate (array, page_number, page_size = 1) {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    function compare( a, b ) {
        if ( a.ordem < b.ordem ){
            return -1;
        }
        if ( a.ordem > b.ordem ){
            return 1;
        }
        return 0;
    }

    const setArrayRespostaData = (pId, rId) => {

        let dataModify = dataQuestions.perguntas.find(x => x.quizPerguntaId === pId);

        let dataResp = dataModify.respostas.find(x => x.quizRespostaId === rId);

        dataResp.selecionada = true;

        dataModify.respostas.filter(x => x.quizRespostaId !== rId).forEach(x => {
            x.selecionada = false;
        });

        setModalLoading(true);
        setQuizRespostaId(rId);
        setTimeout(() => {
            setPageQuestion(pageQuestion + 1);

            if(pageQuestion === dataQuestions.perguntas.length)
                enviar();
            else
                setModalLoading(false);

            setQuizRespostaId("");
            
        },1000);


    }

    useEffect( () => {
        const id = new URLSearchParams(history.location.search).get("QuizId");
        setQuizId(id);
        getQuizIfEdit(id);
    },[])

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    {data.titulo}
                    <button className={styles.backButton} onClick={() => {history.goBack()}}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                </div>
                
                {   data.perguntas && 
                    paginate(data.perguntas.sort(compare), pageQuestion).map((item, i) => (

                        <div className={styles.blueBackground}>
                            <div className={styles.content}>
                            <div className={styles.contentQuestions}>
                                <div><small>{`Pregunta ${pageQuestion} de ${data.perguntas.length}`}</small></div>
                                <div className={styles.subtitle}>{item.texto}</div>
                                <div><small>Selecione a resposta correta</small></div>

                                    { 
                                        dataQuestions?.perguntas?.find(x => x.quizPerguntaId === item.quizPerguntaId)?.respostas.map((resp, r) => (
                                            <>
                                            
                                                <FormControl className={`${styles.halfRadioText} ${dataQuestions?.perguntas?.find(x => x.quizPerguntaId === item.quizPerguntaId)?.respostas?.find(x => x.quizRespostaId === resp.quizRespostaId)?.selecionada ? styles.selected : ""}`}>
                                                    <div className={styles.textResp}>{item.respostas.find(x => x.quizRespostaId === resp.quizRespostaId).texto}</div>
                                                    <RadioGroup
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        defaultValue={""}
                                                        value={quizRespostaId}
                                                        style={{flexWrap: "nowrap", position: "relative"}}
                                                        onClick={(e) => {setArrayRespostaData(item.quizPerguntaId, resp.quizRespostaId); }}
                                                        className={styles.radioResp}
                                                    >
                                                        <FormControlLabel value={resp.quizRespostaId} control={<Radio style={{color: "#5CCBE1"}}/>} label={""} />
                                                    </RadioGroup>
                                                </FormControl>
                                                
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }

                {/* <div className={styles.blueBackground}>
                    <div className={styles.content}>
                        <button className={styles.adicionar} onClick={enviar}>
                            VER RESULTADO
                        </button>
                    </div>
                </div> */}
                
            </div>

            <Modal 
                open={openModal} 
                size="md"
                >
                <div className="pageBody">
                    <div className="existeParceiroPage">

                        <div className="container-fluid row">
                            <div className='text-left'>
                                <h1><b>Resultado</b></h1>
                            </div>
                            <div className={styles.subtitle}>
                                Parabéns! Você concluiu o quiz. <br/>
                                Confira os resultados.
                            </div>
                        </div>

                        { dataQuestionsAnsewered?.perguntas?.length > 0 &&
                            dataQuestionsAnsewered?.perguntas?.map((p, pos) => (
                                <div className={`${styles.divCardAnsewer} ${p.respostas.find(x => x.selecionada && x.correta) ? styles.correct : ""}`}>
                                    <div className={styles.divTitle}>
                                        {`Pergunta ${pos + 1} de ${dataQuestionsAnsewered?.perguntas?.length}`}
                                    </div>
                                    <div className={styles.divAnsewer}>
                                        <div>{p.respostas?.find(x => x.selecionada)?.texto}</div>
                                        <div className={styles.icons}><div>{ p.respostas.find(x => x.selecionada && x.correta) ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/> }</div></div>
                                    </div>
                                    { p.respostas.find(x => !x.selecionada && x.correta) &&
                                        <div className={styles.divAnsewerCorrect}>
                                            <div className={styles.divAnsewerCorrectTitle}>
                                                <small>Resposta correta:</small>
                                            </div>
                                            <div className={styles.divAnsewerCorrectText}>
                                                {p.respostas?.find(x => x.correta)?.texto}
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))
                        }

                        <div className="container padB20 button" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                            <button className={styles.salvar} onClick={() => { history.push('/quiz') }}>
                                Jogar mais
                            </button>
                        </div>

                    </div>
                </div>
            </Modal>

            <ModalLoading
                open={modalLoading} 
                size="lg"
            />

        </>
    );
}

export default QuizDetalhe;