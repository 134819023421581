import React, {useRef, useEffect, useState} from 'react';
import styles from './styles.module.scss';



const ProgressBar = ({ value, maxValue}) => {

    const ref = useRef(null)
    const negativeRelation = (maxValue - value)/maxValue;
    const [negativeSize, setNegativeSize] = useState(0);

    useEffect(()=>{
        const size = ref?.current?.offsetWidth ? (ref?.current?.offsetWidth * negativeRelation) - 2 : 0;
        setNegativeSize(size)
    },[])
    
    const style = {
        width: negativeSize,
        borderRadius: negativeRelation === 1 ? '5px' : '0 5px 5px 0'
    }

    return(
        <div className={styles.wrapper}>
            <div className={styles.bar} ref={ref}>
                <div className={styles.negativeBar} style={style}/>
            </div>
        </div>
    )
}

export default ProgressBar