import React, { useState, useEffect } from 'react';
import services from '../../services/index';
import './styles.scss';

// redux
import { useDispatch } from 'react-redux';
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction,
} from '../../store/actions/snackbarActions.js';
import Modal from '../../components/Modal/Modal';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/pt-br';

const ModalConfirmacao = ({ popUp, setPopUp }) => {
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const showMsgS = (msg) => dispatch(showMsgSAction(msg));

  const [formPage2, setFormPage2] = useState('');
  const [formPage, setFormPage] = useState({});
  const [indicarErros, setIndicarErros] = useState({ erro: false });

  useEffect(() => {
    if (popUp?.camposValidar) {
      for (const campo of popUp.camposValidar.split('|')) {
        if (campo.includes('data')) {
          changes(null, campo);
        }
      }
    }
  }, [popUp?.camposValidar]);

  const changes = (value, campo) => {
    setFormPage((prevstate) => ({ ...prevstate, [campo]: value }));
  };

  const validarForm = () => {
    let valid = true;
    let msg = '';
    let erros = null;

    if (popUp?.camposValidar) {
      for (const campo of popUp.camposValidar.split('|')) {
        let valor = formPage[campo];
        if (typeof valor !== 'object') valor = valor?.trim();

        if (!valor) {
          if (valid) msg = 'Informe os campos obrigatórios';
          valid = false;
          erros = { ...erros, [campo]: 'Campo obrigatório' };
        } else {
          if (campo.includes('nome')) {
            const validNome = services.validate.validadeNome(valor);
            if (validNome.somenteUmNome) {
              if (valid) msg = 'Informe os campos corretamente';
              valid = false;
              erros = {
                ...erros,
                [campo]:
                  'Campo deve conter no mínimo 2 nomes (nome e sobrenome) separados por espaço',
              };
            }
            if (validNome.numerosOuEspeciais) {
              if (valid) msg = 'Informe os campos corretamente';
              valid = false;
              erros = {
                ...erros,
                [campo]:
                  'Campo não pode conter elementos alfanuméricos nem caracteres especiais',
              };
            }
            const palavraReservada = validNome.palavraReservada;
            if (palavraReservada) {
              if (valid) msg = 'Informe os campos corretamente';
              valid = false;
              erros = {
                ...erros,
                [campo]: 'Campo não pode conter a palavra ' + palavraReservada,
              };
            }
          }
          if (campo.includes('cpf')) {
            if (!services.validate.valideCpf(services.mask.unMask(valor))) {
              if (valid) msg = 'Informe os campos corretamente';
              valid = false;
              erros = { ...erros, [campo]: 'CPF inválido' };
            }
          }
          if (
            campo.includes('phone') ||
            campo.includes('fone') ||
            campo.includes('celular')
          ) {
            if (
              !services.validate.valideCellphone(services.mask.unMask(valor))
            ) {
              if (valid) msg = 'Informe os campos corretamente';
              valid = false;
              erros = { ...erros, [campo]: 'Celular inválido' };
            }
          }
          if (campo.includes('email')) {
            if (!services.validate.valideMail(valor)) {
              if (valid) msg = 'Informe os campos corretamente';
              valid = false;
              erros = { ...erros, [campo]: 'E-mail inválido' };
            }
          }
          if (campo.includes('data')) {
            if (!moment(valor).isValid()) {
              if (valid) msg = 'Informe os campos corretamente';
              valid = false;
              erros = { ...erros, [campo]: 'Data inválida' };
            }
          }
        }
      }
    }

    setIndicarErros({ ...erros, erro: true });

    if (msg) {
      showMsgW(msg);
    } else if (!valid) {
      showMsgW('Informe todos os campos obrigatórios');
    }

    return valid;
  };

  const salvar = async () => {
    if (validarForm()) {
      setIndicarErros({ ...indicarErros, erro: false });
      try {
        const response = await services.api.postConfirmacaoPopUp(
          popUp?.endPointValidar,
          formPage
        );
        services.storage.set('isNavegationBlocked', false);
        setPopUp({
          ...popUp,
          open: false,
          msg: '',
        });
        showMsgS('Dados salvos com sucesso!');
        window.location.reload();
      } catch (e) {
        showMsgE('Ocorreu um erro ao salvar os dados');
      }
    }
  };

  const salvar2 = async () => {
    try {
      const response = await services.api.postConfirmacaoVisualizacaoPopUp(
        formPage2
      );
      services.storage.set('isNavegationBlocked', false);
      setPopUp({
        ...popUp,
        open: false,
        msg: '',
      });
      showMsgS('Dados salvos com sucesso!');
      window.location.reload();
    } catch (e) {
      showMsgE('Ocorreu um erro ao salvar os dados');
    }
  };

  useEffect(() => {
    if (formPage2) {
      salvar2();
    }
  }, [formPage2]);

  return (
    <Modal open={popUp?.open} type={'popUpConfirmacao'}>
      <div className="pad20">
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-sm-12 text-center">
            <div dangerouslySetInnerHTML={{ __html: popUp?.mensagem }} />
          </div>
          <div>
            <div className="d-none d-md-flex">
              <img width={'100%'} src={popUp?.urlImagemDesktop} />
            </div>
            <div className="d-flex d-md-none">
              {popUp?.urlImagemMobile && (
                <img width={'100%'} src={popUp?.urlImagemMobile} />
              )}
            </div>
          </div>
          {popUp?.camposValidar &&
            popUp.camposValidar.split('|').map((item) => (
              <div className="col-sm-12" key={item}>
                {item.includes('nome') && (
                  <FormControl>
                    <TextField
                      label="Nome Completo"
                      type="text"
                      size="small"
                      className="customFilledInput"
                      onChange={(e) => changes(e.target.value, item)}
                      value={formPage[item] || ''}
                      helperText={indicarErros.erro && indicarErros[item]}
                      error={Boolean(indicarErros.erro && indicarErros[item])}
                      variant="filled"
                    />
                  </FormControl>
                )}
                {item.includes('cpf') && (
                  <FormControl>
                    <TextField
                      label="CPF"
                      type="text"
                      size="small"
                      className="customFilledInput"
                      onChange={(e) => changes(e.target.value, item)}
                      value={services.mask.cpf(formPage[item]) || ''}
                      helperText={indicarErros.erro && indicarErros[item]}
                      error={Boolean(indicarErros.erro && indicarErros[item])}
                      variant="filled"
                    />
                  </FormControl>
                )}
                {(item.includes('phone') ||
                  item.includes('fone') ||
                  item.includes('celular')) && (
                  <FormControl>
                    <TextField
                      label="Celular"
                      type="text"
                      size="small"
                      className="customFilledInput"
                      onChange={(e) => changes(e.target.value, item)}
                      value={services.mask.phone(formPage[item]) || ''}
                      helperText={indicarErros.erro && indicarErros[item]}
                      error={Boolean(indicarErros.erro && indicarErros[item])}
                      variant="filled"
                    />
                  </FormControl>
                )}
                {item.includes('email') && (
                  <FormControl>
                    <TextField
                      label="E-mail"
                      type="text"
                      size="small"
                      className="customFilledInput"
                      onChange={(e) => changes(e.target.value, item)}
                      value={formPage[item] || ''}
                      helperText={indicarErros.erro && indicarErros[item]}
                      error={Boolean(indicarErros.erro && indicarErros[item])}
                      variant="filled"
                    />
                  </FormControl>
                )}
                {item.includes('data') && (
                  <FormControl>
                    <MuiPickersUtilsProvider
                      locale="pt-br"
                      libInstance={moment}
                      utils={MomentUtils}
                    >
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Data de Nascimento"
                        format="DD/MM/yyyy"
                        value={formPage[item] || null}
                        InputAdornmentProps={{ position: 'start' }}
                        onChange={(novaData) => changes(novaData, item)}
                        className="customFilledInput"
                        helperText={indicarErros.erro && indicarErros[item]}
                        error={Boolean(indicarErros.erro && indicarErros[item])}
                        invalidDateMessage="Data em formato inválido."
                        maxDateMessage="Data não deve ser maior que a data máxima."
                        minDateMessage="Data não deve ser menor que a data mínima."
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                )}
              </div>
            ))}
          <div className="col-sm-12 text-center">
            {!popUp?.endPointValidar && (
              <button
                onClick={() => {
                  setPopUp({
                    ...popUp,
                    open: false,
                    msg: '',
                  });
                  setFormPage(popUp?.popUpId);
                }}
                className="btn btn-outline-info btn-lg mt20 padL40 padR40 mr10"
              >
                Continuar
              </button>
            )}
            {popUp?.camposValidar === '' && popUp?.endPointValidar ? (
              <button
                onClick={() => {
                  setPopUp({
                    ...popUp,
                    open: false,
                    msg: '',
                  });
                  setFormPage2(popUp?.popUpId);
                }}
                className="btn btn-outline-info btn-lg mt20 padL40 padR40 mr10"
              >
                OK, ciente!
              </button>
            ) : (
              <button
                onClick={salvar}
                className="btn btn-outline-info btn-lg mt20 padL40 padR40 mr10"
              >
                Salvar
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirmacao;
