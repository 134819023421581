import React, {useState, useEffect} from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from "react-redux"
import {  showMsgE as showMsgEAction } from '../../../store/actions/snackbarActions.js';
import services from '../../../services';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'
import { cnpj, cpf } from '../../../services/mask';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { useGetUser } from '../../../hooks/useGetUser';
import styles from './styles.scss';
import { useHistory } from 'react-router-dom';



const DadosEmpresa = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const [participantes, setParticipantes] = useState([]);
  const [selectedCnpj, setSelectedCnpj] = useState('');
  const [selectedCodigo, setSelectedCodigo] = useState('');
  const [textFilter, setTextFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [permiteAdicionarParticipante, setPermiteAdicionarParticipante] = useState(services.storage.getClaimValues("Participantes", "Cadastrar"));

  const [parceiros, setParceiros] = useState([{
    "cnpj" : "",
    "razaoSocial" : "",
    "nomeFantasia" : "",
    "regional" : "",
    "filial" : "",
    "grupoEconomico" : "",
    "idParceiro": "",
    "codigo": ""
  }]);

  const addParticipante = () => {
    let id = parceiros.find(p => p.codigo === selectedCodigo).idParceiro
    history.push(`/cadastro-loja/participante/${id}/null`)
  };
  
  const user = useGetUser();
  useEffect(() => {
    getParceiros();
  }, [setParceiros]);

  useEffect(() => {
  }, [textFilter]);

  const getParceiros = () => {
    trackPromise (
      services.api.getResponsavelParceiros()
      .then(res => {
        if (res) {
            if(res.length > 0) {
              setSelectedCnpj(res[0].cnpj);
              setSelectedCodigo(res[0].codigo);
              getParticipantes(res[0].idParceiro, 'id');
            }
            setParceiros(res);
        } else {
          showMsgE(res.errors ? res.errors.message : 'Erro')
        }
      }).catch(error => { 
        showMsgE(error.errors.length ? error.errors[0].message : 'Error')
      })
    )
  }  
  const getParceirosPesquisa = () => {
    let param = parceiros.find(p => p.cnpj === selectedCnpj)
    trackPromise (
      services.api.getParceirosSearch(param.idParceiro,textFilter)
      .then(res => {
        if (res) {
            if(res.length > 0) {
              setParceiros(...res.data.data);
            }

        } else {
          showMsgE(res.errors ? res.errors.message : 'Erro')
        }
      }).catch(error => { 
        showMsgE(error.errors.length ? error.errors[0].message : 'Error')
      })
    )
  }

  const getParticipantes = (value, param) => {
    setLoading(true);
    let id = null;

    if(param === 'id'){
      id = value;
    } else if (param === 'cod'){
      let getParceiro = parceiros.find(p => p.codigo === value);
      id = getParceiro.idParceiro;
      setSelectedCnpj(getParceiro.cnpj);
    }

    if (id !== null){
      trackPromise (
        services.api.getParticipantes(id)
        .then(res => {
          if (!res.fail) {
            setParticipantes([...res.data.data]);
          } else {
            showMsgE(res.errors ? res.errors.message : 'Erro')
          }
          setLoading(false);
        }).catch(error => { 
          showMsgE(error.errors.length ? error.errors[0].message : 'Error');
          setLoading(false);
        })
      )
    }
  }

  
  return (
    <div className="pageBody pb-5">
      <div className="container Dados">

        <div className="row">
          <div className='col '>
            <h3>Dados da Empresa</h3>
          </div>
        </div>
        {parceiros && 
          <div className='dadosEmpresa'>
            <FormControl>
              <TextField label="Código" select size="small" className="customFilledInput"
                onChange={(event) => {setSelectedCodigo(event.target.value); getParticipantes(event.target.value, 'cod');}}
                value={selectedCodigo}
                variant="filled"
              >
                {parceiros.map((option) => (
                  <MenuItem key={option.codigo} value={option.codigo}>
                    {option.codigo}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl>
              <TextField label="CNPJ" size="small" className="customFilledInput"
                value={cnpj(parceiros?.find(x => x.cnpj === selectedCnpj)?.cnpj)}
                variant="filled"
                disabled
              />
            </FormControl>

            <FormControl>
              <TextField label="Razão social" type="text" size="small" className="customFilledInput"
                value={parceiros?.find(x => x.cnpj === selectedCnpj)?.razaoSocial}
                variant="filled"
                disabled
              />
            </FormControl>

            <FormControl>
              <TextField label="Nome fantasia" type="text" size="small" className="customFilledInput"
                value={parceiros?.find(x => x.cnpj === selectedCnpj)?.nomeFantasia}
                variant="filled"
                disabled
              />
            </FormControl>

            <FormControl>
              <TextField label="Regional" type="text" size="small" className="customFilledInput"
                value={parceiros?.find(x => x.cnpj === selectedCnpj)?.regional}
                variant="filled"
                disabled
              />
            </FormControl>

            <FormControl>
              <TextField label="Filial" type="text" size="small" className="customFilledInput"
                value={parceiros?.find(x => x.cnpj === selectedCnpj)?.filial}
                variant="filled"
                disabled
              />
            </FormControl>

            <FormControl>
              <TextField label="Grupo Econômico" type="text" size="small" className="customFilledInput"
                value={parceiros?.find(x => x.cnpj === selectedCnpj)?.grupoEconomico}
                variant="filled"
                disabled
              />
            </FormControl>
          </div>
        }
        <div className="row">
          <div className='col '>
            <h3>Participantes</h3>
          </div>
        </div>

        {
         (permiteAdicionarParticipante) && (
             <div>
                 <button className={`btnInvert ${styles.btAdd}`} onClick={addParticipante}>
                     Adicionar Participante
                 </button>
             </div>
         )
        }

        <div className='divSearch'>
          <FormControl>
            <TextField 
              label="Faça uma pesquisa" 
              type="text" size="small" 
              className="customFilledInput searchParticipants"
              value={textFilter}
              variant="filled"
              onChange={((event) => {setTextFilter(event?.target.value)})}
              onKeyDown= {
                (e) => {
                  if (e.key === 'Enter') {
                      getParceirosPesquisa();
                      e.preventDefault();
                  }
                }
              }
              InputProps={{endAdornment: (
                <button onClick={getParceirosPesquisa} className={'searchButton'}>
                    <FontAwesomeIcon icon={faSearch}/>
                 </button>
              )}}
            />
          </FormControl>   
        </div>
        {participantes 
          && participantes.length > 0 
          && participantes.filter(x => (x.nome 
                                      + x.email 
                                      + x.cpf 
                                      + x.cargo 
                                      + `${x.status}`
                                      + `${x.regulamento}`).toUpperCase().includes(textFilter.toUpperCase()))
                          .map(p => (
            <div className='card-participant'>
              <div className='card-body-participant'>
                <FormControl>
                  <TextField label="Nome" type="text" size="small" className="customFilledInput"
                    value={p.nome}
                    variant="standard"
                    disabled
                  />
                </FormControl>
                <FormControl>
                  <TextField label="E-mail" type="text" size="small" className="customFilledInput"
                    value={p.email}
                    variant="standard"
                    disabled
                  />
                </FormControl>
                <FormControl>
                  <TextField label="CPF" type="text" size="small" className="customFilledInput"
                    value={cpf(p.cpf)}
                    variant="standard"
                    disabled
                  />
                </FormControl>
                <FormControl>
                  <TextField label="Cargo" type="text" size="small" className="customFilledInput"
                    value={p.cargo}
                    variant="standard"
                    disabled
                  />
                </FormControl>
                <FormControl>
                  <TextField label="Perfil de acesso" type="text" size="small" className="customFilledInput"
                    value={p.perfilAcesso}
                    variant="standard"
                    disabled
                  />
                </FormControl>
                <FormControl>
                  <TextField label="Regulamento" type="text" size="small" className="customFilledInput"
                    value={p.regulamento}
                    variant="standard"
                    disabled
                  />
                </FormControl>
              </div>
              <div className='barraStatus'>
                    <div className='Status'><span className={`${p.status}`}>{p.status}</span></div>
                    <div className='Edit'>
                      <Link to={`/cadastro-loja/participante/null/${p.id}`}>
                        <span>Editar <FontAwesomeIcon icon={faEdit}/></span>
                      </Link>
                    </div>
              </div>
            </div>
        ))}

        <table className='table table-participant'>
          <thead>
            <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>CPF</th>
                <th>Cargo</th>
                <th>Perfil de acesso</th>
                <th>Regulamento</th>
                <th className='text-center'>Status</th>
                <th></th>
            </tr>
          </thead>
          <tbody>
            {participantes 
              && participantes.length > 0 
              && participantes.filter(x => (x.nome 
                                            + x.email 
                                            + x.cpf 
                                            + x.cargo 
                                            + x.perfilAcesso
                                            + `${x.regulamento}`
                                            + `${x.status}`
                                            + x.userId
                                            ).toUpperCase().includes(textFilter.toUpperCase()))
                              .map(p => (
                <tr>
                  <td>{p.nome}</td>
                  <td>{p.email}</td>
                  <td>{cpf(p.cpf)}</td>
                  <td>{p.cargo}</td>
                  <td>{p.perfilAcesso}</td>
                  <td>{p.regulamento}</td>
                  <td>
                    <div className='Status'>
                      <span className={`${p.status}`}>{p.status}</span>
                    </div>
                  </td>
                  <td>
                    <div className='Edit'>
                      <Link to={`/cadastro-loja/participante/null/${p.userId}`}>
                        <span><FontAwesomeIcon icon={faEdit}/></span>
                      </Link>
                    </div>
                  </td>
                </tr>
            ))}
          </tbody>
        </table>

        {(participantes && participantes.filter(x => (x.nome 
                                                      + x.email 
                                                      + x.cpf 
                                                      + x.cargo 
                                                      + `${x.status}`
                                                      + `${x.regulamento}`).toUpperCase().includes(textFilter.toUpperCase())).length === 0)
         &&
         <>
          {
            loading ?
            <Loading color='#5CCBE1'/>
            :
            <div className='text-center'>
                <span>Sem dados de participantes para exibir</span>
            </div>
          }
         </>
        }
          
      </div>
    </div>
  );
}

export default DadosEmpresa;