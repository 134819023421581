var globals = {
  // ####  AUTO  ####
  // ####  AUTO  ####
  REACT_APP_APIROOT_AUTO: process.env.REACT_APP_APIROOT_AUTO,
  REACT_APP_RECAPTCHA_KEY_AUTO: process.env.REACT_APP_RECAPTCHA_KEY_AUTO,
  REACT_APP_CATALOG_AUTO: process.env.REACT_APP_CATALOG_AUTO,
  REACT_APP_APIADRESS: process.env.REACT_APP_APIADRESS,
  REACT_APP_AUTH_APP: process.env.REACT_APP_AUTH_APP,
  REACT_APP_MAIN_DOMAIN: process.env.REACT_APP_MAIN_DOMAIN,
  // ###########################################################################################################
};
export default globals;
 