import React, {useEffect, useState} from 'react'
import { getCardCampanha } from '../../services/api';
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom';

const HomeCardCampanha = ({campanha}) => {

    const history = useHistory()
    const [size, setSize] = useState(0);

    useEffect(() => {
        function getWidth() {
            
            setSize(window.innerWidth)
        }
    
        getWidth();
        window.addEventListener('resize', getWidth);
        return () => window.removeEventListener('resize', getWidth);
    }, []);

    const goto = () => {
        history.push(`campanhas/detalhes?campanha=${campanha.id}`)
    }

    return(
        <div className={styles.wrapper} onClick={goto}>
            <img className={styles.desktopImage} src={`data:${campanha.imagemLogo.contentType};base64,${campanha.imagemLogo.fileContents}`}/>
            <div className={styles.nome}>
                {getCardCampanha.nome}
            </div>
        </div>
    )
}

export default HomeCardCampanha;