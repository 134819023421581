import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import styles from './styles.module.scss';

import { useGetUser } from '../../hooks/useGetUser';

import homeMainImg from '../../assets/img/homeMain.png';
import HomeCardCampanha from '../../components/HomeCardCampanha';
import {
  getCardCampanha,
  cardCampanhaParticipanteDatas,
  getCatalogoSaldo,
} from '../../services/api';
import Loading from '../../components/Loading';
import Banner from '../../components/Banner';
import { getUser } from '../../services/storage'; 

const Home = (props) => {
  const history = useHistory();
  const [campanhas, setCampanhas] = useState([]);
  const [paginacao, setPaginacao] = useState(0);
  const [mais, setMais] = useState(true);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(0);
  const [saldo, setSaldo] = React.useState('');

  const getListCampanhas = async () => {
    setLoading(true);
    const today = new Date();
    const list = await cardCampanhaParticipanteDatas(
      paginacao + 1,
      today.getMonth() + 1,
      today.getFullYear()
    );
    setLoading(false);
    setCampanhas([...campanhas, ...list.data]);
    setPaginacao(paginacao + 1);
    setMaxPage(list.maxPage);
  };
  const getSaldo = async () => {
    var response = await getCatalogoSaldo();
    setSaldo(response.balance);
  };

  useEffect(() => {
    if (maxPage > paginacao + 1) {
      setMais(true);
    } else {
      setMais(false);
    }
  }, [paginacao, maxPage]);

  useEffect(() => {
    getListCampanhas();
  }, []);
  useEffect(() => {
    getSaldo();
  }, []);

  useEffect(() => {
    var user = getUser();

    if (!user?.cadastroCompleto || !user?.dataNascimento)
      history.push(`/meu-cadastro`)
  })

  return (
    <div className={styles.pageHome}> 
      <div class={styles.wrapper}>
        <h2 style={{color:'#fff'}} className={styles.mobile + " display-5 text-center mb-4 pb-6 pt-5 "}>
            Você tem: {saldo} ponto(s)
          </h2>
        <div className={styles.mainBannerWrapper}>
          <Banner />
        </div>
      </div>
      <div>
        <div className={styles.programasWrapper}>
          <div className={styles.title}>
            Quer ganhar mais benefícios? É fácil! Seja um parceiro ativo e
            participe das nossas campanhas😉
          </div>
        </div>
        {loading ? (
          <Loading color="#5CCBE1" />
        ) : (
          <div className={styles.displayCampanhaWrapper}>
            <div className={styles.cards}>
              {campanhas.map((campanha) => {
                return <HomeCardCampanha campanha={campanha} />;
              })}
            </div>
            {mais && (
              <div className={styles.verMaisWrapper}>
                <button
                  className={`customButton ${styles.verMaisButton}`}
                  onClick={getListCampanhas}
                >
                  Ver mais
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
