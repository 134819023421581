import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import DefaultRoutes from '../layouts/Defaultroutes';
import PrivateRoutes from '../layouts/Privateroutes';
import Login from '../views/Login/index';

import MeuCadastro from '../views/Cadastro/MeuCadastro';
import Campanhas from '../views/Campanhas';
import Detalhes from '../views/Campanhas/Detalhes';
import Beneficios from '../views/Beneficios';
import Desempenho from '../views/Desempenho';
import CadastroLoja from '../views/Cadastro/Loja/index';
import DadosParticipante from '../views/Cadastro/Loja/DadosParticipante';
import GaleriaVitrine from '../views/Galeria';
import DetalheGaleriaVitrine from '../views/Galeria/Detalhes';
import Regulamento from '../views/Regulamento';
import Home from '../views/Home/index'
import FAQ from '../views/FAQ';
import Quiz from '../views/Quiz';
import QuizDetalhe from '../views/Quiz/Detalhes';
import Treinamento from '../views/Treinamento';
import TreinamentoDetalhe from '../views/Treinamento/Detalhes';
import TreinamentoCertificado from '../views/Treinamento/Certificado';
import Conteudo from '../views/Conteudo';
import ConteudoDetalhe from '../views/Conteudo/Detalhes';
import Noticia from '../views/Noticias';
import NoticiaDetalhe from '../views/Noticias/Detalhes'; 
import Auth from '../views/Login/Auth';
import AutoLogout from '../components/Inatividade';
import Espelho from '../views/Login/Espelho'
export default function UserRoutes(){
return<>
<AutoLogout/>
    <BrowserRouter>
        <Switch>
            <Route path='/token/auth' exact component={Auth} />
            { (window.location.hostname === 'localhost') && <Route path='/' exact component={Login} /> }
            <Route path='/espelho' exact component={Espelho} />

            <PrivateRoutes path='/home' exact component={Home} />
            <PrivateRoutes path='/' exact component={Home} />
            <PrivateRoutes path='/conteudo' exact component={Conteudo}/>
            <PrivateRoutes path='/conteudo/detalhe' exact component={ConteudoDetalhe} />
            <PrivateRoutes path='/noticia' exact component={Noticia}/>
            <PrivateRoutes path='/noticia/detalhe' exact component={NoticiaDetalhe} />
            <PrivateRoutes path='/meu-cadastro' exact component={MeuCadastro} />
            <PrivateRoutes path='/campanhas' exact component={Campanhas} />
            <PrivateRoutes path='/campanhas/detalhes' exact component={Detalhes} />
            <PrivateRoutes path='/beneficios' exact component={Beneficios} />
            <PrivateRoutes path='/desempenho' exact component={Desempenho} />
            <PrivateRoutes path='/cadastro-loja' exact component={CadastroLoja} />
            <PrivateRoutes path='/cadastro-loja/participante/:idParceiro/:idParticipante' exact component={DadosParticipante} />
            <PrivateRoutes path='/faq' exact component={FAQ} />
            <PrivateRoutes path='/quiz' exact component={Quiz} />
            <PrivateRoutes path='/quiz-detalhe' exact component={QuizDetalhe} />
            <PrivateRoutes path='/treinamento' exact component={Treinamento} />
            <PrivateRoutes path='/treinamento-detalhe' exact component={TreinamentoDetalhe} />
            <PrivateRoutes path='/treinamento-certificado' exact component={TreinamentoCertificado} />
            <PrivateRoutes path='/regulamento' exact component={Regulamento} />

            <PrivateRoutes path='/galeria-vitrine' exact component={GaleriaVitrine} />
            <PrivateRoutes path='/galeria-vitrine/detalhe' exact component={DetalheGaleriaVitrine} />

            <DefaultRoutes/>
        </Switch>
    </BrowserRouter>
</>
}