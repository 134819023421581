import React, { useEffect, useState } from 'react';
import './styles.scss';

import services from '../../../../services';
import { useHistory } from 'react-router-dom';
import ArrowForward from '@material-ui/icons/ArrowForward';

import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import { getCatalogoSaldo } from '../../../../services/api';
import globals from '../../../../globals';

const MenuAlt = (props) => {
  const history = useHistory();

  const [openMenu, setOpenMenu] = useState(false);
  const [saldo, setSaldo] = React.useState('');
  const changeMenu = () => {
    setOpenMenu(!openMenu);
  };
  const getSaldo = async () => {
    var response = await getCatalogoSaldo();
    setSaldo(response.balance);
  };
  useEffect(() => {
    getSaldo();
  }, []);

  const sair = () => {
    
    services.api.sair();

    setTimeout(() => {
      services.storage.clear();
      window.location.href = globals.REACT_APP_AUTH_APP;
    }, 800)
  
  };


  return (
    <div className="menuAltWrapper" onClick={changeMenu}>
      <AccountCircleOutlined />
      <ul className={`${props.showMobile ? 'show' : ''} showOnlyDesktop`}>
        <li className="readOnly">
          <span> Olá, {props.nome?.split(' ')[0]} </span>
          <li>você tem: {saldo} ponto(s)</li>
        </li>
      </ul>
      <div className={openMenu ? 'menuAltButtons' : 'close'}>
        <a href="/meu-cadastro">
          Meus dados
          <ArrowForward htmlColor="#5CCBE1" className="menuArrow" />
        </a>
        <a href="/regulamento">
          Regulamento
          <ArrowForward htmlColor="#5CCBE1" className="menuArrow" />
        </a>
        <button onClick={sair}>
          Sair
          <ArrowForward htmlColor="#5CCBE1" className="menuArrow" />
        </button>
      </div>
    </div>
  );
};

export default MenuAlt;
