import React from 'react';
import FooterParticipant from '../components/FooterParticipant';
import HeaderParticipant from '../components/HeaderParticipant';
import logoWhatsApp from '../assets/img/whatsapp.png'

const FullPage = props => {
    return (
        <div id="wrapper">
            <div id="top">
                <HeaderParticipant />
            </div>

            {props.children}

{/*     
            <div style={{ backgroundColor: '#34af23', position: 'fixed', left: '2%', bottom: '15%', width:'60px', borderRadius: '15px', zIndex: 10000000 }}>
                <a href="https://wa.me/551123946370" target="_blank">
                    <img src={logoWhatsApp} alt="" style={{ filter: 'invert(1)', padding: '6px 13px 8px 10px', marginRight:'10px' ,width: '65px' }} />
                </a>
            </div> */}
            <FooterParticipant />
        </div>
    )
}

export default FullPage