import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.scss';

/////////////////////////////
// redux
import { useDispatch } from 'react-redux';
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction,
} from '../../../store/actions/snackbarActions.js';
import moment from 'moment';
import services from '../../../services';
import Modal from '../../../components/Modal/Modal';
import MeusDados from './MeusDados';

import { trackPromise } from 'react-promise-tracker';

import { useGetUser } from '../../../hooks/useGetUser';
import { getCatalogoLink } from '../../../services/api.js';

const formCadastroInitialState = {
  userId: '',
  userName: '',
  dataNascimento: null,
  email: '',
  nome: '',
  phoneNumber: '',
  roleId: '',
  perfilAcesso: '',
  perfilAcessoId: '',

  parceiros: [],

  cep1: '',
  logradouro1: '',
  numero1: '',
  complemento1: '',
  bairro1: '',
  uf1: '',
  cidade1: '',
  mesmoEnderecoEntrega: '',
  cep2: '',
  logradouro2: '',
  numero2: '',
  complemento2: '',
  bairro2: '',
  uf2: '',
  cidade2: '',

  senha: '',
  senhaConfirmar: '',
};

const initialModalMensagem = {
  open: false,
  msg: '',
};

const MeuCadastro = (props) => {
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const showMsgS = (msg) => dispatch(showMsgSAction(msg));
  const history = useHistory();
  const user = useGetUser();

  const [formCadastro, setFormCadastro] = useState({
    ...formCadastroInitialState,
  });
  const [indicarErrosCadastro, setIndicarErrosCadastro] = useState(false);

  const handleChangeForm = (value, campo) => {
    setFormCadastro((prevstate) => ({ ...prevstate, [campo]: value }));
  };

  const [modalMensagem, setModalMensagem] = useState(initialModalMensagem);
  const [cadastroConcluido, setCadastroConcluido] = useState(false);
  const handleOpenModalMensagem = (msg) => {
    setModalMensagem({
      ...modalMensagem,
      open: true,
      msg: msg,
    });
  };

  const closeModalMensagem = () => {
    setModalMensagem({
      ...modalMensagem,
      open: false,
      msg: '',
    });
  };

  const salvar = () => {
    if (validarForm('editar')) {
      setIndicarErrosCadastro(false);
      editar();
    } else {
      setIndicarErrosCadastro(true);
    }
  };
  const logarParticipante = async () => {
    var url = await getCatalogoLink();
  }

  const editar = () => {
    // Cria um objeto com os dados para enviar
    const objEnvio = {
      cep: services.mask.unMask(formCadastro.cep1),
      cidade1: {
        cidadeId: formCadastro.cidade1,
      },
      bairro: formCadastro.bairro1,
      logradouro: formCadastro.logradouro1,
      numero: formCadastro.numero1,
      complemento: formCadastro.complemento1,
      usarEnderecoEntrega: formCadastro.mesmoEnderecoEntrega,
      cep2: services.mask.unMask(formCadastro.cep2),
      cidade2: {
        cidadeId: formCadastro.cidade2,
      },
      bairro2: formCadastro.bairro2,
      logradouro2: formCadastro.logradouro2,
      numero2: formCadastro.numero2,
      complemento2: formCadastro.complemento2,
      nome: formCadastro.nome,
      email: formCadastro.email,
      cpf: services.mask.unMask(formCadastro.userName),
      dataNascimento: formCadastro.dataNascimento,
      phoneNumber: formCadastro.phoneNumber,
      nomePerfil: formCadastro.roleId,
      perfilAcesso: formCadastro.perfilAcesso,
      perfilAcessoId: formCadastro.perfilAcessoId,
    };

    if (formCadastro.senha) {
      objEnvio.senha = formCadastro.senha;
      objEnvio.confirmarSenha = formCadastro.senhaConfirmar;
    }
    trackPromise(
      services.api
        .candidatoEditarMeusDados(objEnvio)
        .then((res) => {
          if (res.data) {
            showMsgS('Cadastro editado com sucesso');
            logarParticipante()
            localStorage.setItem(
              'authSistema',
              JSON.stringify({
                ...services.storage.get('authSistema'),
                profile: res.data,
              })
            );

            // Redireciona se necessário
            if (res.data.cadastroCompleto && res.data.dataNascimento) {
              history.push('/');
            }
          } else {
            showMsgE(res.errors?.message || 'Erro');
          }
        })
        .catch((error) => {
          showMsgE(error.errors?.[0]?.message || 'Error');
        })
    );
  };



  const validarForm = () => {

    let valid = true;
    let msg = '';
    if (
      !formCadastro.nome ||
      !formCadastro.phoneNumber ||
      !formCadastro.roleId ||
      !formCadastro.email ||
      !formCadastro.dataNascimento ||
      !formCadastro.userName
    ) {
      msg = 'Informe os campos obrigatórios';
      valid = false;
    }
    if (formCadastro.nome.split(' ').length < 2) {
      msg =
        'O campo nome completo deverá conter no mínimo 2 nomes (nome e sobrenome) separados por espaço';
      valid = false;
    }
    if (
      !services.validate.valideCellphone(
        services.mask.unMask(formCadastro.phoneNumber)
      )
    ) {
      msg = 'Informe um número de celular válido';
      valid = false;
    }
    if (!services.validate.valideMail(formCadastro.email)) {
      msg = 'Informe um E-mail válido';
      valid = false;
    }
    if (
      !new moment(formCadastro.dataNascimento).isValid() &&
      formCadastro.dataNascimento
    ) {
      msg = 'Informe uma Data de Nascimento válida';
      valid = false;
    }
    if (
      !services.validate.valideCpf(services.mask.unMask(formCadastro.userName))
    ) {
      msg = 'Informe um CPF válido';
      valid = false;
    }

    if (formCadastro.senha) {
      if (!services.validate.validePasswordSize(formCadastro.senha)) {
        msg = 'Senha deve ter mínimo 8 caracteres';
        valid = false;
        console.log('teste1');
      }
      if (!services.validate.validePasswordNumber(formCadastro.senha)) {
        msg = 'Senha deve ter ao menos 1 número';
        valid = false;
        console.log('teste2');
      }
      if (!services.validate.validePasswordCapital(formCadastro.senha)) {
        msg = 'Senha deve ter letras maiúsculas e minúsculas';
        valid = false;
        console.log('teste3');
      }
      if (!services.validate.validePasswordSpecial(formCadastro.senha)) {
        msg = 'Senha deve ter caracteres especiais';
        valid = false;
        console.log('teste4');
      }
      if (formCadastro.senha !== formCadastro.senhaConfirmar) {
        msg = 'Senha e Confirme sua senha devem ser iguais';
        valid = false;
        console.log('teste5');
      }
        
    }

    if (msg) {
      showMsgW(msg);
    } else {
      if (!valid) showMsgW('Informe todos os campos obrigatórios');
    }
    return valid;

  };

  const loadCadastro = () => {
    trackPromise(
      services.api
        .getMeuCadastroCandidato()
        .then((res) => {
          if (res.data) {
            let cadastro = res.data;
            cadastro.roleId = res.data.role?.roleId;
            cadastro.senha = '';
            cadastro.senhaConfirmar = '';
            if (res.data.uf1) {
              cadastro.uf1 = res.data.uf1?.ufId;
            }
            if (res.data.cidade1) {
              cadastro.cidade1 = res.data.cidade1?.cidadeId;
            }
            if (res.data.uf2) {
              cadastro.uf2 = res.data.uf2?.ufId;
            }
            if (res.data.cidade2) {
              cadastro.cidade2 = res.data.cidade2?.cidadeId;
            }
            setFormCadastro(cadastro);
          } else {
            showMsgE(res.errors ? res.errors.message : 'Erro');
          }
        })
        .catch((error) => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error');
        })
    );
  };

  useEffect(() => {
    loadCadastro();
    if (!user.dataNascimento) {
      handleOpenModalMensagem(
        'Para continuar navegando preencha o campo Data de Nascimento.'
      );
    }
  }, [user.dataNascimento]);

  return (
    <div className="CadastroMeu">
   
      <MeusDados
        formPage={formCadastro}
        action={salvar}
        changes={handleChangeForm}
        indicarErros={indicarErrosCadastro}
        onlyRead={false}
      />
      {!user.dataNascimento && (
        <Modal open={modalMensagem.open} close={closeModalMensagem} size={'sm'}>
          <div className="pad20">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h3>{modalMensagem.msg}</h3>
              </div>

              <div className="col-sm-12 text-center">
                <button
                  onClick={closeModalMensagem}
                  className="btn btn-outline-info btn-lg mt20 padL40 padR40 mr10"
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MeuCadastro;
