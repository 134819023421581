import { useState, useEffect } from 'react';

import services from '../services';

export const useGetUser = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    function handleStatusChange(userLogado) {
      setUser(userLogado);
    }
    const userLogado = services.storage.getUser()
    if (userLogado) {handleStatusChange(userLogado)} 
  }, []);

  return user;
}