import React from 'react';
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom';
import moment from "moment";
import "moment/locale/pt-br";

const QuizGaleria = ({ quiz }) => {

    const history = useHistory();

    return (
        <div className={styles.wrapper}>
            <div className={styles.bodyCard}>
                <img className={styles.desktop} src={quiz.urlImagemDesktop} alt={quiz.titulo}/>
                <img className={styles.mobile} src={quiz.urlImagemMobile} alt={quiz.titulo}/>
            </div>

            <div className={styles.actionWrapper}>
                <small>Criando em {new moment(quiz.dateCreated).format('DD/MM/YYYY')}</small>
                <div>
                    <b>{quiz.titulo}</b>
                    <div className={styles.textoDestaque}>
                        {quiz.textoDestaque}
                    </div>
                </div>
                <button className={styles.saibaMais} onClick={() => {history.push(`/quiz-detalhe?QuizId=${quiz.quizId}`)}}>
                    Iniciar quiz
                </button>
            </div>

        </div>
    )
}

export default QuizGaleria;