import React, { useState, useEffect } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  BlobProvider,
  Image,
} from '@react-pdf/renderer';
import { useGetUser } from '../../../hooks/useGetUser';
import Fundo from '../../../assets/img/certificadoLaterar.png';
import FundoCertificado from '../../../assets/img/fundoCertificado.png';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import moment from "moment";
import "moment/locale/pt-br";
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss'

const CertificadoDocument = ({ user, informacoesCertificado }) => {
    
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
    },
    backgroundImage: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: -1,
    },
    section: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
    },
    imageContainer: {
      width: '8%',
    },
    textContainer: {
      flexGrow: 1,
      alignItems: 'center', 
    },
    image: {
      width: '100%',
      height: '100%',
    },
    textConclusao: { 
      marginTop: '50px',
      marginBottom: '30px',
      color: '#fff',
      textAlign: 'center',
      fontSize: '30px',
      fontWeight: '900',
    },
    textTipoCurso: {
      marginBottom: '20px',
      marginLeft: '30px',
      color: '#006bd6',
      fontSize: '35px',
      width: '500px'
    },
    textInfo: {
      marginLeft: '30px',
      color: '#fff',
      textAlign: 'center',
      fontSize: '25px',
    },
  });

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.imageContainer}>
            <Image source={Fundo} style={styles.image} />
          </View>
          <View style={styles.textContainer}>
      <Image source={FundoCertificado} style={styles.backgroundImage} />
          
            <Text style={styles.textConclusao}>
              Certificado de conclusão
            </Text>
  
            <Text style={styles.textInfo}>
              Certificamos que:
            </Text>
            <Text style={styles.textConclusao}>
              {informacoesCertificado?.nomeParticipante}
            </Text>
            <Text style={styles.textInfo}>
             concluiu com sucesso o curso de 
            </Text>
            <Text style={styles.textConclusao}>
            {informacoesCertificado?.tituloCurso}
            </Text>
            <Text style={styles.textInfo}>
              Data de Conclusão:
            </Text>
            <Text style={styles.textConclusao}>
            {new moment(informacoesCertificado?.dataCurso).format('DD/MM/YYYY')}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const CertificadoBv = () => {
  const user = useGetUser();
  const location = useLocation();
  const { informacoesCertificado } = location.state || {};
  const history = useHistory();

  const handleDownload = (blob) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'certificado.pdf';
    link.click();
  };

  return (
    <div
      className="pageBody pb-5"
      style={{ marginTop: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <BlobProvider document={<CertificadoDocument user={user} />}>
        {({ blob }) => (
          <>
            <PDFViewer width="800px" height="600">
              <CertificadoDocument user={user}informacoesCertificado={informacoesCertificado} />
            </PDFViewer>
          </>
        )}
      </BlobProvider>

      <button className={styles.saibaMais} onClick={() => {history.push(`/treinamento`)}}>
          Voltar
      </button>

    </div>
  );
};

export default CertificadoBv;
