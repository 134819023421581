import React, { memo }  from 'react';

import './styles.scss';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const ModalLoading = ({open, size, type}) => {
    return (
        <Dialog open={open} className={type} aria-labelledby="customized-dialog-title">
            <DialogTitle id="form-dialog-title">
            </DialogTitle>
            <DialogContent>
                <FontAwesomeIcon icon={faSpinner} style={{fontSize: '65px', color: '#5ccce2'}} spin={true}/>
            </DialogContent>
        </Dialog>
    )
}

export default memo(ModalLoading);