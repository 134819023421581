import React from 'react';
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom';
import moment from "moment";
import "moment/locale/pt-br";

const NoticiaCard = ({ noticia }) => {

    const history = useHistory();

    return (
        <div className={styles.wrapperCard}>
            <div className={styles.bodyCard}>
                <img className={styles.desktop} src={`data:${noticia.imagemDestaqueDesktop?.contentType};base64,${noticia.imagemDestaqueDesktop?.fileContents}`} alt={noticia.titulo}/>
                <img className={styles.mobile} src={`data:${noticia.imagemDestaqueMobile?.contentType};base64,${noticia.imagemDestaqueMobile?.fileContents}`} alt={noticia.titulo}/>
            </div>

            <div className={styles.actionWrapper}>
                <small>Criado em {new moment(noticia.dataCadastro).format('DD/MM/YYYY')}</small>
                <div>
                    <b>{noticia.nome}</b>
                    <div className={styles.textoDestaque}>
                        {noticia.textoDestaque}
                    </div>
                </div>
                <button className={styles.saibaMais} onClick={() => {history.push(`/noticia/detalhe?NoticiaId=${noticia.id}`)}}>
                    Saiba mais
                </button>
            </div>

        </div>
    )
}

export default NoticiaCard;