import React from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import globals from "../../globals";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export default function Auth() {
  const user = useParams();
  const history = useHistory();

  const token = getCookie("token");

  function login() {
    fetch(globals.REACT_APP_APIROOT_AUTO + "login/LoginComToken", {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((user) => {
        return user.json();
      })
      .then((user) => {
        let objUser = user.data;

        localStorage.setItem("authSistema", JSON.stringify(objUser));
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("product", "auto");
        localStorage.setItem("isUserCompany", false);

        if (
          user.data.profile.cadastroCompleto &&
          user.data.profile.dataNascimento
        ) {
          history.push(`/`);
        } else {
          history.push(`/meu-cadastro`);
        }
      });
  }

  useEffect(() => {
    login();
  }, []);

  return <></>;
}
