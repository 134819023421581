import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import services from '../services/index';
import FullPage from './Fullpage';
import ModalConfirmacao from '../components/ModalConfirmacao';
import globals from '../globals';
import { getMeuCadastroCandidato } from '../services/api';

const initialModalMensagem = {
  open: false,

  mensagem: '',
};

const PrivateRoutes = ({ component: Component, ...rest }) => {
  const history = useHistory();

  const [status, setStatus] = useState('');

  const [popUp, setPopUp] = useState(initialModalMensagem);

  function checkStatus() {
    if (
      localStorage.getItem('isAuthenticated') &&
      !services.storage.get('isUserCompany') &&
      services.storage.checkProduct('auto')
    ) {
      setStatus('true');
    } else {
      setStatus('false');
    }
  }

  useEffect(() => {
    if (services.storage.checkProduct('solar')) {
      services.storage.clear();
    }

    if (
      services.storage.get('isAuthenticated') &&
      !services.storage.get('isUserCompany') &&
      services.storage.checkProduct('auto')
    ) {
      checkEu();
    } else {
      setTimeout(() => {
        checkStatus();
      }, 1000);
    }
  }, []);

  const checkEu = async () => {
    const response = await services.api.getMeuCadastroCandidato();

    if (response.fail === true) {
      services.storage.clear();
      // history.push(`/`);
      window.location.href = globals.REACT_APP_AUTH_APP;
    }

    localStorage.setItem('eu', JSON.stringify(response.data));

    setStatus('true');

    if (
      !response.data.cadastroCompleto &&
      history.location.pathname !== '/meu-cadastro'
    ) {
      history.push('/meu-cadastro');

      return;
    }

    if (
      !response.data.dataNascimento &&
      history.location.pathname !== '/meu-cadastro'
    ) {
      history.push('/meu-cadastro');

      return;
    }

    if (response.data.cadastroCompleto && response.data.dataNascimento) {
      checkPopUp();
    }
  };

  const checkPopUp = async () => {
    const response = await services.api.getPopUpAtivo();

    if (response) {
      services.storage.set('isNavegationBlocked', true);

      let campos = response.camposValidar.split('|');

      setPopUp({
        ...popUp,
        open: true,
        popUpId: response.popUpId,
        mensagem: response.mensagem,
        camposValidar: campos,
        endPointValidar: response.endPointValidar,
        ...response,
      });
    }
  };

  if (status === '') {
    return <></>;
  } else if (status === 'false') {
    if (window.location.hostname === 'localhost') {
      history.push('/');

      return false;
    } else {
      document.location.href = globals.REACT_APP_AUTH_APP;

      return false;
    }
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <FullPage>
            <Component {...props} />

            <ModalConfirmacao popUp={popUp} setPopUp={setPopUp} />
          </FullPage>
        )}
      />
    );
  }
};

export default PrivateRoutes;
