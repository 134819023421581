import React from 'react';
import './styles.scss'

const Destaques = () => {

    const disponiveis = [
        {
            classificacao: 'premium', destaques:[
                {nome: "Galerias Turbo", quantidade:2},
                {nome: "Destaques Turbo", quantidade:3},
            ]
        },
        {
            classificacao: 'gold', destaques:[
                {nome: "Galeria 2.0", quantidade:1},
                {nome: "Destaques Turbo", quantidade:1},
                {nome: "Destaque 2.0", quantidade:1},
            ]
        },
        {
            classificacao: 'plus', destaques:[
                {nome: "Destaque 2.0", quantidade:1},
                {nome: "Destaque 1.6", quantidade:1},
            ]
        },
    ]

    return (
        <div >
        </div>
    )
}

export default Destaques;