import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { getPopUpAtivo, getTreinamentoList } from '../../services/api';
import TreinamentoCard from '../../components/TreinamentoCard';
import homeMainImg from '../../assets/img/quiz.png';
import ModalLoading from '../../components/ModalLoading';
import { InputLabel, MenuItem, Select, FormControl } from '@material-ui/core';

const Treinamento = () => {

    const history = useHistory();
    const [treinamento, setTreinamento] = useState([])
    const [verMais, setVerMais] = useState(true)
    const [modalLoading, setModalLoading] = useState(false);
    const [filter, setFilter] = useState( {page : 1, pageSize: 10, query: "" } );
    const [categoria, setCategoria] = useState('null');
    const [status, setStatus] = useState('null');

    const handleCategoriaChange = (event) => {
      setCategoria(event.target.value);
    };
  
    const handleStatusChange = (event) => {
      setStatus(event.target.value);
    };


    const getTreinamento = async() => {
        var filtro = {
            status: status == 3 ? 0 : 1,
            categoria: categoria !=  'null' ? categoria : null,
            StatusTreinamentoUser: status !=  'null' ? status : null
        }

        setModalLoading(true)
        const data = await getTreinamentoList(filtro);
        setModalLoading(false)
        setTreinamento(data.data)
    } 
    useEffect(()=>{
        (treinamento && treinamento.items?.length > 0 && treinamento.page === treinamento.totalPages ) ? setVerMais(true) : setVerMais(false); 
        getTreinamento()
    },[])

    useEffect(()=>{
        getTreinamento();
    },[categoria, status])

    return (
        <div className={styles.wrapper}>
           <div className={styles.title}>
                Treinamento
            </div>
           
            <div className={styles.mainBannerWrapper}>

            </div>
            {treinamento.pontosGanhos != 0 && <><div className={styles.subTitle}>
                FAÇA NOSSOS TREINAMENTOS, SE DESTAQUE NAS VENDAS E ACUMULE PONTOS!
            </div>
            <div className={styles.pontos}>
                {treinamento.pontosGanhos}
            </div>
            <div className={styles.subTitle}>
                Pontos conquistados até o momento
            </div>
            <p className={styles.obs}>Consulte seu extrato para o detalhamento</p></>}

            <div className={styles.filters} style={{paddingLeft:'20px'}}>
                <div className='row'>
                <div className='col-md-3'>
                <FormControl variant="filled" className={styles.filterControl}>
                <InputLabel id="select-categoria-label" className={styles.label}>
                    Categoria
                </InputLabel>
                <Select
                    labelId="select-categoria-label"
                    label="Categoria"
                    onChange={handleCategoriaChange}
                    value={categoria}
                    variant="filled"
                    className="customFilledInput"
                >
                    <MenuItem value={'null'}>Todos</MenuItem>
                    <MenuItem value={0}>Gerais</MenuItem>
                    <MenuItem value={1}>Onboarding</MenuItem>
                    <MenuItem value={2}>QualidadeVendas</MenuItem>
                </Select>
                </FormControl>
                    </div>
                    <div className='col-md-3'>
                <FormControl variant="filled" className={styles.filterControl}>
                <InputLabel id="select-status-label" className={styles.label}>
                    Status
                </InputLabel>
                <Select
                    labelId="select-status-label"
                    label="Status"
                    onChange={handleStatusChange}
                    value={status}
                    variant="filled"
                    className="customFilledInput"
                >
                    <MenuItem value={'null'}>Todos</MenuItem>
                    <MenuItem value={1}>Pendentes</MenuItem>
                    <MenuItem value={2}>Em Andamento</MenuItem>
                    <MenuItem value={3}>Concluídos</MenuItem>
                </Select>
                </FormControl>
                </div>
                </div>
            </div>

            <div className={styles.acoesWrapper}>
                {treinamento && treinamento.treinamentos?.map(
                    (q) => <TreinamentoCard treinamento={q}/>
                )}
            </div>
            {
                treinamento.items?.length === 0 &&
                <div className={styles.verMaisWrapper}> Não existem dados para exibir </div>
            }
            <ModalLoading open={modalLoading}  size={"lg"} />
        </div>
    );
  
}

export default Treinamento;