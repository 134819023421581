import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import "moment/locale/pt-br";
import styles from './styles.module.scss';
import { getConteudoById } from '../../../services/api';
import { useDispatch } from "react-redux";
import {
    showMsgW as showMsgWAction,
    showMsgE as showMsgEAction,
    showMsgS as showMsgSAction
} from '../../../store/actions/snackbarActions.js';
import ModalLoading from '../../../components/ModalLoading'
import { trackPromise } from 'react-promise-tracker';
import services from '../../../services';




const ConteudoDetalhe = () => {
    const dispatch = useDispatch();
    const showMsgW = (msg) => dispatch(showMsgWAction(msg));
    const showMsgE = (msg) => dispatch(showMsgEAction(msg));
    const showMsgS = (msg) => dispatch(showMsgSAction(msg));

    const history = useHistory();
    const [data, setData] = useState([]);
    const [modalLoading, setModalLoading] = useState(false);
    const idPopUp = new URLSearchParams(history.location.search).get("ConteudoId");
    const getConteudo = async (id) => {

        if (id) {
            setModalLoading(true);
            try {
                const info = await getConteudoById(id);
                setData(info.data);
                setModalLoading(false);
            } catch (e) {
                showMsgW('Não foi possível carregar os dados');
                setModalLoading(false);
            }

        }

    }

    const baixarAnexo = () => {
        trackPromise(
            services.api
              .baixouPopUp(idPopUp)
              .then((res) => {
                  if(res.fail != true){
                    const link = document.createElement('a');
                    link.href = data.urlAnexo;
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
              })
              .catch((error) => {
                showMsgE(error.errors.length ? error.errors[0].message : 'Error');
              })
          );

    }

    useEffect(() => {
        const id = new URLSearchParams(history.location.search).get("ConteudoId");
        getConteudo(id);
    }, [])

    return (
        <>
            <div className={`${styles.wrapper} ${styles.blueBackground}`}>
                <div className={styles.title}>
                    Informação
                    <button className={styles.backButton} onClick={() => { history.goBack() }}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                </div>
                <div className={styles.content}>
                    <div className={styles.card}>
                        <div className={styles.subtitle}>
                            {data.titulo}
                        </div>
                        <div className={styles.periodo}>
                            Publicado em {new moment(data.dataCadastro).format('DD/MM/YYYY')}
                        </div>
                        <div className={styles.descricao}>
                            <div dangerouslySetInnerHTML={{ __html: data?.descricao?.replaceAll('\n', '<br/>') }}></div>
                        </div>
                        <div className={styles.imagemBeneficio}>
                            <img className={styles.desktop} src={`data:${data.imagemConteudoDesktop?.contentType};base64,${data.imagemConteudoDesktop?.fileContents}`} alt={data.titulo} />
                            <img className={styles.mobile} src={`data:${data.imagemConteudoMobile?.contentType};base64,${data.imagemConteudoMobile?.fileContents}`} alt={data.titulo} />
                        </div>
                    </div>
                </div>

            </div>

            
            {
                    data.urlAnexo &&
                    <div className={styles.blueBackground}>
                        <div className={styles.content}>
                            <div className={styles.card}>
                                <div className={styles.imageWrapper}>
                                    <button onClick={baixarAnexo}>
                                        <div className={styles.imageWrapperIcon}>
                                            <FontAwesomeIcon icon={faDownload} />
                                        </div>
                                        <div className={styles.imageButtonWrapperText}>
                                            <div className={styles.imageWrapperTexto}>
                                                Baixar anexo
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
        </>
    );
}

export default ConteudoDetalhe;