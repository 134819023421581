import * as storage from './storage'
import * as api from './api'
import * as utils from './utils'
import * as mask from './mask'
import * as validate from './validateForm'

export default {
    storage,
    api,
    utils,
    mask,
    validate
}
