import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import "moment/locale/pt-br";
import styles from './styles.module.scss';
import { postResgatar, getVitrineBVById } from '../../../services/api';
import { useDispatch } from "react-redux";
import Modal from '../../../components/Modal/Modal';
import { 
    showMsgW as showMsgWAction,
    showMsgE as showMsgEAction,
    showMsgS as showMsgSAction
} from '../../../store/actions/snackbarActions.js';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import ModalLoading from '../../../components/ModalLoading'

const dataInit = {
    idVitrineBV: null,
    titulo: "",
    periodoInicial: null,
    periodoFinal: null,
    periodoAtividade: 0,
    tipo: 0,
    status: 1,
    ordemExibicao: 1,
    lojasParticipantes: [
        {
            idParticipanteVitrineBV: null,
            participanteId: null,
            idVitrineBV: null,
            classificacao: "",
            regional: "",
            filial: "",
            loja: "",
            participanteNome: ""
        }
    ],
    linkBeneficio: "",
    aceitaAcompanhante: false,
    quantidade: null,
    conteudo: "",
    linkDestaqueDesktop: "",
    linkDestaqueMobile: "",
    linkConteudoDesktop: "",
    linkConteudoMobile: ""
}

const acompInicial = {
    idVitrineBV: null,
    listaAcompanhante: [
        {
            cpfAcompanhante: "",
            nomeAcompanhante: ""
        }
    ]
}


const DetalheGaleriaVitrine = () => {
    const dispatch = useDispatch();
    const showMsgW = (msg) => dispatch(showMsgWAction(msg));
    const showMsgE = (msg) => dispatch(showMsgEAction(msg));
    const showMsgS = (msg) => dispatch(showMsgSAction(msg));

    const history = useHistory();
    const [data, setData] = useState(dataInit);
    const [acompanhante, setAcompanhante] = useState(acompInicial);
    const [modalLoading, setModalLoading] = useState(false);
    const [modal, setModal] = useState(false);

    const getVitrineIfEdit = async (id) => {

        if(id) {
            setModalLoading(true)
            try{
                const info = await getVitrineBVById(id);
                setData(info.data);
                
                let a = acompanhante;
                for(let i = 1; i < info.data.quantidade; i++) {
                    a.listaAcompanhante.push({ cpfAcompanhante: "", nomeAcompanhante: "" });
                }

                a.idVitrineBV = info.data.idVitrineBV;
                setAcompanhante(a);


            } catch(e){
                showMsgW('Não foi possível carregar os dados');
            }
            setModalLoading(false)
        }

    }

    const enviar = async() => {

        if(data.aceitaAcompanhante && acompanhante.listaAcompanhante.length > 0) {
            setModal(true);
        } else {
            await resgatar();
        }
        
    }

    const resgatar = async () => {

        if(data.aceitaAcompanhante 
            && acompanhante.listaAcompanhante.length > 0 
            && acompanhante.listaAcompanhante.some(x => x.cpfAcompanhante === "")
            && window.confirm("Você pode levar acompanhantes para este evento, clique em OK se deseja resgatar mesmo assim, ou CANCELAR para informar os acompanhantes.")) {
            try {
                await postResgatar(acompanhante);
                showMsgS("Dados salvos com sucesso!");
                setTimeout(()=>{
                    history.push('/galeria-vitrine')
                }, 2000)
            } catch(e){
                showMsgE("Ocorreu um erro ao salvar os dados")
            }
        } else {}

    }

    useEffect( () => {
        const id = new URLSearchParams(history.location.search).get("IdVitrineBV");
        getVitrineIfEdit(id);
    },[])

    const atualizaNomeAcompanhante = (posicao, valor) => {
        acompanhante.listaAcompanhante[posicao].nomeAcompanhante = valor;
        setAcompanhante(acompanhante);
    }

    const atualizaCpfAcompanhante = (posicao, valor) => {
        acompanhante.listaAcompanhante[posicao].cpfAcompanhante = valor;
        setAcompanhante(acompanhante);
    }

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    Benefício
                    <button className={styles.backButton} onClick={() => {history.goBack()}}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                </div>

                <div className={styles.blueBackground}>
                    <div className={styles.content}>
                        <div className={styles.card}>
                            <div className={styles.colunaUm}>
                                <div className={styles.tituloBeneficio}>
                                    {data.titulo}
                                </div>
                                <div className={styles.dtCadastro}>
                                    Criado em {new moment(data.dataCadastro).format('DD/MM/YYYY')}
                                </div>
                                <div className={styles.imagemBeneficio}>
                                    <img className={styles.desktop} src={data.linkConteudoMobile} alt={data.titulo} />
                                    <img className={styles.mobile} src={data.linkConteudoMobile} alt={data.titulo} />
                                </div>
                            </div>
                            <div className={styles.colunaDois}>
                                <div className={styles.conteudo}>
                                    <div dangerouslySetInnerHTML={{__html: data.conteudo.replaceAll('\n', '<br/>')}}></div>
                                </div>

                                <div className={styles.acoes}>
                                    {   data.tipo === 2 &&
                                        <a className={styles.resgatar} onClick={resgatar} href={data.linkBeneficio} target="_blank">
                                            Resgatar
                                        </a>
                                    }
                                    {   data.tipo === 1 &&
                                        <button className={styles.salvar} onClick={enviar}>
                                            Confirmar presença
                                        </button>
                                    }
                                    <button className={styles.voltar} onClick={() => { history.push("/galeria-vitrine"); }}>
                                        Voltar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <Modal 
                open={modal} 
                close={() => {setModal(false)}}
                size="md"
                >
                <div className="pageBody">
                    <div className="container-fluid existeParceiroPage">

                        <div className="row">
                            <div className='col text-left'>
                            <h1><b>Acompanhantes</b></h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className='col-sm-12'>
                                    {   acompanhante.listaAcompanhante.length > 0 && 
                                        acompanhante.listaAcompanhante.map((item, i) => {
                                            return (
                                                <div className={styles.acompanhante}>
                                                    <div><h5><b>Acompanhante {i+1}</b></h5></div>
                                                    <FormControl>
                                                        <TextField label="Nome" type="text"  size="small"
                                                            onChange={(event)=>{atualizaNomeAcompanhante( i, event.target.value)}}
                                                            variant="filled"
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <TextField label="CPF" type="text"  size="small"
                                                            onChange={(event)=>{atualizaCpfAcompanhante( i, event.target.value)}}
                                                            variant="filled"
                                                        />
                                                    </FormControl>
                                                </div>
                                            )
                                        })
                                    }
                            </div>
                        </div>

                        <div className="row">
                            <div className='col-12 padB20'>
                                <button className={styles.resgatar} onClick={() => { resgatar() }}>
                                    Confirmar presença
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
            <ModalLoading open={modalLoading}/>
        </>
    );
}

export default DetalheGaleriaVitrine;