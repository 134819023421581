import React, { useState, useEffect } from 'react';

import styles from './styles.module.scss';
import Focus from '../../components/Focus';
import { useGetUser } from '../../hooks/useGetUser';

import homeMainImg from '../../assets/img/homeMain.png'

import car from '../../assets/img/car.svg'
import market from '../../assets/img/market.svg'
import protecao from '../../assets/img/protecao.svg'
import seguro from '../../assets/img/seguro.svg'

import Destaques from '../../components/Destaques';

import premiumItem from '../../assets/img/premiumItem.svg';
import goldItem from '../../assets/img/goldItem.svg';
import plusItem from '../../assets/img/plusItem.svg';
import brassItem from '../../assets/img/brassItem.png';
import noneItem from '../../assets/img/noneItem.png';

const Beneficios = props => {
  const user = useGetUser();
  const [parceiro, setParceiro] = useState({});
  const [variosParceiros, setVariosParceiros] = useState(false);

  useEffect(() => {
    if (user.parceiros?.length == 1) {
      setParceiro(user.parceiros[0]);
    }
    else if (user.parceiros?.length > 0) {
      setVariosParceiros(true);
    }
  }, [user]);

  const classificacaoText = (parceiro) => {
    let classificacao = parceiro?.classificacao?.descricao;
    if (classificacao === 'Compensa Premium') {
      return (
        <div className={styles.congrats}>
          Parabéns! Para se manter <span>Compensa Premium</span> em <span>{parceiro?.beneficio?.mesSeguinte}</span>, mantenha o foco em:
        </div>
      )
    }
    if (classificacao === 'Compensa Gold') {
      return (
        <div className={styles.congrats}>
          Parabéns! Para ser <span>Compensa Premium</span> em <span>{parceiro?.beneficio?.mesSeguinte}</span>, continue focando nos seguintes itens:
        </div>
      )
    }
    if (classificacao === 'Compensa Plus' || classificacao === 'Compensa') {
      return (
        <div className={styles.congrats}>
          Parabéns! Para melhorar a sua classificação, foque nos seguintes itens:
        </div>
      )
    }

    return (
      <div className={styles.congrats}>
        Faça mais de 1 contrato com o BV para obter classificação em <span>{parceiro?.beneficio?.mesSeguinte}</span>.
      </div>
    )
  }

  const classificacaoImage = {
    'Compensa Premium': (
      <div className={styles.classificacaoBadgeWrapper}>
        <img src={premiumItem} className={styles.classificacaoBadgeImage} />
        <div className={styles.classificacaoBadgeText}>
          Compensa <b>Premium</b>
        </div>
      </div>
    ),
    'Compensa Gold': (
      <div className={styles.classificacaoBadgeWrapper}>
        <img src={goldItem} className={styles.classificacaoBadgeImage} />
        <div className={styles.classificacaoBadgeText}>
          Compensa <b>Gold</b>
        </div>
      </div>
    ),
    'Compensa Plus': (
      <div className={styles.classificacaoBadgeWrapper}>
        <img src={plusItem} className={styles.classificacaoBadgeImage} />
        <div className={styles.classificacaoBadgeText}>
          Compensa <b>Plus</b>
        </div>
      </div>
    ),
    'Compensa': (
      <div className={styles.classificacaoBadgeWrapper}>
        <img src={brassItem} className={styles.classificacaoBadgeImage} />
        <div className={styles.classificacaoBadgeText}>
          Compensa
        </div>
      </div>
    ),
    'Sem Classificação': (
      <div className={styles.classificacaoBadgeWrapper}>
        <img src={noneItem} className={styles.classificacaoBadgeImage} />
        <div className={styles.classificacaoBadgeText}>
          Sem <span>Classificação</span>
        </div>
      </div>
    )
  }

  const getClassificacao = (classificacao) => {
    const show = classificacaoImage[classificacao]

    if (show) {
      return show
    }

    return classificacaoImage['Sem Classificação']
  }

  return (
    <div className={`${styles.pageHeader} ${styles.pageHome}`}>
      {/*<HeaderParticipant />!*/}
      <div className={styles.mainBannerWrapper}>
        <img className={styles.mainBanner} src={homeMainImg} />
      </div>
      <div className={styles.classificacao}>
        {
          variosParceiros &&
          <div className={styles.cardWrapper}>
            {
              user.parceiros.map((parceiro) => (
                <div style={{ width: '50%' }}>
                  <div className={styles.texto}>
                    {parceiro?.nomeFantasia}, sua classificação de <span>{parceiro?.beneficio?.mes}</span> foi:
                  </div>
                  <div className={styles.imageCard}>
                    <div className={styles.imgWrapper}>
                      {getClassificacao(parceiro?.classificacao?.descricao)}
                    </div>
                  </div>
                  <div className={styles.texto}>
                    {classificacaoText(parceiro)}
                  </div>
                </div>
              ))
            }
          </div>
        }
        {
          !variosParceiros &&
          <>
            <div className={styles.texto}>
              Olá, {parceiro?.nomeFantasia || user.nome}! Sua classificação de <span>{parceiro?.beneficio?.mes}</span> foi:
            </div>
            <div className={styles.imgWrapper}>
              {getClassificacao(parceiro?.classificacao?.descricao)}
            </div>
          </>
        }

        <div className={styles.focusWrapper}>
          <div className={`${styles.container} ${styles.focusWrapperBox}`}>
            {!variosParceiros && classificacaoText(parceiro)}
            <div className={styles.focusList}>
              <Focus image={market} name="Market Share" type="Contratos BV" />
              <Focus image={protecao} name="Seguro Proteção Financeira" type="Penetração" />
              <Focus image={seguro} name="Seguro Acidentes Pessoais" type="Penetração" />
              <Focus image={car} name="Seguro Linha Auto Parcial" type="Penetração" />
            </div>
            <div className={styles.focusFooter}>
              <div className={styles.textBox}>
                Ah, e não se esqueça de sempre entender as necessidades e possibilidades de pagamento dos clientes, garantindo a transparência na negociação e a ciência de todas as taxas, impostos e produtos que fazem parte do contrato. Assim você faz vendas de qualidade, evita inadimplência e o cancelamento de produtos.
                <span>Consulte seu Gerente de Relacionamento BV para saber sobre outros indicadores da sua loja!</span>
              </div>
            </div>
          </div>
        </div>
        <section>
          <Destaques />
        </section>

      </div>
    </div>
  );

}

export default Beneficios;