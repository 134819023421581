import React from 'react';
import services from '../../services';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const Parceiros = ({formPage}) => {
    return (
        <div className='row mt20 mb10'>
            <div className='col-2'>
              <TextField label="Código Parceiro" size="small" className="customFilledInput"
                value={formPage.codigo}
                variant="filled"
                disabled
              />
            </div>
            <div className='col-3'>
              <TextField label="CNPJ" type="text" size="small" className="customFilledInput"
                value={services.mask.cnpj(formPage.cnpj)}
                variant="filled"
                disabled
              />
            </div>
            <div className='col-4'>
              <TextField label="Razão social" type="text" size="small" className="customFilledInput"
                value={formPage.razaoSocial}
                variant="filled"
                disabled
              />
            </div>
            <div className='col-3'>
              <TextField label="Nome fantasia" type="text" size="small" className="customFilledInput"
                value={formPage.nomeFantasia}
                variant="filled"
                disabled
              />
            </div>
          </div>
    )
}

export default Parceiros;