import React, { useEffect, useState } from 'react';

import './styles.scss';
import { Carousel } from 'react-bootstrap';
import { getBanners } from '../../services/api';

const Banner = ({ screen }) => {
  const [banner, setBanners] = useState([]);

  const getListCampanhas = async () => {
    const b = await getBanners();
    setBanners(b.data);
  };

  const typeBanner = screen === 'vitrine' ? '1' : '0';

  useEffect(() => {
    getListCampanhas();
  }, []);

  return (
    <div className="defaultBanner">
      <Carousel>
        {banner &&
          banner.length > 0 &&
          banner
            .filter((item) => item.tipo == typeBanner)
            .map((i) => (
              <Carousel.Item key={i.id}>
                <img
                  className={`destaqueDesktop${i.link ? ' link' : ''}`}
                  src={`data:${i.imagemDestaqueDesktop?.contentType};base64,${i.imagemDestaqueDesktop?.fileContents}`}
                  alt=""
                  onClick={
                    i.link
                      ? () => {
                          window.open(i.link, '_blank');
                        }
                      : undefined
                  }
                />
                <img
                  className={`destaqueMobile${i.link ? ' link' : ''}`}
                  src={`data:${i.imagemDestaqueMobile?.contentType};base64,${i.imagemDestaqueMobile?.fileContents}`}
                  alt=""
                  onClick={
                    i.link
                      ? () => {
                          window.open(i.link, '_blank');
                        }
                      : undefined
                  }
                />
              </Carousel.Item>
            ))}
      </Carousel>
    </div>
  );
};

export default Banner;
