import React from 'react';

import './styles.scss';

const FomPreCadastro = ({action}) => {

  const voltarHome = () => {
    action()
  }

  return (
    <div className="pageBody">
      <div className="container-fluid existeParceiroPage">

        <div className="row">
          <div className='col text-left'>
            <h1><b>Pré-cadastro concluído</b></h1>
          </div>
        </div>
        <div className="row">
          <div className='col text-left'>
            <h5>Seus dados foram encaminhados para análise.</h5>
          </div>
        </div>

        <div className="row">
          <div className='col-12 padB20'>
            <button className="btn btnVoltar btn-lg btn-block" onClick={voltarHome}>Ir para o login</button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default FomPreCadastro;