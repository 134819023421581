import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { cardCampanhaParticipanteDatas } from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const firstYear = 2022;

const Campanhas = props => {

    const history = useHistory();
    const [campanhas, setCampanhas] = useState([])

    const [anos, setAnos] = useState([])
    const [anoSelecionado, setAnoSelecionado] = useState('')
    const [pagina, setPagina] = useState(1)
    const [morePage, setMorePage] = useState(true)
    const [loading, setLoading] = useState(false)
    const [maxPage, setMaxPage] = useState(0);
    
    const [mesSelecionado, setMesSelecionado] = useState('')
    const meses = [
        {nome: 'janeiro',   numero: 1, short: 'JAN'},
        {nome: 'fevereiro', numero: 2, short: 'FEV'},
        {nome: 'março',     numero: 3, short: 'MAR'},
        {nome: 'abril',     numero: 4, short: 'ABR'},
        {nome: 'maio',      numero: 5, short: 'MAI'},
        {nome: 'junho',     numero: 6, short: 'JUN'},
        {nome: 'julho',     numero: 7, short: 'JUL'},
        {nome: 'agosto',    numero: 8, short: 'AGO'},
        {nome: 'setembro',  numero: 9, short: 'SET'},
        {nome: 'outubro',   numero: 10, short: 'OUT'},
        {nome: 'novembro',  numero: 11, short: 'NOV'},
        {nome: 'dezembro',  numero: 12, short: 'DEZ'}
    ]

    const [size, setSize] = useState(0);
    useEffect(() => {
        function getWidth() {
            setSize(window.innerWidth)
        }
    
        getWidth();
        window.addEventListener('resize', getWidth);
        return () => window.removeEventListener('resize', getWidth);
    }, []);
    const mobile = size < 800;

    const getYears = () => {
        const anoAtual = new Date().getFullYear();
        let array_ano = []
        for(let i = firstYear; i <= anoAtual; i++){
            array_ano.push(i)
        }

        setAnos(array_ano);
    }

    const getCampanhas = async() => {
        setPagina(1)
        setLoading(true)
        const info = await cardCampanhaParticipanteDatas(1, mesSelecionado.numero, anoSelecionado)
        setLoading(false)
        setCampanhas(info.data)
        setMaxPage(info.maxPage)
    }

    useEffect(()=>{
        if(maxPage > pagina + 1){ setMorePage(true) } else { setMorePage(false) }
    },[pagina, maxPage])

    const addPage = async() => {
        setLoading(true)
        const info = await cardCampanhaParticipanteDatas(pagina + 1, mesSelecionado.numero, anoSelecionado)
        setPagina(pagina + 1)
        setLoading(false)
        setCampanhas([ ...campanhas, ...info.data])
        setMaxPage(info.maxPage)
    }

    useEffect(() => {
        getYears()
        setAnoSelecionado(new Date().getFullYear())
        setMesSelecionado(meses[new Date().getMonth()])
    },[])

    useEffect(() => {
        if(mesSelecionado && anoSelecionado){
            getCampanhas()
        }
    },[anoSelecionado, mesSelecionado])

    const verCampanha = (id) => {
        history.push(`campanhas/detalhes?campanha=${id}`)
    }

    

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Campanhas
            </div>
            <div className={styles.backBlue}>
                <div className={styles.card}>
                    <div className={styles.titleCard}>
                        Conheça todos os detalhes das nossas campanhas
                    </div>
                    <div className={styles.list}>
                        {anos.map((ano) => {
                            return (
                                <button className={ ano === anoSelecionado ? styles.activeYearButton : styles.yearButton} onClick={()=>{setAnoSelecionado(ano)}}>
                                    {ano}
                                </button>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={styles.monthWrapper}>
                <div className={styles.list}>
                    {meses.map((mes) => {
                        return (
                            <button className={ mes.numero === mesSelecionado.numero ? styles.activeMonthButton : styles.monthButton} 
                                onClick={()=>{setMesSelecionado(mes)}}>
                                {mes.short}
                            </button>
                        )
                    })}
                </div>
            </div>
            { loading && (<div className={styles.paginacaoWrapper}>
                    <FontAwesomeIcon icon={faSpinner} className={styles.icon} spin={true}/>
                </div>)}
            { (!loading) && (
                (mesSelecionado && anoSelecionado) ?
                (<div className={styles.campanhasWrapper}>
                    <div className={styles.subTitle}>
                        Campanhas de {mesSelecionado.nome}
                    </div>
                    <div className={styles.cardWrapper}>
                        {
                            campanhas.map((campanha) => {
                                return(
                                        <img src={`data:${campanha.imagemLogo.contentType};base64,${campanha.imagemLogo.fileContents}`}
                                            onClick={() => verCampanha(campanha.id)}
                                            className={styles.imageCard}
                                        />
                                )
                            })
                        }
                    </div>
                    { morePage &&
                        (<div className={styles.paginacaoWrapper}>
                            <button onClick={addPage} className={`customButton ${styles.paginacao}`}>
                                ver mais
                            </button>
                        </div>)
                    }
                </div>) :
                (<div className={styles.mensagem}>
                    Favor Selecionar ano e mês
                </div>)
                )
            }
            
        </div>
    );
  
}

export default Campanhas;