import React, { useState, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useGetUser } from '../../../hooks/useGetUser';
import { useHistory } from 'react-router-dom';

/////////////////////////////
// redux
import { useDispatch } from "react-redux"
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction,
} from '../../../store/actions/snackbarActions.js';
import styles from './styles.scss'

import services from '../../../services';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import PasswordStrength from '../../../components/PasswordStrength'

import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import Checkbox from '@material-ui/core/Checkbox';
import ModalLoading from '../../../components/ModalLoading';
import AcoesUser from '../../../components/ButtonsAcoes';
import Parceiros from '../../Parceiros';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br"

const MeusDados = ({ formPage, action, changes, indicarErros, onlyRead }) => {
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const showMsgS = (msg) => dispatch(showMsgSAction(msg));

  const user = useGetUser();
  const [perfis, setPerfis] = useState([]);
  const [estados, setEstados] = useState([]);
  const [cidades1, setCidades1] = useState([]);
  const [cidades2, setCidades2] = useState([]);
  const [parceiros, setparceiros] = useState([]);
  const [mostraCamposEmpresa, setMostraCamposEmpresa] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [ativo, setAtivo] = useState(false);
  const history = useHistory();
  const [espelho, SetEspelho] = useState(localStorage.getItem('isEspelho'))

  const getParceiros = () => {
    trackPromise(
      services.api.getParceiros()
        .then(res => {
          if (res) {
            setparceiros(res)
          } else {
            showMsgE(res.errors ? res.errors.message : 'Erro')
          }
        }).catch(error => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error')
        })
    )
  }

  useEffect(() => {
    setAtivo(formPage.ativo)
  }, [formPage.ativo]);

  useEffect(() => {
    getCidades1()
  }, [formPage.uf1]);

  useEffect(() => {
    getCidades2()
  }, [formPage.uf2]);

  useEffect(() => {
    getParceiros()
  }, []);

  useEffect(() => {
    getPerfis();
    getEstados();
    setMostraCamposEmpresa(services.storage.getClaimValues("MeusDados", "MostraCamposEmpresa"));
  }, []);

  const getPerfis = () => {
    trackPromise(
      services.api.perfisParticipantes()
        .then(res => {
          if (!res.fail) {

            if (user.perfilAcesso === "Participante" ||
            user.perfilAcesso === "Responsavel") {

              var perfis = res.data.filter(perfil => perfil.displayName == "Vendedor(a)"
                || perfil.displayName == "Proprietário(a)"
                || perfil.displayName == "F&I");

              setPerfis(perfis)
            } else {
              setPerfis(res.data)
            }

          } else {
            showMsgE(res.errors ? res.errors.message : 'Erro')
          }
        }).catch(error => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error')
        })
    )
  }

  const getEstados = () => {
    trackPromise(
      services.api.enderecoEstados()
        .then(res => {
          if (!res.fail) {
            setEstados(res.data)
          } else {
            showMsgE(res.errors ? res.errors.message : 'Erro')
          }
        }).catch(error => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error')
        })
    )
  }

  const getend1 = (value) => {
    if (services.validate.validateCep(value)) {
      trackPromise(
        services.api.enderecoEndByCep(value)
          .then(res => {
            if (res.data) {
              if (res.data.bairro) { changes(res.data.bairro, 'bairro1') }
              if (res.data.logradouro) { changes(res.data.logradouro, 'logradouro1') }
              if (res.data.uf) { changes(res.data.uf.ufId, 'uf1') }
              if (res.data.cidade) { changes(res.data.cidade.cidadeId, 'cidade1') }
            } else {
              showMsgE(res.errors ? res.errors.message : 'Erro')
            }
          }).catch(error => {
            showMsgE(error.errors.length ? error.errors[0].message : 'Error')
          })
      )
    }

    changes(value, 'cep1')
  }

  const getend2 = (value) => {
    if (services.validate.validateCep(value)) {
      trackPromise(
        services.api.enderecoEndByCep(value)
          .then(res => {
            if (res.data) {
              if (res.data.bairro) { changes(res.data.bairro, 'bairro2') }
              if (res.data.logradouro) { changes(res.data.logradouro, 'logradouro2') }
              if (res.data.uf) { changes(res.data.uf.ufId, 'uf2') }
              if (res.data.cidade) { changes(res.data.cidade.cidadeId, 'cidade2') }
            } else {
              showMsgE(res.errors ? res.errors.message : 'Erro')
            }
          }).catch(error => {
            showMsgE(error.errors.length ? error.errors[0].message : 'Error')
          })
      )
    }

    changes(value, 'cep2')
  }

  const getCidades1 = () => {
    if (formPage.uf1) {
      trackPromise(
        services.api.enderecoCidades(formPage.uf1)
          .then(res => {
            if (!res.fail) {
              setCidades1(res.data)
            } else {
              showMsgE(res.errors ? res.errors.message : 'Erro')
            }
          }).catch(error => {
            showMsgE(error.errors.length ? error.errors[0].message : 'Error')
          })
      )
    } else {
      setCidades1([])
    }
  }

  const getCidades2 = () => {
    if (formPage.uf2) {
      trackPromise(
        services.api.enderecoCidades(formPage.uf2)
          .then(res => {
            if (!res.fail) {
              setCidades2(res.data)
            } else {
              showMsgE(res.errors ? res.errors.message : 'Erro')
            }
          }).catch(error => {
            showMsgE(error.errors.length ? error.errors[0].message : 'Error')
          })
      )
    } else {
      setCidades2([])
    }
  }

  const changeEnderecoSim = (e) => {
    if (e.target.checked) {
      changes(true, 'mesmoEnderecoEntrega')
    }
  }

  const changeEnderecoNao = (e) => {
    if (e.target.checked) {
      changes(false, 'mesmoEnderecoEntrega')
    }
  }

  const ativarInativar = async () => {
    setModalLoading(true)
    await services.api.adminstradorAtivarInativar(formPage.userId)
    setModalLoading(false)
    history.goBack();
  }


  return (
    <div className="pageBodyMeusDados">
    <div className="meusDadosBody">
         <h1 className="container">Meus dados</h1>
          <div className="container MeusDados">

            <div className="row">
              <div className='col '>
                <h3>Dados de pessoa física</h3>
              </div>
            </div>

            <div className='dataGroup'>
              <FormControl>
                <TextField label="Nome Completo" type="text" size="small" className="customFilledInput"
                  onChange={e => changes(e.target.value, 'nome')}
                  value={formPage.nome}
                  helperText={indicarErros && !formPage.nome && 'Campo obrigatório'}
                  error={indicarErros && !formPage.nome ? true : false}
                  variant="filled"
                  disabled={onlyRead || espelho}
                />
              </FormControl>
              <FormControl>
                <TextField label="CPF" type="text" size="small" className="customFilledInput"
                  onChange={e => changes(e.target.value, 'userName')}
                  value={services.mask.cpf(formPage.userName)}
                  helperText={indicarErros && !formPage.userName && 'Campo obrigatório'}
                  error={indicarErros && !formPage.userName ? true : false}
                  variant="filled"
                  disabled={formPage.userId}
                />
              </FormControl>
              <FormControl>
                <MuiPickersUtilsProvider locale="pt-br" libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Data de Nascimento"
                    format="DD/MM/yyyy"
                    value={formPage.dataNascimento}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={novaData => { changes(novaData, 'dataNascimento') }}
                    className="customFilledInput"
                    helperText={indicarErros && !formPage.dataNascimento && 'Campo obrigatório'}
                    error={indicarErros && !formPage.dataNascimento ? true : false}
                    invalidDateMessage="Data em formato inválido."
                    maxDateMessage="Data não deve ser maior que a data máxima."
                    minDateMessage="Data não deve ser menor que a data mínima."
                    disabled={onlyRead || espelho}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl>
                <TextField label="E-mail" type="text" size="small" className="customFilledInput"
                  onChange={e => changes(e.target.value, 'email')}
                  value={formPage.email}
                  helperText={indicarErros && !formPage.email && 'Campo obrigatório'}
                  error={indicarErros && !formPage.email ? true : false}
                  variant="filled"
                  disabled={onlyRead || espelho}
                />
              </FormControl>
              <FormControl>
                <TextField label="Celular" type="text" size="small" className="customFilledInput"
                  onChange={e => changes(e.target.value, 'phoneNumber')}
                  value={services.mask.celphone(formPage.phoneNumber)}
                  helperText={indicarErros && !formPage.phoneNumber && 'Campo obrigatório'}
                  error={indicarErros && !formPage.phoneNumber ? true : false}
                  variant="filled"
                  disabled={onlyRead || espelho}
                />
              </FormControl>
              <FormControl>
                <TextField label="Cargo" select size="small" className="customFilledInput"
                  onChange={e => changes(e.target.value, 'roleId')}
                  value={formPage.roleId}
                  helperText={indicarErros && !formPage.roleId && 'Campo obrigatório'}
                  error={indicarErros && !formPage.roleId ? true : false}
                  variant="filled"
                  disabled={onlyRead || espelho}
                >
                  {perfis.map((option) => (
                    <MenuItem key={option.roleId} value={option.roleId}>
                      {option.displayName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>

            {mostraCamposEmpresa && parceiros && <>
              <div className="row">
                <div className='col '>
                  <h3>Empresas parceiras</h3>
                </div>
              </div>

              {
                parceiros.map((loja, i) => {
                  return (
                    <Parceiros key={i}
                      formPage={loja}
                    />
                  )
                })
              }
            </>}

            <div className="row mt20">
              <div className='col'>
                <h3>Endereço</h3>
              </div>
            </div>

            <div className='dataGroup'>
              <FormControl>
                <TextField label="CEP" type="text" size="small" className="customFilledInput"
                  onChange={e => getend1(e.target.value, 'cep1')}
                  value={services.mask.cep(formPage.cep1)}
                  variant="filled"
                  inputProps={{ maxLength: 9 }}
                  disabled={onlyRead || espelho}
                />
              </FormControl>
              <FormControl>
                <TextField label="Logradouro" type="text" size="small" className="customFilledInput"
                  onChange={e => changes(e.target.value, 'logradouro1')}
                  value={formPage.logradouro1}
                  variant="filled"
                  disabled={onlyRead || espelho}
                />
              </FormControl>
              <FormControl>
                <TextField label="Número" type="text" size="small" className="customFilledInput"
                  onChange={e => changes(e.target.value, 'numero1')}
                  value={formPage.numero1}
                  variant="filled"
                  disabled={onlyRead || espelho}
                />
              </FormControl>
              <FormControl>
                <TextField label="Complemento" type="text" size="small" className="customFilledInput"
                  onChange={e => changes(e.target.value, 'complemento1')}
                  value={formPage.complemento1}
                  variant="filled"
                  disabled={onlyRead || espelho}
                />
              </FormControl>
              <FormControl>
                <TextField label="Bairro" type="text" size="small" className="customFilledInput"
                  onChange={e => changes(e.target.value, 'bairro1')}
                  value={formPage.bairro1}
                  variant="filled"
                  disabled={onlyRead || espelho}
                />
              </FormControl>

              <FormControl>
                <TextField label="Estado" select size="small" className="customFilledInput"
                  onChange={e => changes(e.target.value, 'uf1')}
                  value={formPage.uf1}
                  variant="filled"
                  disabled={onlyRead || espelho}
                >
                  {estados.map((option) => (
                    <MenuItem key={option.ufId} value={option.ufId}>
                      {option.sigla}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <FormControl>
                <TextField label="Cidade" select size="small" className="customFilledInput"
                  onChange={e => changes(e.target.value, 'cidade1')}
                  value={formPage.cidade1}
                  variant="filled"
                  disabled={onlyRead || espelho}
                >
                  {cidades1.map((option) => (
                    <MenuItem key={option.cidadeId} value={option.cidadeId}>
                      {option.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>

            <div className="row justify-content-center">
              <div className="newAdressLabel">Usar o mesmo endereço para a entrega?</div>
              <div className="col-sm-12 newAddress">
                <Checkbox
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<RadioButtonChecked />}
                  checked={formPage.mesmoEnderecoEntrega}
                  onChange={changeEnderecoSim}
                  name="checkedPermission"
                  disabled={onlyRead || espelho}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <label >Sim</label>
                <Checkbox
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<RadioButtonChecked />}
                  checked={!formPage.mesmoEnderecoEntrega}
                  onChange={changeEnderecoNao}
                  name="checkedPermission"
                  disabled={onlyRead || espelho}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <label className="acceptTerms">Não</label>
              </div>
            </div>

            <div className={formPage.mesmoEnderecoEntrega && "hide"}>
              <div className="row">
                <div className='col '>
                  <h3>Endereço de entrega</h3>
                </div>
              </div>

              <div className='dataGroup'>
                <FormControl>
                  <TextField label="CEP" type="text" size="small" className="customFilledInput"
                    onChange={e => getend2(e.target.value)}
                    value={services.mask.cep(formPage.cep2)}
                    variant="filled"
                    disabled={onlyRead || espelho}
                    inputProps={{ maxLength: 9 }}
                  />
                </FormControl>
                <FormControl>
                  <TextField label="Logradouro" type="text" size="small" className="customFilledInput"
                    onChange={e => changes(e.target.value, 'logradouro2')}
                    value={formPage.logradouro2}
                    variant="filled"
                    disabled={onlyRead || espelho}
                  />
                </FormControl>
                <FormControl>
                  <TextField label="Número" type="text" size="small" className="customFilledInput"
                    onChange={e => changes(e.target.value, 'numero2')}
                    value={formPage.numero2}
                    variant="filled"
                    disabled={onlyRead || espelho}
                  />
                </FormControl>
                <FormControl>
                  <TextField label="Complemento" type="text" size="small" className="customFilledInput"
                    onChange={e => changes(e.target.value, 'complemento2')}
                    value={formPage.complemento2}
                    variant="filled"
                    disabled={onlyRead || espelho}
                  />
                </FormControl>
                <FormControl>
                  <TextField label="Bairro" type="text" size="small" className="customFilledInput"
                    onChange={e => changes(e.target.value, 'bairro2')}
                    value={formPage.bairro2}
                    variant="filled"
                    disabled={onlyRead || espelho}
                  />
                </FormControl>

                <FormControl>
                  <TextField label="Estado" select size="small" className="customFilledInput"
                    onChange={e => changes(e.target.value, 'uf2')}
                    value={formPage.uf2}
                    variant="filled"
                    disabled={onlyRead || espelho}
                  >
                    {estados.map((option) => (
                      <MenuItem key={option.ufId} value={option.ufId}>
                        {option.sigla}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <FormControl>
                  <TextField label="Cidade" select size="small" className="customFilledInput"
                    onChange={e => changes(e.target.value, 'cidade2')}
                    value={formPage.cidade2}
                    variant="filled"
                    disabled={onlyRead || espelho}
                  >
                    {cidades2.map((option) => (
                      <MenuItem key={option.cidadeId} value={option.cidadeId}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            </div>

            {!onlyRead &&
              <>
                <div className="row">
                  <div className='col '>
                    <h3>Configuração de acesso</h3>
                  </div>
                </div>
                <div className='dataPassword'>
                  <FormControl>
                    <TextField label="Senha" type="password" size="small" className="customFilledInput"
                      onChange={e => changes(e.target.value, 'senha')}
                      value={formPage.senha}
                      variant="filled"
                      disabled={onlyRead || espelho}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField label="Confirmar Senha" type="password" size="small" className="customFilledInput"
                      onChange={e => changes(e.target.value, 'senhaConfirmar')}
                      value={formPage.senhaConfirmar}
                      variant="filled"
                      disabled={onlyRead || espelho}
                    />
                  </FormControl>
                </div>
                <PasswordStrength password={formPage.senha} />
              </>
            }

            {
              !onlyRead ? (
                <div className={styles.blueBackground}>
                  <div className={styles.content}>
                    <div className={styles.card}>

                      {<AcoesUser status={ativo}
                        key={user.userId}
                        encerrar={ativarInativar}
                        enviar={action}
                        disabled={false}
                        msgDialog={'Deseja cancelar esta ação? Todos os dados não salvos serão perdidos.'}
                        redirect={`/cadastro-loja`}
                        claimName={'Participantes'}
                      />}
                    </div>
                  </div>
                </div>
              ) : null
            }
            <ModalLoading
              open={modalLoading}
              size="lg"
            />

          </div>
          <div className="espacoAzul"></div>
    </div>
    </div>
  );
}

export default MeusDados;