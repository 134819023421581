import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss'
import { useGetUser } from '../../hooks/useGetUser'
import { useLocation, useParams } from 'react-router-dom';
import services from '../../services';


const AcoesUser = ({ status, encerrar, enviar, disabled, msgDialog, redirect, claimName }) => {

    const user = useGetUser();    
    const history = useHistory();
    const [update, setUpdate] = useState(false);
    const [add, setAdd] = useState(false);
    const [ativarInativar, setAtivarInativar] = useState(false);
    const [meuCadastro, setMeuCadastro] = useState(false);
    const [novo, setNovo] = useState(false);
    const location = useLocation();
    const { idParticipante } = useParams();

    useEffect(() => {        
        setAtivarInativar(services.storage.getClaimValues(claimName, "Ativar_Inativar"))
        setUpdate(services.storage.getClaimValues(claimName, "Alterar"))
        setAdd(services.storage.getClaimValues(claimName, "Cadastrar"))
        
        if (location.pathname === "/meu-cadastro") {
            setMeuCadastro(true);
        }

        if(idParticipante === "null"){
            setNovo(true);
        }
      
    },[user, status])

    return (

        <div className={styles.card}>

            <div className={styles.subtitle}>
                    Ações
                </div>
            {(user.userId && status && ativarInativar && (!meuCadastro) && (!novo)) ? (
                <button className={styles.encerrar} onClick={encerrar}>
                    Inativar
                </button>
            ) : null}
            {(user.userId && (!status) && ativarInativar && (!meuCadastro) && (!novo)) ? (
                <button className={styles.ativar} onClick={encerrar}>
                    Ativar
                </button>
            ) : null}
            {add || update ? (
                <button className={styles.encerrar} onClick={() => { if (window.confirm(msgDialog)) { history.push(redirect); } }}>
                    Cancelar
                </button>
            ) : null}
            {
//                add || update ? (
                    <button className={styles.salvar} onClick={enviar} disabled={disabled} >
                        Salvar
                    </button>
//                ) : null
            }

        </div>
    )
}

export default AcoesUser