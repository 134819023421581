import React from 'react';
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom';

const BeneficioGaleria = ({ beneficio }) => {

    const history = useHistory();

    return (
        <div className={styles.wrapper}>
            <div className={styles.bodyCard}>
                { beneficio.status === 0 && (<div className={styles.encerrado}>ENCERRADO</div>) }
                { beneficio.status === 1 && (<div className={styles.andamento}>DISPONÍVEL</div>) }
                <img className={styles.desktop} src={beneficio.linkDestaqueDesktop} alt={beneficio.titulo}/>
                <img className={styles.mobile} src={beneficio.linkDestaqueDesktop} alt={beneficio.titulo}/>
            </div>

            <div className={styles.actionWrapper}>
                <button className={styles.saibaMais} onClick={() => {history.push(`/galeria-vitrine/detalhe?IdVitrineBV=${beneficio.idVitrineBV}`)}}>
                    Saiba mais
                </button>
            </div>

        </div>
    )
}

export default BeneficioGaleria;