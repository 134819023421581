import React from 'react';

import './styles.scss';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const FormTermosUso = ({action}) => {

  const fecharTermosUso= () => {
    action()
  }

  return (
    <div className="pageBody">
      <div className="container-fluid regulamentoPage">

        <div className="row">
          <div className='col text-left'>
            <h1>Termos de uso</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-12">
            <iframe 
                className="regulamento" 
                src="https://devpartnerstorage.blob.core.windows.net/bvsolar/regulamento/c54a95dd-0811-4e04-943c-fc4ecde862b0.pdf" 
                frameborder="0"></iframe>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className='col-ms-12 col-md-4'>
            <button className="btn btnVoltar btn-lg btn-block" onClick={fecharTermosUso}>Voltar</button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default FormTermosUso;