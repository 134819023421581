import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Recaptcha from 'react-google-recaptcha';

import './styles.scss';

/////////////////////////////
// redux
import { useDispatch } from 'react-redux';
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction,
} from '../../store/actions/snackbarActions.js';

import { trackPromise } from 'react-promise-tracker';
import moment from 'moment';

import services from '../../services';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import FormLogin from './FormLogin';
import FormCadastroCandidato from './FormCadastroCandidato';
import FormCadastroEmpresa from './FormCadastroEmpresa';
import Modal from '../../components/Modal/Modal';

import ExisteParceiro from './FormExisteParceiro';
import ConfirmarCadastro from './FormConfirmacaoCadastro';
import CompletarCadastroParceiro from './FormCompletarCadastroParceiro';
import FormRegulamento from './FormRegulamento';
import TermosUso from './FormTermosUso';
import PreCadastro from './FomPreCadastro';

import footerCompany from '../../assets/img/footer.png';
import globals from '../../globals';

const RECAPTCHA_KEY = globals.REACT_APP_RECAPTCHA_KEY_AUTO;

// const formLoginInitialState = { login: '51888562048', senha: 'aD#123456' } // candidato
// const formLoginInitialState = { login: '83338111754', senha: 'aD#123456' } // recrutador
const formLoginInitialState = { login: '', senha: '' };

const formExisteParceiroInitialState = {
  documento: '',
  codigo: '',
};

const formCompletarCadastroParceiroInitialState = {
  nomeCompleto: '',
  cpf: '',
  dataNascimento: null,
  email: '',
  celular: '',
  cargo: '',
  cnpj: '',
  senha: '',
  confirmarSenha: '',
  razaoSocial: '',
  cnpj: '',
  parceiroId: '',
  aceiteTermos: false,
};

const formCadastroInitialState = {
  nome: '',
  email: '',
  cpf: '',
  senha: '',
  senhaConfirmacao: '',
  aceiteRegulamento: false,
  aceiteTermos: false,
  aceiteAviso: false,
};

const formCadastroEmpInitialState = {
  nome: '',
  nomeEmpresa: '',
  email: '',
  cpf: '',
  cnpj: '',
  telefone: '',
  senha: '',
  senhaConfirmacao: '',
  aceiteTermosRegulamentos: false,
};

const Login = (props) => {
  const history = useHistory();
  const recaptcha = useRef(null);
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const showMsgS = (msg) => dispatch(showMsgSAction(msg));

  const [cpfRecuperaSenha, setCpfRecuperaSenha] = useState('');
  const [emailRecuperaSenha, setEmailRecuperaSenha] = useState('');
  const [disableNomeEmpresa, setDisableNomeEmpresa] = useState(false);
  const [showSucessM, setShowSucessM] = useState(false);

  const [typeUser, setTypeUser] = useState(false);
  const [formLogin, setFormLogin] = useState({ ...formLoginInitialState });
  const [formCadastro, setFormCadastro] = useState({
    ...formCadastroInitialState,
  });
  const [formCadastroEmp, setFormCadastroEmp] = useState({
    ...formCadastroEmpInitialState,
  });

  const [formParceiro, setFormParceiro] = React.useState({
    ...formExisteParceiroInitialState,
  });
  const [formCompletarCadastroParceiro, setFormCompletarCadastroParceiro] =
    React.useState({ ...formCompletarCadastroParceiroInitialState });

  const [indicarErrosLogin, setIndicarErrosLogin] = useState(false);
  const [indicarErrosCadastro, setIndicarErrosCadastro] = useState(false);
  const [indicarErrosCadastroEmp, setIndicarErrosCadastroEmp] = useState(false);

  const [indicarErrosExisteParceiro, setIndicarErrosExisteParceiro] =
    React.useState(false);
  const [indicarErrosConfirmarCadastro, setIndicarErrosConfirmarCadastro] =
    React.useState(false);
  const [
    indicarErrosCompletarCadastroParceiro,
    setIndicarErrosCompletarCadastroParceiro,
  ] = React.useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const [openModalPrimeiroAcesso, setOpenModalPrimeiroAcesso] =
    React.useState(false);
  const [openModalConfirmarCadastro, setOpenModalConfirmarCadastro] =
    React.useState(false);
  const handleOpenModalPrimeiroAcesso = () => {
    //setOpenModalRegulamento(true);
    //setOpenModalCompletarCadastro(true)
    setOpenModalPrimeiroAcesso(true);
    //setOpenModalPreCadastro(true)
  };

  const [openModalCompletarCadastro, setOpenModalCompletarCadastro] =
    React.useState(false);
  const handleOpenModalCompletarCadastro = () => {
    setOpenModalCompletarCadastro(true);
  };

  const [openModalRegulamento, setOpenModalRegulamento] = React.useState(false);
  const handleOpenModalRegulamento = () => {
    setOpenModalRegulamento(true);
  };
  const [openModalTermosUso, setOpenModalTermosUso] = React.useState(false);
  const handleOpenModalTermosUso = () => {
    setOpenModalTermosUso(true);
  };

  const [openModalPreCadastro, setOpenModalPreCadastro] = React.useState(false);
  const handleOpenModalPreCadastro = () => {
    setOpenModalPreCadastro(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };
  const closeModalPrimeiroAcesso = () => {
    setOpenModalPrimeiroAcesso(false);
  };
  const closeModalConfirmarCadastro = () => {
    setOpenModalConfirmarCadastro(false);
  };

  const closeModalCompletarCadastro = () => {
    setOpenModalCompletarCadastro(false);
  };
  const closeModalRegulamento = () => {
    setOpenModalRegulamento(false);
  };
  const closeModalTermosUso = () => {
    setOpenModalTermosUso(false);
  };
  const closeModalPreCadastro = () => {
    setOpenModalPreCadastro(false);
  };

  const [permiteEditarCargo, setPermiteEditarCargo] = React.useState(true);

  const logar = () => {
    if (!formLogin.login || !formLogin.senha) {
      setIndicarErrosLogin(true);
      showMsgW('Informe os campos obrigatórios');
      return false;
    }
    if (!services.validate.valideCpf(services.mask.unMask(formLogin.login))) {
      showMsgW('Informe um cpf válido');
      return false;
    }

    trackPromise(
      services.api
        .logar({
          userName: services.mask.unMask(formLogin.login),
          password: formLogin.senha,
        })
        .then((res) => {
          console.log('RESSSSSSSSSSSSSS', res);
          if (!res.fail) {
            localStorage.setItem('authSistema', JSON.stringify(res.data));
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('product', 'auto');
            localStorage.setItem('isNavegationBlocked', false);
            history.push('/home');
          } else {
            showMsgE(res.errors ? res.errors.message : 'Erro');
          }
        })
        .catch((error) => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error');
        })
    );
  };

  const abrirRegumaneto = () => {
    handleOpenModalRegulamento();
  };
  const abrirTermosUso = () => {
    handleOpenModalTermosUso();
  };

  const backToExisteParceiro = () => {
    setOpenModalConfirmarCadastro(false);
    setOpenModalPrimeiroAcesso(true);
  };

  const existeParceiroNaBase = () => {
    if (!formParceiro.documento) {
      setIndicarErrosExisteParceiro(true);
      showMsgW('Campo CNPJ é obrigatório');
      return false;
    }

    if (
      !services.validate.valideCnpj(
        services.mask.unMask(formParceiro.documento)
      )
    ) {
      setIndicarErrosExisteParceiro(true);
      showMsgW('Informe um CNPJ válido');
      return false;
    }

    let cnpj = services.mask.unMask(formParceiro.documento);

    let formatObt = {};
    formatObt.cnpj = cnpj;

    trackPromise(
      services.api
        .existeParceiroNaBase(formatObt)
        .then((res) => {
          if (!res.fail) {
            if (res.data == null) {
              showMsgW('CNPJ não encontrado na base.');
            } else {
              setFormCompletarCadastroParceiro({
                ...formCompletarCadastroParceiroInitialState,
              });

              setFormCompletarCadastroParceiro((cnpj) => ({
                ...cnpj,
                ['cnpj']: res.data.documento,
              }));
              setFormCompletarCadastroParceiro((parceiroId) => ({
                ...parceiroId,
                ['parceiroId']: res.data.parceiroId,
              }));

              setOpenModalPrimeiroAcesso(false);
              setOpenModalConfirmarCadastro(true);
            }
          } else {
            setFormCompletarCadastroParceiro({
              ...formCompletarCadastroParceiroInitialState,
            });
            showMsgE('Empresa não encontrada.');
          }
        })
        .catch((error) => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error');
        })
    );
  };

  const searchCpf = () => {
    if (services.validate.valideCpf(formCompletarCadastroParceiro.cpf)) {
      if (recaptcha.current) {
        trackPromise(
          recaptcha.current
            .executeAsync()
            .then((recaptchaToken) => {
              services.storage.set('captcha', recaptchaToken);
              services.api
                .searchCpf({
                  cpf: services.mask.unMask(formCompletarCadastroParceiro.cpf),
                })
                .then((res) => {
                  if (!res.fail) {
                    if (res.data !== null) {
                      setFormCompletarCadastroParceiro({
                        ...formCompletarCadastroParceiro,
                        nomeCompleto: res.data.nomeCompleto,
                        email: res.data.email,
                        celular: res.data.phoneNumber,
                        cargo: res.data.nomePerfil,
                      });
                      setPermiteEditarCargo(
                        res.data.nomePerfil == 'F&I' ||
                          res.data.nomePerfil == 'Vendedor(a)'
                      );
                    }
                  } else {
                    setFormCompletarCadastroParceiro({
                      ...formCompletarCadastroParceiro,
                      nomeCompleto: '',
                      email: '',
                      celular: '',
                      cargo: '',
                    });
                    setPermiteEditarCargo(true);
                  }
                })
                .catch((error) => {
                  showMsgE(
                    error.errors.length ? error.errors[0].message : 'Error'
                  );
                });
            })
            .catch((error) => {
              console.log(error);
            })
        );
      }
    }
  };

  const codigoParceiroNaBase = () => {
    let cnpj = services.mask.unMask(formParceiro.documento);

    let formatObt = {};
    formatObt.cnpj = cnpj;
    formatObt.codigo = formParceiro.codigo;

    trackPromise(
      services.api
        .codigoParceiro(formatObt)
        .then((res) => {
          if (!res.fail) {
            if (res.data == null) {
              setOpenModalPrimeiroAcesso(false);

              setOpenModalCompletarCadastro(true);
              setOpenModalConfirmarCadastro(false);
            } else {
              setOpenModalCompletarCadastro(true);
              setOpenModalConfirmarCadastro(false);
            }
          } else {
            setFormCompletarCadastroParceiro({
              ...formCompletarCadastroParceiroInitialState,
            });
            showMsgE('Código não encontrado.');
            //showMsgE(res.errors.length ? res.errors[0].message : 'Error')
          }
        })
        .catch((error) => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error');
        })
    );
  };

  const completarCadastroParceiroNaBase = (event, campo) => {
    if (!formCompletarCadastroParceiro.parceiroId) {
      if (!formCompletarCadastroParceiro.nomeCompleto) {
        setIndicarErrosCompletarCadastroParceiro(true);
        showMsgW('Informe os campos obrigatórios');
        return false;
      }
    }

    if (!formCompletarCadastroParceiro.cpf) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Informe os campos obrigatórios');
      return false;
    }
    if (
      !services.validate.valideCpf(
        services.mask.unMask(formCompletarCadastroParceiro.cpf)
      )
    ) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Informe um CPF válido');
      return false;
    }

    if (!formCompletarCadastroParceiro.dataNascimento) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Informe os campos obrigatórios');
      return false;
    }

    if (
      !new moment(formCompletarCadastroParceiro.dataNascimento).isValid() &&
      formCompletarCadastroParceiro.dataNascimento
    ) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Informe uma Data de Nascimento válida');
      return false;
    }

    if (!formCompletarCadastroParceiro.email) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Informe os campos obrigatórios');
      return false;
    }

    if (!services.validate.valideMail(formCompletarCadastroParceiro.email)) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Informe um E-mail válido');
      return false;
    }

    if (!formCompletarCadastroParceiro.celular) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Informe os campos obrigatórios');
      return false;
    }

    if (
      !services.validate.valideCellphone(
        services.mask.unMask(formCompletarCadastroParceiro.celular)
      )
    ) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Informe um número de celular válido');
      return false;
    }

    if (!formCompletarCadastroParceiro.cargo) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Informe os campos obrigatórios');
      return false;
    }

    if (!formCompletarCadastroParceiro.senha) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Informe os campos obrigatórios');
      return false;
    }

    if (!formCompletarCadastroParceiro.confirmarSenha) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Informe os campos obrigatórios');
      return false;
    }

    if (
      !services.validate.validePasswordSize(formCompletarCadastroParceiro.senha)
    ) {
      showMsgW('Senha deve ter mínimo 8 caracteres');
      return false;
    }

    if (
      !services.validate.validePasswordNumber(
        formCompletarCadastroParceiro.senha
      )
    ) {
      showMsgW('Senha deve ter ao menos 1 número');
      return false;
    }

    if (
      !services.validate.validePasswordCapital(
        formCompletarCadastroParceiro.senha
      )
    ) {
      showMsgW('Senha deve ter letras maiúsculas e minúsculas');
      return false;
    }

    if (
      !services.validate.validePasswordSpecial(
        formCompletarCadastroParceiro.senha
      )
    ) {
      showMsgW('Senha deve ter caracteres especiais');
      return false;
    }

    if (
      formCompletarCadastroParceiro.senha !==
      formCompletarCadastroParceiro.confirmarSenha
    ) {
      showMsgW('Senha e Confirme sua senha devem ser iguais');
      return false;
    }

    if (!formCompletarCadastroParceiro.aceiteTermos) {
      setIndicarErrosCompletarCadastroParceiro(true);
      showMsgW('Necessário aceitar o regulamento para completar o cadastro');
      return false;
    }

    let formatObt = {};
    formatObt.nome = formCompletarCadastroParceiro.nomeCompleto;
    formatObt.senha = formCompletarCadastroParceiro.senha;
    formatObt.confirmarSenha = formCompletarCadastroParceiro.confirmarSenha;
    formatObt.email = formCompletarCadastroParceiro.email;
    formatObt.cpf = services.mask.unMask(formCompletarCadastroParceiro.cpf);
    formatObt.phoneNumber = services.mask.unMask(
      formCompletarCadastroParceiro.celular
    );
    formatObt.aceiteRegulamento =
      formCompletarCadastroParceiro.aceiteRegulamento;
    formatObt.nomePerfil = formCompletarCadastroParceiro.cargo;
    formatObt.userName = services.mask.unMask(
      formCompletarCadastroParceiro.cpf
    );

    formatObt.cnpj = services.mask.unMask(formParceiro.documento);
    formatObt.razaoSocial = formCompletarCadastroParceiro.nomeCompleto;

    formatObt.dataNascimento = formCompletarCadastroParceiro.dataNascimento;

    if (recaptcha.current) {
      trackPromise(
        recaptcha.current
          .executeAsync()
          .then((recaptchaToken) => {
            services.storage.set('captcha', recaptchaToken);
            services.api
              .preCadastro(formatObt)
              .then((res) => {
                if (!res.fail) {
                  closeModalCompletarCadastro();
                  handleOpenModalPreCadastro();
                } else {
                  showMsgE(
                    res.errors.message != null ? res.errors.message : 'Error'
                  );
                }
              })
              .catch((error) => {
                showMsgW('Erro ao concluir pré-cadastro');
                showMsgE(
                  error.errors.length ? error.errors[0].message : 'Error'
                );
              });
          })
          .catch((error) => {
            console.log(error);
          })
      );
    }
  };

  const handleChangeExisteParceiro = (event, campo) => {
    event.persist();
    let value = event.target.value;
    value = services.mask.cnpj(value);
    setFormParceiro((documento) => ({ ...documento, ['documento']: value }));
  };

  const handleCodigoParceiro = (event) => {
    event.persist();
    let value = event.target.value;
    setFormParceiro((codigo) => ({ ...codigo, ['codigo']: value }));
  };

  const handleChangeCompletarCadastroParceiro = (value, campo) => {
    setFormCompletarCadastroParceiro((prevstate) => ({
      ...prevstate,
      [campo]: value,
    }));
  };

  const recuperarSenha = () => {
    let emailRec = emailRecuperaSenha.cpf;
    if (emailRec != null)
      // if (!cpf || !services.validate.valideCpf(cpf)) {
      //   showMsgW('informe um cpf válido')
      //   return false
      // }
      ;
    trackPromise(
      services.api
        .resetarSenha({ email: emailRec })
        .then((res) => {
          if (!res.fail) {
            setShowSucessM(true);
            // showMsgS('Uma nova senha foi envia para o e-mail cadastrado ')
            // closeModal()
          } else {
            showMsgE(res.errors.length ? res.errors[0].message : 'Error');
            setShowSucessM(false);
          }
        })
        .catch((error) => {
          setShowSucessM(false);
          showMsgE(error.errors.length ? error.errors[0].message : 'Error');
        })
    );
  };

  const handleChangeLogin = (value, campo) => {
    setFormLogin((prevstate) => ({ ...prevstate, [campo]: value }));
  };

  const handleChangeRecuperarSenha = (value, campo) => {
    //  const cpfMask = value => {
    const emailRegex = /\S+@\S+\.\S+/;
    // const validateEmail = (event) => {
    //   const email = event.target.value;
    //   if (emailRegex.test(email)) {
    //    return false;

    //   } else {

    //     return true
    //   }
    // };
    // }

    setEmailRecuperaSenha((prevstate) => ({ ...prevstate, [campo]: value }));
  };
  const handleChangeCadastroParticipante = (value, campo) => {
    setFormCadastro((prevstate) => ({ ...prevstate, [campo]: value }));
  };

  const handleChangeCadastroEmpresa = (value, campo) => {
    if (campo === 'cnpj') {
      let unmaskCnpj = services.mask.unMask(value);
      if (unmaskCnpj.length === 14) {
        console.log(' && value', value);
        buscarEmpresaPorCnpj(value);
      }
    }
    setFormCadastroEmp((prevstate) => ({ ...prevstate, [campo]: value }));
  };

  const cadastrarCandidato = () => {
    if (
      !formCadastro.nome ||
      !formCadastro.email ||
      !formCadastro.cpf ||
      !formCadastro.senha ||
      !formCadastro.senhaConfirmacao
    ) {
      setIndicarErrosCadastro(true);
      showMsgW('Informe os campos obrigatórios');
      return false;
    }
    if (!services.validate.valideCpf(services.mask.unMask(formCadastro.cpf))) {
      showMsgW('Informe um cpf válido');
      return false;
    }
    if (formCadastro.senha.length < 6) {
      showMsgW('Senha deve ter mais que 6 caracteres');
      return false;
    } else if (formCadastro.senha !== formCadastro.senhaConfirmacao) {
      showMsgW('Senha e Repetir Senha devem ser iguais');
      return false;
    }
    if (!formCadastro.aceiteRegulamento) {
      showMsgW('Necessário aceitar o regulamento');
      return false;
    }
    if (!formCadastro.aceiteAviso) {
      showMsgW('Necessário aceitar recebimento de comunicação');
      return false;
    }

    let formatObt = {};
    formatObt.nome = formCadastro.nome;
    formatObt.email = formCadastro.email;
    formatObt.cpf = services.mask.unMask(formCadastro.cpf);
    formatObt.senha = formCadastro.senha;
    formatObt.aceiteRegulamento = formCadastro.aceiteRegulamento;
    formatObt.aceiteAviso = formCadastro.aceiteAviso;

    console.log('formatObt', formatObt);
    trackPromise(
      services.api
        .candidatoPreCadastro(formatObt)
        .then((res) => {
          if (res.data) {
            showMsgS(res.data);
            setFormCadastro({ ...formCadastroInitialState });
            setIndicarErrosCadastro(false);
            closeModal();
          } else {
            showMsgE(res.errors.length ? res.errors.message : 'Erro');
          }
        })
        .catch((error) => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error');
        })
    );
  };

  const cadastrarEmpresa = () => {
    if (
      !formCadastroEmp.nome ||
      !formCadastroEmp.email ||
      !formCadastroEmp.telefone ||
      !formCadastroEmp.cnpj ||
      !formCadastroEmp.cpf ||
      !formCadastroEmp.nomeEmpresa
    ) {
      setIndicarErrosCadastroEmp(true);
      showMsgW('Informe os campos obrigatórios');
      return false;
    }
    if (
      !services.validate.valideCnpj(services.mask.unMask(formCadastroEmp.cnpj))
    ) {
      showMsgW('Informe um cnpj válido');
      return false;
    }
    if (
      !services.validate.valideCpf(services.mask.unMask(formCadastroEmp.cpf))
    ) {
      showMsgW('Informe um cpf válido');
      return false;
    }
    // if (formCadastroEmp.senha.length < 6) {
    //   showMsgW('Senha deve ter mais que 6 caracteres')
    //   return false
    // } else if (formCadastroEmp.senha !== formCadastroEmp.senhaConfirmacao) {
    //   showMsgW('Senha e Repetir Senha devem ser iguais')
    //   return false
    // }
    if (!formCadastroEmp.aceiteTermosRegulamentos) {
      showMsgW('Necessário aceitar o regulamento');
      return false;
    }

    let formatObt = {};
    formatObt.nome = formCadastroEmp.nome;
    formatObt.email = formCadastroEmp.email;
    formatObt.cnpj = services.mask.unMask(formCadastroEmp.cnpj);
    formatObt.cpf = services.mask.unMask(formCadastroEmp.cpf);
    formatObt.nomeEmpresa = formCadastroEmp.nomeEmpresa;
    formatObt.telefone = services.mask.unMask(formCadastroEmp.telefone);
    formatObt.senha = formCadastroEmp.senha;
    formatObt.aceiteTermosRegulamentos =
      formCadastroEmp.aceiteTermosRegulamentos;

    console.log(formatObt);

    trackPromise(
      services.api
        .recrutadorPreCadastro(formatObt)
        .then((res) => {
          if (res.data) {
            //showMsgS('Uma nova senha foi envia para o e-mail cadastrado')
            showMsgS(res.data);
            setIndicarErrosCadastroEmp(false);
            setFormCadastroEmp({ ...formCadastroEmpInitialState });
            closeModal();
          } else {
            showMsgE(res.errors ? res.errors.message : 'Erro');
          }
        })
        .catch((error) => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error');
        })
    );
  };

  const buscarEmpresaPorCnpj = (cnpj) => {
    trackPromise(
      services.api
        .buscarEmpresaPorCnpj(services.mask.unMask(cnpj))
        .then((res) => {
          if (!res.fail) {
            console.log(res.data);
            setFormCadastroEmp((prevstate) => ({
              ...prevstate,
              ['nomeEmpresa']: res.data,
            }));
            setDisableNomeEmpresa(true);
          }
        })
        .catch((error) => {})
    );
  };

  useEffect(() => {
    async function init() {
      let isAuthenticated =  services.storage.get('isAuthenticated');

      if (isAuthenticated) {
        history.push(`/home`);
      }
    }
    init();
  }, []);

  return (
    <div>
      <div className="container-fluid loginPage">
        <div className="row">
          <div className="col-md-6 offset-md-6 boxLogin">
            <FormLogin
              formPage={formLogin}
              action={logar}
              changes={handleChangeLogin}
              modalSenha={handleOpenModal}
              modalPrimeiroAcesso={handleOpenModalPrimeiroAcesso}
              indicarErros={indicarErrosLogin}
            />
          </div>
        </div>

        <Modal
          open={openModalPrimeiroAcesso}
          close={closeModalPrimeiroAcesso}
          size="xl"
        >
          <ExisteParceiro
            formPage={formParceiro}
            action={existeParceiroNaBase}
            changes={handleChangeExisteParceiro}
            indicarErros={indicarErrosExisteParceiro}
          />
        </Modal>

        <Modal
          open={openModalConfirmarCadastro}
          close={closeModalConfirmarCadastro}
          size="lg"
        >
          <ConfirmarCadastro
            formPage={formParceiro}
            action={codigoParceiroNaBase}
            back={backToExisteParceiro}
            changes={handleCodigoParceiro}
            indicarErros={indicarErrosExisteParceiro}
          />
        </Modal>

        <Modal
          open={openModalCompletarCadastro}
          close={closeModalCompletarCadastro}
          size="lg"
        >
          <Recaptcha ref={recaptcha} sitekey={RECAPTCHA_KEY} size="invisible" />
          
          <CompletarCadastroParceiro
            formPage={formCompletarCadastroParceiro}
            action={completarCadastroParceiroNaBase}
            search={searchCpf}
            changes={handleChangeCompletarCadastroParceiro}
            indicarErros={indicarErrosCompletarCadastroParceiro}
            openRegulamento={abrirRegumaneto}
            permiteEditarCargo={permiteEditarCargo}
          />
        </Modal>

        <Modal
          open={openModalRegulamento}
          close={closeModalRegulamento}
          size="lg"
        >
          <FormRegulamento action={closeModalRegulamento} />
        </Modal>

        <Modal open={openModalTermosUso} close={closeModalTermosUso} size="lg">
          <TermosUso action={closeModalTermosUso} />
        </Modal>

        <Modal
          open={openModalPreCadastro}
          close={closeModalPreCadastro}
          size="lg"
        >
          <PreCadastro action={closeModalPreCadastro} />
        </Modal>

        <Modal open={openModal} close={closeModal} size="lg">
          <div className="pad40">
            <h2 className="mb40">
              Informe seu e-mail cadastrado para receber a nova senha
            </h2>

            <FormControl>
              <TextField
                label="E-mail"
                type="text"
                className="inputCustom"
                size="small"
                value={emailRecuperaSenha.cpf || ''}
                onChange={(event) =>
                  handleChangeRecuperarSenha(event.target.value, 'cpf')
                }
              />
            </FormControl>
            <button
              className="btn btn-dark btn-block btn-lg mt20 buttonCustom"
              onClick={recuperarSenha}
            >
              Resetar
            </button>
          </div>

          <Modal open={showSucessM} size="lg" close={closeModal}>
            <div className="pad40">
              <h2 className="mb40">Nova senha enviada com sucesso.</h2>
              <span>
                {' '}
                Em breve você receberá um e-mail com sua senha. Caso não receba,
                verifique sua pasta de SPAM.
              </span>
            </div>
            <button
              className="btn btnOut btn-block btn-lg mt20"
              onClick={closeModal}
            >
              {' '}
              Ir para Login
            </button>
          </Modal>
        </Modal>
      </div>

      <div className="container-fluid footerPage">
        <div className="row footerPage">
          <div className="col-md-12 footerPage">
            <div>
              <img src={footerCompany} alt=" " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
