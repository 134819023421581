import React from 'react';
import './styles.scss';
import DadosEmpresa from './DadosEmpresa';

const Loja = () => {

  return (
    <div className='MeuCadastro'>
      <h1 className='container'>Cadastro</h1>
      <DadosEmpresa />
    </div>
  );
  
}

export default Loja;