import React from 'react';
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom';
import moment from "moment";
import "moment/locale/pt-br";
import services from '../../services';
import { trackPromise } from 'react-promise-tracker';
import {
    showMsgE as showMsgEAction,
  } from '../../store/actions/snackbarActions.js';
import { useDispatch } from 'react-redux';


const ConteudoCard = ({ conteudo }) => {
    const dispatch = useDispatch();
    const showMsgE = (msg) => dispatch(showMsgEAction(msg));
    const history = useHistory();


    const visualizou = () => {
        trackPromise(
          services.api
            .visualizouPopUp(conteudo.id)
            .then((res) => {
                if(res.fail != true){

                    history.push(`/conteudo/detalhe?ConteudoId=${conteudo.id}`)
                }
            })
            .catch((error) => {
              showMsgE(error.errors.length ? error.errors[0].message : 'Error');
            })
        );
      };
    return (
        <div className={styles.wrapperCard}>
            <div className={styles.bodyCard}>
                <img className={styles.desktop} src={`data:${conteudo.imagemDestaqueDesktop?.contentType};base64,${conteudo.imagemDestaqueDesktop?.fileContents}`} alt={conteudo.titulo}/>
                <img className={styles.mobile} src={`data:${conteudo.imagemDestaqueMobile?.contentType};base64,${conteudo.imagemDestaqueMobile?.fileContents}`} alt={conteudo.titulo}/>
            </div>

            <div className={styles.actionWrapper}>
                <small>Criado em {new moment(conteudo.dataCadastro).format('DD/MM/YYYY')}</small>
                <div>
                    <b>{conteudo.nome}</b>
                    <div className={styles.textoDestaque}>
                        {conteudo.textoDestaque}
                    </div>
                </div>
                <button className={styles.saibaMais} onClick={() => {visualizou();}}>
                    Saiba mais
                </button>
            </div>

        </div>
    )
}

export default ConteudoCard;