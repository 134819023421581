import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { getNoticiaList } from '../../services/api';
import NoticiaCard from '../../components/NoticiaCard';
import ModalLoading from '../../components/ModalLoading';

const Noticia = () => {

    const history = useHistory();
    const [noticia, setNoticia] = useState([])
    const [modalLoading, setModalLoading] = useState(false);
    

    const getNoticia = async() => {
        setModalLoading(true)
        const data = await getNoticiaList();
        setModalLoading(false)
        setNoticia(data.data)
    } 
    useEffect(()=>{
        getNoticia()
    },[])

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Notícias
            </div>
            <div className={styles.acoesWrapper}>
                {noticia?.map(
                    (q) => <NoticiaCard noticia={q}/>
                )}
            </div>
            {
                noticia.items?.length === 0 &&
                <div className={styles.verMaisWrapper}> Não existem dados para exibir </div>
            }
            <ModalLoading open={modalLoading}  size={"lg"} />
        </div>
    );
  
}

export default Noticia;