import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import "moment/locale/pt-br";
import styles from './styles.module.scss';
import { getNoticiaById } from '../../../services/api';
import { useDispatch } from "react-redux";
import { 
    showMsgW as showMsgWAction,
    showMsgE as showMsgEAction,
    showMsgS as showMsgSAction
} from '../../../store/actions/snackbarActions.js';
import ModalLoading from '../../../components/ModalLoading'




const NoticiaDetalhe = () => {
    const dispatch = useDispatch();
    const showMsgW = (msg) => dispatch(showMsgWAction(msg));
    const showMsgE = (msg) => dispatch(showMsgEAction(msg));
    const showMsgS = (msg) => dispatch(showMsgSAction(msg));

    const history = useHistory();
    const [data, setData] = useState([]);
    const [modalLoading, setModalLoading] = useState(false);

    const getNoticia = async (id) => {

        if(id) {
            setModalLoading(true);
            try{
                const info = await getNoticiaById(id);
                setData(info.data);
                setModalLoading(false);
            } catch(e){
                showMsgW('Não foi possível carregar os dados');
                setModalLoading(false);
            }
            
        }

    }

    useEffect( () => {
        const id = new URLSearchParams(history.location.search).get("NoticiaId");
        getNoticia(id);
    },[])

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    Notícia
                    <button className={styles.backButton} onClick={() => {history.goBack()}}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                </div>

                <div className={styles.blueBackground}>
                    <div className={styles.content}>
                        <div className={styles.card}>
                            <div className={styles.imagemBeneficio}>
                                <img className={styles.desktop} src={`data:${data.imagemConteudoDesktop?.contentType};base64,${data.imagemConteudoDesktop?.fileContents}`}  alt={data.titulo} />
                                <img className={styles.mobile} src={`data:${data.imagemConteudoMobile?.contentType};base64,${data.imagemConteudoMobile?.fileContents}`} alt={data.titulo} />
                            </div>
                            <div className={styles.tituloBeneficio}>
                                {data.titulo}
                            </div>
                            <div className={styles.dtCadastro}>
                                Publicado em {new moment(data.dataCadastro).format('DD/MM/YYYY')}
                            </div>
                            <div className={styles.conteudo}>
                                <div dangerouslySetInnerHTML={{__html: data.textoConteudo?.replaceAll('\n', '<br/>')}}></div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <ModalLoading open={modalLoading}/>
        </>
    );
}

export default NoticiaDetalhe;