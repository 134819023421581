import React from 'react';

import './styles.scss';

import services from '../../services'

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const FormExisteParceiro = ({formPage, action, changes, indicarErros}) => {

  const existeParceiroNaBase = () => {
    action()
  }

  const correctCnpj = services.validate.valideCnpj(formPage.documento)

  return (
    <div className="pageBody">
      <div className="container-fluid existeParceiroPage">

        <div className="row">
          <div className='col text-left'>
            <h1><b>Primeiro acesso</b></h1>
          </div>
        </div>

        <div className="row">
          <div className='col text-left'>
            <h5>Informe o CNPJ da sua empresa</h5>
          </div>
        </div>

        <div className="row">
          <div className='col-sm-12'>
            <FormControl  >
              <TextField label="CNPJ" type="text"  size="small"
                onChange={event => changes(event, 'documento')}
                value={formPage.documento}
                helperText={indicarErros && !formPage.documento && 'Campo obrigatório'}
                error={indicarErros && !formPage.documento ? true : false} 
                variant="filled"
              />
            </FormControl>
          </div>
        </div>

        <div className="row">
          <div className='col-12 padB20'>
            <button className={(correctCnpj) ? 'btn btnIrPrimeiroCadastro btn-lg btn-block activeButton' : "btn btnIrPrimeiroCadastro btn-lg btn-block"} 
              onClick={existeParceiroNaBase}
            >
                Ir Para O Cadastro
            </button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default FormExisteParceiro;