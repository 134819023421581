import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import FullPage from './Fullpage'
import globals from '../globals';
//import Default from './Default'

const DefaultRoutes = () => {
    const history = useHistory();

    if(window.location.hostname === 'localhost'){
        history.push('/')
        return false;
    } else {
        window.location.href = globals.REACT_APP_AUTH_APP
        return false;
    }
    return false
}

export default DefaultRoutes