import React, { useEffect } from 'react';
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom';
import moment from "moment";
import "moment/locale/pt-br";
import services from '../../services';
import { useState } from 'react';

const TreinamnetoGaleria = ({ treinamento }) => {
    const [porcentagem, setPorcentagem] = useState(0)
    const history = useHistory();
    let informacoesCertificado = {
        tituloCurso: treinamento.titulo,
        dataCurso: treinamento.dataConclusao,
        porcentagem: treinamento.porcentagem,
        nomeParticipante: treinamento.participante
    };
    
    const handleClick = () => {
        
        localStorage.setItem('RESPONDIDO', JSON.stringify(treinamento.respondido));
        localStorage.setItem('PONTOS', JSON.stringify(treinamento.pontos));
        
        
        history.push(`/treinamento-detalhe?TreinamentoId=${treinamento.treinamentoId}`);
    };;
    

    return (
        <div className={styles.wrapper}>
            <div className={styles.bodyCard}>
                <img className={styles.desktop} src={"data:image/png;base64," + treinamento.urlImagemDesktop} alt={treinamento.titulo}/>
                <img className={styles.mobile} src={"data:image/png;base64," + treinamento.urlImagemMobile} alt={treinamento.titulo}/>
            </div>

            <div className={styles.actionWrapper}>
                <small>Ativo até {new moment(treinamento.dataFim).format('DD/MM/YYYY')}</small>
                <div>
                    <b>{treinamento.titulo}</b>
                </div>

                {treinamento.respondido? 
                  informacoesCertificado.porcentagem >= 80 ?
                        <button className={styles.saibaMaisVe} onClick={() => {history.push(`/treinamento-certificado`, { informacoesCertificado })}}>
                            Visualizar Certificado
                        </button>
                    :
                    <button className={styles.saibaMais} onClick={handleClick}>
                     Iniciar treinamento
                    </button>
                :   treinamento.liberado?
                        <button className={styles.saibaMais} onClick={handleClick}>
                            Iniciar treinamento
                        </button>
                    :
                    <button className={styles.saibaMaisA} disabled>
                            Treinamento Bloqueado
                        </button>
                }
            </div>

        </div>
    )
}

export default TreinamnetoGaleria;