import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faArrowLeft, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import "moment/locale/pt-br";
import styles from './styles.module.scss';
import { getTreinamentoById } from '../../../services/api';
import { getTreinamentoByIdRespondidas } from '../../../services/api';
import { useDispatch } from "react-redux";

import services from '../../../services';

import { 
    showMsgW as showMsgWAction,
    showMsgE as showMsgEAction,
    showMsgS as showMsgSAction
} from '../../../store/actions/snackbarActions.js';
import ModalLoading from '../../../components/ModalLoading';
import TextField from '@material-ui/core/TextField';
import { Select, MenuItem, InputLabel, FormControlLabel, Radio, RadioGroup, FormLabel, FormGroup } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { postResponseTreinamento, getTreinamentoResultados } from '../../../services/api';
import Modal from '../../../components/Modal/Modal';
import cloneDeep from 'lodash/cloneDeep';

const dataInit = {
    titulo: "",
    textoDestaque: "",
    periodoAtividade: 0,
    status: 1,
    dataInicio: null,
    dataFim: null,
    urlImagemMobile: "",
    urlImagemDesktop: "",
    tipo: {
        treinamentoTipoId: "7D9C0637-CF9F-485E-8196-347F81E9E4AD"
    },
    ativo: true,
    perguntas: [{
        ordem: 1,
        pergunta: "",
        pontos: null,
        respostas: [
            {
                ordem: 1,
                texto: "",
                correta: true
            },
            {
                ordem: 2,
                texto: "",
                correta: false
            },
            {
                ordem: 3,
                texto: "",
                correta: false
            }]
        }]
}

const questions = {
    perguntas: [
      {
        treinamentoPerguntaId: "",
        respostas: [
          {
            treinamentoRespostaId: "",
            treinamentoResposta: "",
            selecionada: false
          }
        ]
      }
    ]
  }


const TreinamentoDetalhe = () => {
    const dispatch = useDispatch();
    const showMsgW = (msg) => dispatch(showMsgWAction(msg));
    const showMsgE = (msg) => dispatch(showMsgEAction(msg));
    const showMsgS = (msg) => dispatch(showMsgSAction(msg));

    const dataInicial = new Date();

    const history = useHistory();
    const [data, setData] = useState(dataInit);
    const [dataQuestions, setDataQuestions] = useState(questions);
    const [dataQuestionsAnsewered, setDataQuestionsAnsewered] = useState({});
    const [modalLoading, setModalLoading] = useState(false);
    const [treinamentoId, setTreinamentoId] = useState();
    const [treinamentoRespostaId, setTreinamentoRespostaId] = useState("");
    const [pageQuestion, setPageQuestion] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [enviou, setEnviou] = useState(false);

    const [respostasCorretas, setRespostasCorretas] = useState(0);
    const [porcentagemAcertos, setPorcentagemAcertos] = useState(0);
    
    const verificaPagina = async (id) => {
        const resp = await getTreinamentoResultados(id);
        const perguntas = resp.data.perguntas;

        for (let i = 0; i < perguntas.length; i++) {
          const pergunta = perguntas[i];
          if (pergunta.respostas.length === 0) {
            setPageQuestion(i + 1)
            break;
          }
        }

    }

    const getTreinamentoIfEdit = async (id) => {
        
        if(id) {
            setModalLoading(true);
            try {

       
                const info = await getTreinamentoById(id);
                setData(info.data);             
            
                verificaPagina(id)
                if(info.data.respondido) {
                    const resp = await getTreinamentoResultados(id);
                    setDataQuestionsAnsewered(resp.data);
                    setOpenModal(true);
                }

                let quest = { perguntas: [] };

                info.data.perguntas.forEach(x => {
                    var q = {
                        treinamentoPerguntaId: x.id,
                        respostas: [{
                            treinamentoRespostaId: 1,
                            treinamentoResposta: x.resposta1,
                            selecionada: false
                          },
                          {
                            treinamentoRespostaId: 2,
                            treinamentoResposta: x.resposta2,
                            selecionada: false
                          },
                          {
                            treinamentoRespostaId: 3,
                            treinamentoResposta: x.resposta3,
                            selecionada: false
                          }]
                      };

                    quest.perguntas.push(q);
                });
                setDataQuestions(quest);
                
            } catch(e){
                showMsgW('Não foi possível carregar os dados');
            }
            setModalLoading(false);
        }
    }

    const enviar = async () => {
        const currentQuestion = data.perguntas[pageQuestion - 1];
      
        const selectedAnswer = dataQuestions.perguntas.find(
          (x) => x.treinamentoPerguntaId === currentQuestion.id
        ).respostas.find((resp) => resp.selecionada);

        const formdata = {
          treinamentoPerguntaId: currentQuestion.id,
          respostas: [
            {
              treinamentoRespostaId: selectedAnswer.treinamentoRespostaId,
              treinamentoResposta: selectedAnswer.treinamentoResposta,
              selecionada: true,
            },
          ],
        };
      
        setModalLoading(true);
      
        try {
          const info = await postResponseTreinamento(treinamentoId, formdata);
      
          if (info.data) {
            showMsgS("Sua resposta foi enviada com sucesso!");
      
            if (pageQuestion < dataQuestions.perguntas.length) {
              setPageQuestion(pageQuestion + 1);
            } else {
              setOpenModal(true);
            }
      
            const resp = await getTreinamentoResultados(info.data.treinamentoId);
            setDataQuestionsAnsewered(resp.data);
      
            const respostasCorretasAtualizadas = resp.data.perguntas.filter(
              (p) => p.respostas.some((r) => r.selecionada && r.correta)
            ).length;
            setRespostasCorretas(respostasCorretasAtualizadas);
      
            const porcentagem =
              (respostasCorretasAtualizadas / resp.data.perguntas.length) * 100;
            setPorcentagemAcertos(porcentagem);
          }
        } catch (e) {
          showMsgE(e?.response?.data?.message);
        }
      
        setModalLoading(false);
      };
      

    function paginate (array, page_number, page_size = 1) {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    function compare( a, b ) {
        if ( a.ordem < b.ordem ){
            return -1;
        }
        if ( a.ordem > b.ordem ){
            return 1;
        }
        return 0;
    }

    const setArrayRespostaData = (pId, rId) => {

        if(dataInicial.setMinutes(dataInicial.getMinutes() + data.tempo) > new Date() && pageQuestion === 1)
        {
            showMsgW("Tempo mínimo de treinamento ainda não atingido!")
        }
        else
        {
            let dataModify = dataQuestions.perguntas.find(x => x.treinamentoPerguntaId === pId);

            let dataResp = dataModify.respostas.find(x => x.treinamentoRespostaId === rId);

            dataResp.selecionada = true;

            dataModify.respostas.filter(x => x.treinamentoRespostaId !== rId).forEach(x => {
                x.selecionada = false;
            });

            setModalLoading(true);
            setTreinamentoRespostaId(rId);
            setTimeout(() => {
                
                enviar();
              

                if(pageQuestion < dataQuestions.perguntas.length)
                    setModalLoading(false);

                setTreinamentoRespostaId("");
                
            },1000);
        }
        dataInicial.setMinutes(dataInicial.getMinutes() - data.tempo)

    }

    useEffect( () => {
        const id = new URLSearchParams(history.location.search).get("TreinamentoId");
        setTreinamentoId(id);
        getTreinamentoIfEdit(id);
        verificaPagina(id)
        
    },[])

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    {data.titulo}
                    <button className={styles.backButton} onClick={() => {history.goBack()}}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                </div>
                {   data.perguntas && 
                    paginate(data.perguntas.sort(compare), pageQuestion).map((item, i) => (
                        
                        <div className={styles.blueBackground}>
                            <div className={styles.content}>
                                <div style={{width: '50%', float: 'left', padding: '50px 10px 10px 10px'}}>
                                    <iframe src={data.arquivoTreinamento} width="100%" height="550"></iframe>
                                    {data.materialApoio !== null && <div className={styles.subtitle} style={{textAlign: "center"}}><a href={data.materialApoio} target="_blank">Visualizar Material de Apoio</a></div>}
                                </div>

                                <div className={styles.contentQuestions} style={{width: '50%', float: 'left', marginTop: '50px'}}>
                                    <div><small>{`Pergunta ${pageQuestion} de ${data.perguntas.length}`}</small></div>
                                    <div className={styles.subtitle}>{item.pergunta}</div>
                                    <div><small>Selecione a resposta correta</small></div>

                                    { 
                                        dataQuestions?.perguntas?.find(x => x.treinamentoPerguntaId === item.id)?.respostas.map((resp, r) => (
                                            <>
                                                {     
                                                    resp.treinamentoResposta !== ""?
                                                    <>
                                                        <FormControl className={`${styles.halfRadioText} ${dataQuestions?.perguntas?.find(x => x.treinamentoPerguntaId === item.id)?.selecionada ? styles.selected : ""}`}>
                                                            <div className={styles.textResp}>{resp.treinamentoResposta}</div>
                                                            <RadioGroup
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                defaultValue={""}
                                                                value={treinamentoRespostaId}
                                                                style={{flexWrap: "nowrap", position: "relative"}}
                                                                onClick={(e) => {setArrayRespostaData(item.id, resp.treinamentoRespostaId); }}
                                                                className={styles.radioResp}
                                                            >
                                                            <FormControlLabel value={resp.treinamentoRespostaId} control={<Radio style={{color: "#5CCBE1"}}/>} label={""} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </>
                                                    : ""
                                                }
                                             
                                            </>
                                        ))
                                    }
                                </div>
                                <div className={styles.contentQuestions} style={{width: '50%', float: 'left', marginTop: '20px'}}>
                                    <div className={styles.subtitle2}>Instruções do Treinamento</div>
                                    <textarea 
                                        name="textarea" 
                                        rows="5" 
                                        cols="100" 
                                        placeholder={data.instrucoes != null ? data.instrucoes : ''} 
                                        disabled>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    ))
                }

                {/* <div className={styles.blueBackground}>
                    <div className={styles.content}>
                        <button className={styles.adicionar} onClick={enviar}>
                            VER RESULTADO
                        </button>
                    </div>
                </div> */}
                
            </div>

            <Modal 
                open={openModal} 
                size="md"
                >
                <div className="pageBody">
                    <div className="existeParceiroPage">

                    <div className="container-fluid row">
                        <div className='text-left'>
                            <h1><b>Resultado</b></h1>
                        </div>

                        {porcentagemAcertos >= 80 ? (
                            <div className={styles.subtitle}>
                                Parabéns! Você concluiu o treinamento. <br />
                                Confira os resultados.
                            </div>
                        ) : (
                            <div className={styles.subtitle}>
                                Você acertou {respostasCorretas} pergunta de {dataQuestionsAnsewered?.perguntas?.length} . Tente novamente para garantir seu certificado.
                            </div>
                        )}
                    </div>

                        { dataQuestionsAnsewered?.perguntas?.length > 0 &&
                            dataQuestionsAnsewered?.perguntas?.map((p, pos) => (
                                <div className={`${styles.divCardAnsewer} ${p.respostas.find(x => x.selecionada && x.correta) ? styles.correct : ""}`}>
                                    <div className={styles.divTitle}>
                                        {`Pergunta ${pos + 1} de ${dataQuestionsAnsewered?.perguntas?.length}`}
                                    </div>
                                    <div className={styles.divAnsewer}>
                                        
                                        <div>{p.respostas?.find(x => x.selecionada)?.texto}</div>
                                        <div className={styles.icons}><div>{ p.respostas.find(x => x.selecionada && x.correta) ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/> }</div></div>
                                    </div>
                                    {/* { p.respostas.find(x => !x.selecionada && x.correta) &&
                                        <div className={styles.divAnsewerCorrect}>
                                            <div className={styles.divAnsewerCorrectTitle}>
                                                <small>Resposta correta:</small>
                                            </div>
                                            <div className={styles.divAnsewerCorrectText}>
                                                {p.respostas?.find(x => x.correta)?.texto}
                                            </div>
                                        </div>
                                    } */}
                                </div>
                            ))
                        }

                        <div className="container-fluid row">
                            <div className={styles.subtitle}>
                            <br/>Caso você tenha respondido corretamente<br/>todas as questões, você poderá visualizar<br/>seu certificado na tela de treinamento.
                            </div>
                        </div>

                        <div className="container padB20 button" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                            <button className={styles.salvar} onClick={() => { history.push(`/treinamento`)}}>
                                Fechar Treinamento
                            </button>
                        </div>

                    </div>
                </div>
            </Modal>

            <ModalLoading
                open={modalLoading} 
                size="lg"
            />

        </>
    );
}

export default TreinamentoDetalhe;