import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { buscarDataAceiteRegulamento } from '../../services/api';
import { useDispatch } from 'react-redux';
import { useGetUser } from '../../hooks/useGetUser';

const dataInit = {
  linkPdf: '',
  dataAceite: null,
};

const Regulamento = () => {
  const user = useGetUser();
  const dispatch = useDispatch();
  const [linkArquivo, setLinkArquivo] = useState('');
  const [data, setData] = useState(dataInit);

  useEffect(() => {
    if (user.userId !== undefined) {
      getAtivo(user.userId);
    }
  }, [user]);

  const getAtivo = async (userId) => {
    const reg = await buscarDataAceiteRegulamento(userId);
    setLinkArquivo(reg.data.linkPdf);
    setData(reg.data);
  };

  return (

    <div className={styles.wrapper}>
      <div className={styles.title}>Regulamento</div>
      <div style={{display:'flex'}}>

      <div className={styles.subTitle}>Regulamento atual</div>
      <div style={{margin:'10px 0 0 40px'}}>
      <div>Regulamento aceito em:</div>
      {data.dataAceite != null && (
        <div className={styles.textBlue}>{data.dataAceite}</div>
        )}
      {data.dataAceite == null && (
        <div className={styles.textRed}>Ainda não há aceite.</div>
        )}
        </div>
        </div>

      {linkArquivo && <embed src={linkArquivo} width="100%"></embed>}
   
    </div>
  );
};

export default Regulamento;
