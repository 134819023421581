import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForward from '@material-ui/icons/ArrowForward';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import './styles.scss';

const MenuLink = ({ label, link, closeMenuMobile, subList, id }) => {
  return (
    <li onClick={closeMenuMobile}>
      <a href={link} id={id}>
        {label}
        {id ? (
          <KeyboardArrowDownIcon htmlColor="#393A4B" className="menuArrow" />
        ) : (
          <ArrowForward htmlColor="#5CCBE1" className="menuArrow" />
        )}
      </a>
      {subList}
    </li>
  );
};

export default MenuLink;
