import React from 'react';

import './styles.scss';

import LogoFooterImg from '../../assets/img/footer.png';
import Email from '../../assets/img/email.png';

const FooterParticipant = () => {
  return (
    <footer className="footerParticipantMain">
        <div className="container">
            <div className="footerWrapper">
                <div className="footerTextWrapper">
                <img src={Email} alt="Email" className="" style={{width:"20px"}} /> <span><strong>Dúvidas?</strong> Entre em contato através do e-mail: <a href='mailto:bvrelacionar@ltmfidelidade.com.br' target='_blank'>bvrelacionar@ltmfidelidade.com.br</a></span>
                </div>
                <div className="footerImgWrapper">
                    <img src={LogoFooterImg} alt="BV" className="footerLogo" />
                </div>
            </div>
      </div>
    </footer>
  );
}

export default FooterParticipant;