import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useDispatch } from "react-redux"
import {
  showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  showMsgS as showMsgSAction,
} from '../../../store/actions/snackbarActions.js';
import moment from "moment";
import services from '../../../services';
import { useGetUser } from '../../../hooks/useGetUser';
import { trackPromise } from 'react-promise-tracker';
import MeusDados from '../MeuCadastro/MeusDados';
import { useParams, useHistory } from 'react-router-dom';
import Voltar from '../../../components/VoltarBtn/voltar';
import ModalLoading from '../../../components/ModalLoading';

const formCadastroInitialState = {
  userId: "",
  userName: "",
  dataNascimento: null,
  email: "",
  nome: "",
  phoneNumber: "",
  roleId: "",
  cnpj: "",
  nomeFantasia: "",
  razaoSocial: "",
  cep1: "",
  logradouro1: "",
  numero1: "",
  complemento1: "",
  bairro1: "",
  uf1: "",
  cidade1: null,
  mesmoEnderecoEntrega: false,
  cep2: "",
  logradouro2: "",
  numero2: "",
  complemento2: "",
  bairro2: "",
  uf2: "",
  cidade2: null,
  senha: "",
  senhaConfirmar: "",
  ativo: "",
  perfilAcesso: "",
  perfilAcessoId: "",
}

const DadosParticipante = () => {
  const user = useGetUser();
  const history = useHistory();
  const { idParticipante } = useParams();
  const { idParceiro } = useParams();
  const dispatch = useDispatch();
  const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  const showMsgS = (msg) => dispatch(showMsgSAction(msg));
  const [loading, setLoading] = useState(false)
  const [formCadastro, setFormCadastro] = useState({ ...formCadastroInitialState });
  const [indicarErrosCadastro, setIndicarErrosCadastro] = useState(false);
  const [permiteAdicionarParticipante, setPermiteAdicionarParticipante] = useState(services.storage.getClaimValues("Participantes", "Cadastrar"));
  const [permiteEditarParticipante, setPermiteEditarParticipante] = useState(services.storage.getClaimValues("Participantes", "Alterar"));

  const handleChangeForm = (value, campo) => {
    setFormCadastro(prevstate => ({ ...prevstate, [campo]: value }))
  }

  const editar = async () => {

    let objEnvio = {
      parceiroId: formCadastro.parceiroId,
      nomeCompleto: formCadastro.nome,
      cpf: services.mask.unMask(formCadastro.userName),
      dataNascimento: formCadastro.dataNascimento,
      email: formCadastro.email,
      phoneNumber: services.mask.unMask(formCadastro.phoneNumber),
      cep: services.mask.unMask(formCadastro.cep1),
      cidade1: {
        cidadeId: formCadastro.cidade1,
      },
      bairro: formCadastro.bairro1,
      logradouro: formCadastro.logradouro1,
      numero: formCadastro.numero1,
      complemento: formCadastro.complemento1,
      usarEnderecoEntrega: formCadastro.mesmoEnderecoEntrega,
    }

    if (!formCadastro.mesmoEnderecoEntrega) {
      objEnvio.cep2 = services.mask.unMask(formCadastro.cep2);
      objEnvio.cidade2 = {
        cidadeId: formCadastro.cidade2
      };
      objEnvio.bairro2 = formCadastro.bairro2;
      objEnvio.logradouro2 = formCadastro.logradouro2;
      objEnvio.numero2 = formCadastro.numero2;
      objEnvio.complemento2 = formCadastro.complemento2;
    }
    if (formCadastro.userId) {
      objEnvio.userId = formCadastro.userId;
    }

    if (formCadastro.senha) {
      objEnvio.senha = formCadastro.senha;
      objEnvio.confirmaSenha = formCadastro.senhaConfirmar;
    }

    if (idParceiro !== "null") {
      objEnvio.parceiroId = idParceiro;
    }

    await services.api.perfisParticipantes()
      .then(res => {
        if (!res.fail) {
          var cargo = res.data.filter(perfil => perfil.roleId == formCadastro.roleId);
          objEnvio.cargo = cargo[0].displayName;
        } else {
          showMsgE(res.errors ? res.errors.message : 'Erro')
        }
      }).catch(error => {
        showMsgE(error.errors.length ? error.errors[0].message : 'Error')
      })

    try {
      setLoading(true)
      await services.api.postParticipantes(objEnvio)
      showMsgS("Dados salvos com sucesso")
      setTimeout(() => {
        history.goBack()
      }, 2000)
    } catch (e) {
      showMsgE(e.response.data)
    } finally {
      setLoading(false)
    }
  }

  const salvar = () => {
    if (validarForm()) {
      setIndicarErrosCadastro(false)
      editar()
    }
    else{
      setIndicarErrosCadastro(true)
    }
  }

  const validarForm = () => {
    let valid = true
    let msg = ''
    if (
      !formCadastro.nome ||
      !formCadastro.phoneNumber ||
      !formCadastro.roleId ||
      !formCadastro.email ||
      !formCadastro.dataNascimento ||
      !formCadastro.userName
    ) { msg = 'Informe os campos obrigatórios'; valid = false; }
    if (!services.validate.valideCellphone(services.mask.unMask(formCadastro.phoneNumber))) { msg = 'Informe um número de celular válido'; valid = false; }
    if (!services.validate.valideMail(formCadastro.email)) { msg = 'Informe um E-mail válido'; valid = false; }
    if (!new moment(formCadastro.dataNascimento).isValid() && formCadastro.dataNascimento) { msg = 'Informe uma Data de Nascimento válida'; valid = false; }
    if (!services.validate.valideCpf(services.mask.unMask(formCadastro.userName))) { msg = 'Informe um CPF válido'; valid = false; }

    if (formCadastro.senha) {
      if (!services.validate.validePasswordSize(formCadastro.senha)) { msg = 'Senha deve ter mínimo 8 caracteres'; valid = false; }
      if (!services.validate.validePasswordNumber(formCadastro.senha)) { msg = 'Senha deve ter ao menos 1 número'; valid = false; }
      if (!services.validate.validePasswordCapital(formCadastro.senha)) { msg = 'Senha deve ter letras maiúsculas e minúsculas'; valid = false; }
      if (!services.validate.validePasswordSpecial(formCadastro.senha)) { msg = 'Senha deve ter caracteres especiais'; valid = false; }
      if (formCadastro.senha !== formCadastro.senhaConfirmar) { msg = 'Senha e Confirme sua senha devem ser iguais'; valid = false; }
    }

    if (msg) {
      showMsgW(msg)
    } else {
      if (!valid) showMsgW('Informe todos os campos obrigatórios')
    }
    return valid
  }

  const loadCadastro = (idParticipante) => {
    trackPromise(
      services.api.getParceiroById(idParticipante)
        .then(res => {
          if (res.data) {
            let cadastro = res.data;
            cadastro.roleId = res.data.role?.roleId;
            cadastro.senha = '';
            cadastro.senhaConfirmar = '';
            if (res.data.uf1) { cadastro.uf1 = res.data.uf1?.ufId }
            if (res.data.cidade1) { cadastro.cidade1 = res.data.cidade1?.cidadeId }
            if (res.data.uf2) { cadastro.uf2 = res.data.uf2?.ufId }
            if (res.data.cidade2) { cadastro.cidade2 = res.data.cidade2?.cidadeId }
            setFormCadastro(cadastro)
          } else {
            showMsgE(res.errors ? res.errors.message : 'Erro')
          }
        }).catch(error => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error')
        })
    )
  }

  useEffect(() => {
    if (idParticipante) {
      loadCadastro(idParticipante)
    }
    else if (idParceiro) {
      setFormCadastro({ ...formCadastro, parceiroId: idParceiro })
    }
    else {
      history.goBack();
    }
  }, [])

  return (
    <div className='MeuCadastro'>
      <div className='container'>
        <div className='titlePage'>
          Dados do participante
          <Voltar link={`/cadastro-loja`} />
        </div>
      </div>
      <MeusDados
        formPage={formCadastro}
        indicarErros={indicarErrosCadastro}
        action={salvar}
        changes={handleChangeForm}
        onlyRead={!permiteAdicionarParticipante && !permiteEditarParticipante}
      />
      <ModalLoading open={loading} />
    </div>
  );

}

export default DadosParticipante;