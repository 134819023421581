export const valideMail = (email) => {
    if (email.length < 5) {
        return false
    }
    if (!email.includes("@")) {
        return false
    }
    return true
}

export const validePhone = (phone) => {
    if (phone.length < 10 || phone.length > 11) {
        return false
    }
    return true
}

export const valideCellphone = (phone) => {
    if (phone.length !== 11) {
        return false
    }
    return true
}

export const validePasswordSize = (password) => {
    if (password.length < 8) {
        return false
    }
    return true
}

export const validePasswordNumber = (password) => {
    if (!(/\d/.test(password))) {
        return false
    }
    return true
}

export const validePasswordCapital = (password) => {
    if (!(/[a-z]/.test(password) && /[A-Z]/.test(password))) {
        return false
    }
    return true
}

export const validePasswordSpecial = (password) => {
    if (!(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password))) {
        return false
    }
    return true
}

export const valideCpf = (strCPF) => {
    let Soma;
    let Resto;
    Soma = 0;
    strCPF = strCPF.replace(/\./g, "")
        .replace(/\-/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/\//g, "")
        .replace(/\s/g, "")
    if (strCPF === "00000000000") return false;
    if (strCPF === "11111111111") return false;
    if (strCPF === "22222222222") return false;
    if (strCPF === "33333333333") return false;
    if (strCPF === "44444444444") return false;
    if (strCPF === "55555555555") return false;
    if (strCPF === "66666666666") return false;
    if (strCPF === "77777777777") return false;
    if (strCPF === "88888888888") return false;
    if (strCPF === "99999999999") return false;

    if (strCPF.length !== 11) return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

export const valideCnpj = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;
}

export const validateCep = (value) => {
    return /^[\d]{5}-[\d]{3}/.test(value)
}

export const validateForm = (id, obj) => {

    let errorFields = [];
    let msgType = 0;
    let hasError = true;
    let message = "";
    let objRetorno = { "valid": false, "msg": "" }

    if (id === 'vendaStepOne') {
        if (obj.marcaId === '' || obj.marcaId === undefined) {
            hasError = false;
            errorFields.push('Marca');
        };

        if (obj.modeloId === '' || obj.modeloId === undefined) {
            hasError = false;
            errorFields.push('Modelo');
        }

        if (obj.versaoId === '' || obj.versaoId === undefined) {
            hasError = false;
            errorFields.push('Versão');
        }

        if (obj.quilometragem === '' || obj.quilometragem === undefined) {
            hasError = false;
            errorFields.push('quilometragem');
        }

        if (obj.cidadeId === '' || obj.cidadeId === undefined) {
            hasError = false;
            errorFields.push('cidade');
        }

        // if (obj.email === '' || obj.email === undefined) {
        //     hasError = false;
        //     errorFields.push('Email');
        // } else if (!_valideMail(obj.email)) {
        //     hasError = false;
        //     objRetorno.valid = hasError
        //     objRetorno.msg = "Informe um Email Válido"
        //     return objRetorno
        // };

        // if (obj.celular === '' || obj.celular === undefined) {
        //     hasError = false;
        //     errorFields.push('celular');
        // } else if (!_validePhone(obj.celular)) {
        //     hasError = false;
        //     objRetorno.valid = hasError
        //     objRetorno.msg = "Informe um Celular Válido"
        //     return objRetorno
        // }

    };

    if (id === 'vendaStepTwo') {
        if (obj.nome === '' || obj.nome === undefined) {
            hasError = false;
            errorFields.push('Nome');
        };

        if (obj.email === '' || obj.email === undefined) {
            hasError = false;
            errorFields.push('Email');
        } else if (!valideMail(obj.email)) {
            hasError = false;
            objRetorno.valid = hasError
            objRetorno.msg = "Informe um Email Válido"
            return objRetorno
        };

    }

    if (errorFields.length === 1 && msgType === 0) {
        message = "O campo " + errorFields[0] + " é obrigatório."
    }

    if (errorFields.length === 2 && msgType === 0) {
        message = "Os campos " + errorFields[0] + " e " + errorFields[1] + " são obrigatórios."
    }

    if (errorFields.length > 2 && msgType === 0) {
        let concatString = "";
        for (let n = 0; n < errorFields.length - 1; n++) { concatString += errorFields[n] + ", " }
        concatString = concatString.slice(0, concatString.lastIndexOf(","));
        concatString += " e " + errorFields[errorFields.length - 1];
        message = "Os campos " + concatString + " são obrigatórios."
    }

    objRetorno.valid = hasError
    objRetorno.msg = message
    return objRetorno

}

export const validadeNome = (strNOME) => {
    let proibido = ["AUTOMOVEL", "AUTOMÓVEL", "AUTOMOVEIS", "AUTOMÓVEIS",
        "CAR", "CARS", "VEÍCULO", "VEÍCULOS", "VEICULO", "VEICULOS",
        "CENTER", "AUTOS", "AUTO", "MULTIMARCAS", "MULTIMARCA",
        "LOJA", "LOJAS", "LTDA", "ME", "EPP", "COMÉRCIO", "COMERCIO", "COMÉRCIOS", "COMERCIOS",
        "CARROS", "CARRO", "EIRELLI", "MOTOR", "MOTORS", "SERVICO", "SERVIÇO", "SERVIÇOS", "SOLUÇÃO",
        "SOLUCAO", "SOLUÇÕES", "SOLUCOES", "GARAGEM", "GARAGE", "TECNOLOGIA", "LOCACAO",
        "LOCAÇÃO", "NOVO", "NOVOS", "SEMINOVO", "SEMINOVOS", "MOTO", "MOTOS", "MOTOCICLETAS", "MOTOCICLETA",
        "DISTRIBUIDOR", "DISTRIBUIDORA", "DISTRIBUIDORES", "DISTRIBUIDORAS", "AUTOMARCA", "AUTOMARCAS", "PNEU",
        "PNEUS", "ESTACIONAMENTO", "ESTACIONAMENTOS", "AUDITORIA", "AUDITORIAS", "CONSULTORIA", "CONSULTORIAS",
        "REPRESENTANTE", "REPRESENTANTES", "CAMINHÃO", "CAMINHAO", "CAMINHÕES", "CAMINHOES", "AUTOMOTIVA",
        "AUTOMOTIVAS", "AUTOMOTIVO", "AUTOMOTIVOS", "VAN", "VANS", "ONIBUS", "BUS", "ÔNIBUS", "MICRO", "MICROS",
        "IMPORTS", "IMPORTAÇÃO", "IMPORTACAO", "CIA", "COMPANHIA", "COMPANHIAS", "FINANCEIRA", "FINANCEIRO",
        "FINANCEIRAS", "FINANCEIROS", "FINANCIADORA", "FINANCIADOR", "FINANCIADORES", "BOX"];

    var itens = strNOME.toUpperCase().trim().split(' ');

    if (itens.length < 2)
        return { somenteUmNome: true };

    if (itens.length > 0) {
        for (var x = 0; x < itens.length; x++) {
            if (itens[x] === itens[x + 1])
                return { somenteUmNome: true };

            if (proibido.indexOf(itens[x].toUpperCase()) !== -1)
                return { palavraReservada: itens[x].toUpperCase() };

            const regexnum = /[0-9]/;

            if (regexnum.test(itens[x]))
                return { numerosOuEspeciais: true };

            const regexcesp = /[!""#$%&'()*+,-./:;?@[\\\]_`{|}~]/;

            if (regexcesp.test(itens[x]))
                return { numerosOuEspeciais: true };

            if (itens[x].indexOf("*") !== -1)
                return { numerosOuEspeciais: true };
        }
    }

    return {};
}