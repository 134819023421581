import React, { useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';

import services from '../../../services';
import { useDispatch } from "react-redux"
import {
  //showMsgW as showMsgWAction,
  showMsgE as showMsgEAction,
  ///showMsgS as showMsgSAction,
} from '../../../store/actions/snackbarActions.js';

import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import {
  alpha,
  ThemeProvider,
  withStyles,
  makeStyles,
  createTheme,
} from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br"

import './styles.scss';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";

const FormCompletarCadastroParceiro = ({ formPage, action, search, changes, indicarErros, openRegulamento, permiteEditarCargo }) => {

  const dispatch = useDispatch();
  //const showMsgW = (msg) => dispatch(showMsgWAction(msg));
  const showMsgE = (msg) => dispatch(showMsgEAction(msg));
  //const showMsgS = (msg) => dispatch(showMsgSAction(msg));

  const [perfis, setPerfis] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [checkedTermos, setCheckedTermos] = React.useState(false);
  const [cargo, setCargo] = React.useState([]);
  const [passwordStrength, setPasswordStrength] = React.useState(0)

  const completarCadastroParceiroNaBase = () => {
    action()
  }

  const abrirRegumaneto = () => {
    openRegulamento()
  }


  const passwordSizeValidation = services.validate.validePasswordSize(formPage.senha);
  const passwordNumberValidation = services.validate.validePasswordNumber(formPage.senha);
  const passwordCapitalValidation = services.validate.validePasswordCapital(formPage.senha);
  const passwordSpecialValidation = services.validate.validePasswordSpecial(formPage.senha);

  React.useEffect(() => {

    let strength = 0;

    if (passwordSizeValidation) { console.log('size', formPage.senha.length); strength++; }
    if (passwordNumberValidation) { strength++; }
    if (passwordCapitalValidation) { strength++; }
    if (passwordSpecialValidation) { strength++; }

    setPasswordStrength(strength);

  }, [formPage.senha])

  React.useEffect(() => {
    search();
  }, [formPage.cpf])

  const handleAceiteRegulamento = event => {
    setChecked(event.target.checked);
    formPage.aceiteRegulamento = event.target.checked;
  };

  const handleAceiteTermosUso = event => {
    setCheckedTermos(event.target.checked);
    formPage.aceiteTermos = event.target.checked;
  }

  const handleSelecionarCargo = event => {
    setCargo(event.target.value);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#5CCBE1',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#5CCBE1',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          backgroundColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#5CCBE1',
        },
      },
    },
  })(TextField);

  useEffect(() => {
    trackPromise(
      services.api.perfisParticipantes()
        .then(res => {
          if (!res.fail) {

            var perfis = res.data.filter(perfil => perfil.displayName == "Vendedor(a)"
              || perfil.displayName == "Proprietário(a)"
              || perfil.displayName == "F&I");

            setPerfis(perfis)


          } else {
            showMsgE(res.errors ? res.errors.message : 'Erro')
          }
        }).catch(error => {
          showMsgE(error.errors.length ? error.errors[0].message : 'Error')
        })
    )
  }, []);

  return (
    <div className="formCompletarCadastroParceiro">
      <div className="container-fluid completarCadastroParceiroPage">

        <div className="row">
          <div className='col text-left'>
            <h1><b>Preencha os campos para se cadastrar.</b></h1>
          </div>
        </div>

        <div className="row">
          <div className='col-sm-12'>
            <FormControl>
              <TextField label="Nome completo" type="text" size="small"
                onChange={e => changes(e.target.value, 'nomeCompleto')}
                value={formPage.nomeCompleto}
                helperText={indicarErros && !formPage.nomeCompleto && 'Campo obrigatório'}
                error={indicarErros && !formPage.nomeCompleto ? true : false}
                variant="filled"
              />
            </FormControl>
          </div>
          <div className='col-sm-12'>
            <FormControl  >
              <TextField label="CPF" type="text" size="small"
                onChange={e => changes(e.target.value, 'cpf')}
                value={services.mask.cpf(formPage.cpf)}
                helperText={indicarErros && !formPage.cpf && 'Campo obrigatório'}
                error={indicarErros && !formPage.cpf ? true : false}
                variant="filled"
              />
            </FormControl>
          </div>
          <div className='col-sm-12'>
            <FormControl  >
              <MuiPickersUtilsProvider locale="pt-br" libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Data de Nascimento"
                  format="DD/MM/yyyy"
                  value={formPage.dataNascimento}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={novaData => { changes(novaData, 'dataNascimento') }}
                  className="customFilledInput"
                  helperText={indicarErros && !formPage.dataNascimento && 'Campo obrigatório'}
                  error={indicarErros && !formPage.dataNascimento ? true : false}
                  invalidDateMessage="Data em formato inválido."
                  maxDateMessage="Data não deve ser maior que a data máxima."
                  minDateMessage="Data não deve ser menor que a data mínima."
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </div>
          <div className='col-sm-12'>
            <FormControl  >
              <TextField label="E-mail" type="text" size="small"
                onChange={e => changes(e.target.value, 'email')}
                value={formPage.email}
                helperText={indicarErros && !formPage.email && 'Campo obrigatório'}
                error={indicarErros && !formPage.email ? true : false}
                variant="filled"
              />
            </FormControl>
          </div>
          <div className='col-sm-12'>
            <FormControl>
              <TextField label="Telefone celular" type="text" size="small"
                onChange={e => changes(e.target.value, 'celular')}
                value={services.mask.celphone(formPage.celular)}
                helperText={indicarErros && !formPage.celular && 'Campo obrigatório'}
                error={indicarErros && !formPage.celular ? true : false}
                inputProps={{ maxLength: 15 }}
                variant="filled"
              />
            </FormControl>
          </div>
          <div className='col-sm-12'>
            <FormControl>
              <TextField label="Cargo" select size="small"
                onChange={e => changes(e.target.value, 'cargo')}
                value={formPage.cargo}
                helperText={indicarErros && !formPage.roleId && 'Campo obrigatório'}
                error={indicarErros && !formPage.cargo ? true : false}
                variant="filled"
                disabled={!permiteEditarCargo}
              >
                {perfis.map((option) => (
                  <MenuItem key={option.roleId} value={option.displayName}>
                    {option.displayName}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
          <div className='col-sm-12'>
            <FormControl  >
              <TextField label="Senha" type="password" size="small"
                onChange={e => changes(e.target.value, 'senha')}
                value={formPage.senha}
                helperText={indicarErros && !formPage.senha && 'Campo obrigatório'}
                error={indicarErros && !formPage.senha ? true : false}
                variant="filled"
              />
            </FormControl>
          </div>
          <div className='col-sm-12'>
            <p><b>Força da sua senha</b></p>
            <div className="passwordOutterWrapper">
              <div className="passwordWrapper">
                <div className={passwordStrength > 0 ? "passwordBlock passwordActive" : "passwordBlock"} />
              </div>
              <div className="passwordWrapper">
                <div className={passwordStrength > 1 ? "passwordBlock passwordActive" : "passwordBlock"} />
              </div>
              <div className="passwordWrapper">
                <div className={passwordStrength > 2 ? "passwordBlock passwordActive" : "passwordBlock"} />
              </div>
              <div className="passwordWrapper">
                <div className={passwordStrength > 3 ? "passwordBlock passwordActive" : "passwordBlock"} />
              </div>
            </div>
            <ul className='lista-senha'>
              <li className={passwordSizeValidation && "active"}>
                Conter no mínimo 8 caracteres
              </li>
              <li className={passwordNumberValidation && "active"}>
                Conter números
              </li>
              <li className={passwordCapitalValidation && "active"}>
                Conter letras maiúsculas e minúsculas
              </li>
              <li className={passwordSpecialValidation && "active"}>
                Conter caracteres especiais
              </li>
            </ul>
          </div>
          <div className='col-sm-12'>
            <FormControl>
              <TextField label="Confirme sua senha" type="password" size="small"
                onChange={e => changes(e.target.value, 'confirmarSenha')}
                value={formPage.confirmarSenha}
                helperText={indicarErros && !formPage.confirmarSenha && 'Campo obrigatório'}
                error={indicarErros && !formPage.confirmarSenha ? true : false}
                variant="filled"
              />
            </FormControl>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-12 acceptTermsWrapper">
            <Checkbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<RadioButtonChecked />}
              checked={checkedTermos}
              onChange={handleAceiteTermosUso}
              name="checkedPermission"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              error={indicarErros && !formPage.aceiteTermos ? true : false}
            />
            <label className="acceptTerms">Li e aceito o <a className="linkRegulamento" onClick={abrirRegumaneto}><b><u>regulamento</u></b></a>&nbsp;do programa</label>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className='padB20'>
            <button className="btn btnFinalizarCadastro btn-lg btn-block" onClick={completarCadastroParceiroNaBase}>Finalizar cadastro</button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default FormCompletarCadastroParceiro;