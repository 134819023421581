import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import "moment/locale/pt-br"

import styles from './styles.module.scss';
import { getCampanhaWithId } from '../../../services/api';

const campanhaInit = {
    nome: '',
    dataInicio: moment(),
    dataFim: moment(),
    descricao: '',
    comoFunciona: ''
}

const Detalhes = props => {

    const history = useHistory();
    const [campanha, setCampanha] = useState(campanhaInit)
    const [loading, setLoading] = useState(false)

    const carregarCampanha = async() => {
        const campanhaId = new URLSearchParams(history.location.search).get("campanha")
        setLoading(true)
        const info = await getCampanhaWithId(campanhaId);
        setLoading(false)
        setCampanha({
            nome: info.nome,
            dataInicio: moment(info.periodoInicial),
            dataFim: moment(info.periodoFinal),
            descricao: info.descricao,
            comoFunciona: `data:${info.imagemComoFunciona.contentType};base64,${info.imagemComoFunciona.fileContents}`,
            mensagemPersonalizada: info.mensagemPersonalizada
        })
    }
    
    useEffect(() => {
        carregarCampanha()
    },[])
    

    return (
        <div className={`${styles.wrapper} ${styles.blueBackground}`}>
            <div className={styles.title}>
                Campanha
                <button className={styles.backButton} onClick={() => {history.goBack()}}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            </div>
            { loading && (<div className={styles.paginacaoWrapper}>
                    <FontAwesomeIcon icon={faSpinner} className={styles.icon} spin={true}/>
                </div>)}
            { (!loading) && (
                <div className={styles.content}>
                    <div className={styles.card}>
                        <div className={styles.subtitle}>
                            {campanha.nome}
                        </div>
                        <div className={styles.periodo}>
                            De <span className={styles.data}>{campanha.dataInicio && (`${campanha.dataInicio.format('DD/MM/YYYY')} `)} </span>
                            até <span className={styles.data}>{campanha.dataFim && (campanha.dataFim.format('DD/MM/YYYY'))}</span>
                        </div>
                        <div className={styles.descricao}>
                            {campanha.descricao}
                        </div>
                        <div className={styles.descricao}>
                            {campanha.mensagemPersonalizada}
                        </div>
                        <div className={styles.comoFunciona}>
                            Como funciona?
                        </div>
                        <div className={styles.imageWrapper}>
                            { campanha.comoFunciona && (<img src={campanha.comoFunciona} className={styles.image}/>)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
  
}

export default Detalhes;