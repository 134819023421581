export const iframeDone = () => {
  if (window.location.hostname === 'localhost') {
    return true;
  } else {
    const ifr = document.getElementById('iframeOrigin');
    return ifr.contentWindow ? true : false;
  }
};

export const set = (key, value) => {
  const ifr = document.getElementById('iframeOrigin');
  if (window.location.hostname === 'localhost') {
    if (ifr) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  } else {
    if (ifr) {
      localStorage.setItem(key, JSON.stringify(value));
      ifr.contentWindow.localStorage.setItem(key, JSON.stringify(value));
    }
  }
  return get(key);
};

export const get = (key) => {
  const ifr = document.getElementById('iframeOrigin');
  if (window.location.hostname === 'localhost') {
    let retorno = JSON.parse(localStorage.getItem(key));
    return retorno;
  } else {
    let retorno = localStorage.getItem(key);
    if (retorno === null || retorno === 'null') {
      retorno = ifr?.contentWindow?.localStorage.getItem(key);
      if (retorno) {
        localStorage.setItem(key, retorno);
        return JSON.parse(retorno);
      } else {
        return;
      }
    }
    return JSON.parse(retorno);
  }
};

export const setProduct = (product) => {
  if (window.location.hostname === 'localhost') {
    localStorage.setItem('product', product);
  } else {
    const ifr = document.getElementById('iframeOrigin');
    if (ifr) {
      ifr.contentWindow.localStorage.setItem('product', product);
      localStorage.setItem('product', product);
    }
  }
};
export const checkProduct = (product) => {
  if (window.location.hostname === 'localhost') {
    return localStorage.getItem('product') === product;
  } else {
    let prod = localStorage.getItem('product');
    if (prod === null || prod === 'null') {
      const ifr = document.getElementById('iframeOrigin');
      prod = ifr?.contentWindow?.localStorage.getItem('product');
      if (prod) {
        localStorage.setItem('product', prod);
      }
      return prod === product;
    }

    return prod === product;
  }
};

export const getUser = () => {
  const ifr = document.getElementById('iframeOrigin');
  let user;
  if (window.location.hostname === 'localhost') {
    user = localStorage.getItem('authSistema');
  } else {
    user = localStorage.getItem('authSistema');
    if (!user || user === 'null') {
      user = ifr.contentWindow?.localStorage?.getItem('authSistema');
      localStorage.setItem('authSistema', user);
    }
  }

  if (!user || user === 'null') return undefined;

  user = JSON.parse(user);
  return user.profile;
};

export const getClaimValues = (claimName, value) => {
  const user = JSON.parse(localStorage.getItem('authSistema'));
  let possuiClaim = false;
  if (!user) return undefined;

  user.claims.forEach((item) => {
    if (item.type === claimName) {
      item.value.split(',').forEach((v) => {
        if (v.trim() === value.trim()) possuiClaim = true;
      });
    }
  });

  return possuiClaim;
};

export const getUserId = () => {
  const ifr = document.getElementById('iframeOrigin');
  let user;
  if (window.location.hostname === 'localhost') {
    user = JSON.parse(localStorage.getItem('authSistema'));
  } else {
    user = localStorage.getItem('authSistema');
    if (!localStorage.getItem('authSistema')) {
      user = ifr.contentWindow.localStorage.getItem('authSistema');
      localStorage.setItem('authSistema', user);
    }
    user = JSON.parse(user);
  }

  if (!user || user === 'null') return undefined;
  if (!user.profile) return undefined;
  if (!user.profile.role) return undefined;
  if (!user.profile.role.roleId) return undefined;
  return user.profile.role.roleId;
};
export const getUserRecrutadorId = () => {
  const ifr = document.getElementById('iframeOrigin');
  let user;
  if (window.location.hostname === 'localhost') {
    user = JSON.parse(localStorage.getItem('authSistema'));
  } else {
    user = localStorage.getItem('authSistema');
    if (!localStorage.getItem('authSistema')) {
      user = ifr.contentWindow.localStorage.getItem('authSistema');
      localStorage.setItem('authSistema', user);
    }
    user = JSON.parse(user);
  }

  if (!user || user === 'null') return undefined;
  if (!user.profile) return undefined;
  if (!user.profile.recrutador) return undefined;
  if (!user.profile.recrutador.recrutadorId) return undefined;
  return user.profile.recrutador.recrutadorId;
};

export const getUserName = () => {
  const ifr = document.getElementById('iframeOrigin');
  let user;
  if (window.location.hostname === 'localhost') {
    JSON.parse(localStorage.getItem('SYSTEM_USER'));
  } else {
    user = localStorage.getItem('SYSTEM_USER');
    if (!localStorage.getItem('SYSTEM_USER')) {
      user = ifr.contentWindow.localStorage.getItem('SYSTEM_USER');
      localStorage.setItem('SYSTEM_USER', user);
    }
    user = JSON.parse(user);
  }

  if (!user || user === 'null') return undefined;
  return user.userName;
};

export const clear = (key) => {
  localStorage.clear();
  //   if (window.location.hostname === 'localhost') {
  //   } else {
  //     localStorage.clear();
  //     const ifr = document.getElementById('iframeOrigin');
  //     ifr.contentWindow.localStorage.clear();
  //   }
};

export const isAdmin = async () => {
  const user = get('SYSTEM_USER');
  if (!user || user === 'null') return false;
  if (!user.role) return false;
  return user.role.includes('Admin');
};

export const SaveUfId = async (ufId) => {
  try {
    if (window.location.hostname === 'localhost') {
      localStorage.setItem('@modal/ufId', ufId);
    } else {
      const ifr = document.getElementById('iframeOrigin');
      ifr.contentWindow.localStorage.setItem('@modal/ufId', ufId);
      localStorage.setItem('@modal/ufId', ufId);
    }
    return null;
  } catch (error) {
    return error;
  }
};

export const SaveCityId = async (cityId) => {
  try {
    if (window.location.hostname === 'localhost') {
      localStorage.setItem('@modal/cityId', cityId);
    } else {
      const ifr = document.getElementById('iframeOrigin');
      ifr.contentWindow.localStorage.setItem('@modal/cityId', cityId);
      localStorage.setItem('@modal/cityId', cityId);
    }
    return null;
  } catch (error) {
    return error;
  }
};

export const SaveUfName = async (ufName) => {
  try {
    if (window.location.hostname === 'localhost') {
      localStorage.setItem('@modal/ufName', ufName);
    } else {
      const ifr = document.getElementById('iframeOrigin');
      ifr.contentWindow.localStorage.setItem('@modal/ufName', ufName);
      localStorage.setItem('@modal/ufName', ufName);
    }
    return null;
  } catch (error) {
    return error;
  }
};

export const SaveCityName = async (cityName) => {
  try {
    if (window.location.hostname === 'localhost') {
      localStorage.setItem('@modal/cityName', cityName);
    } else {
      localStorage.setItem('@modal/cityName', cityName);
      const ifr = document.getElementById('iframeOrigin');
      ifr.contentWindow.localStorage.setItem('@modal/cityName', cityName);
    }
    return null;
  } catch (error) {
    return error;
  }
};

export const getUfId = async () => {
  try {
    let data = localStorage.getItem('@modal/ufId');
    if (!data && window.location.hostname === 'localhost') {
      const ifr = document.getElementById('iframeOrigin');
      data = ifr.contentWindow.localStorage.getItem('@modal/ufId');
      localStorage.setItem('@modal/ufId', data);
    }

    return data;
  } catch (error) {
    return error;
  }
};

export const getCityId = () => {
  try {
    const data = localStorage.getItem('@modal/cityId');
    if (!data && window.location.hostname === 'localhost') {
      const ifr = document.getElementById('iframeOrigin');
      data = ifr.contentWindow.localStorage.getItem('@modal/cityId');
      localStorage.setItem('@modal/cityId', data);
    }
    return data;
  } catch (error) {
    return error;
  }
};

export const getUfName = (ufName) => {
  const ifr = document.getElementById('iframeOrigin');
  try {
    if (window.location.hostname === 'localhost') {
      localStorage.getItem('@modal/ufName', ufName);
    } else {
      ifr.contentWindow.localStorage.getItem('@modal/ufName', ufName);
    }
    return null;
  } catch (error) {
    return error;
  }
};

export const getCityName = (cityName) => {
  const ifr = document.getElementById('iframeOrigin');
  try {
    if (window.location.hostname === 'localhost') {
      localStorage.getItem('@modal/cityName', cityName);
    } else {
      ifr.contentWindow.localStorage.getItem('@modal/cityName', cityName);
    }
    return null;
  } catch (error) {
    return error;
  }
};

export const checkTimeLimit = () => {
  if (get('timeLimit') > Date.now()) {
    return true;
  } else {
    clear();
    return false;
  }
};
