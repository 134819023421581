import React from 'react'
import styles from './styles.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Loading = ({color="#000"}) => {
    return (
        <div className={styles.paginacaoWrapper} style={{color: color}}>
            <FontAwesomeIcon icon={faSpinner} className={styles.icon} spin={true}/>
        </div>
    )
}

export default Loading;