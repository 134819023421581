import React from 'react';

import './styles.scss';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const FormConfirmacaoCadastro = ({formPage, action, back, changes, indicarErros}) => {

  const existeParceiroNaBase = () => {
    action()
  }

  return (
    <div className="pageBody">
      <div className="container-fluid confirmarCadastro">

        <div className="row">
          <div className='col text-left'>
            <h1><b>Primeiro acesso</b></h1>
          </div>
        </div>

        <div className="row">
          <div className='col-sm-12'>
            <FormControl  >
              <TextField label="Informe o Código do Parceiro" type="text"  size="small"
                onChange={event => changes(event)}
                value={formPage.codigo}
                helperText={indicarErros && !formPage.codigo && 'Campo obrigatório'}
                error={indicarErros && !formPage.codigo ? true : false}
                inputProps={{ maxLength: 6 }} 
                variant="filled"
              />
            </FormControl>
          </div>
        </div>

        <div className="row">
          <div className='col-12 padB20'>
            <button className="btn btnContinuarIrPrimeiroCadastro btn-lg btn-block" onClick={existeParceiroNaBase}>Confirmar</button>
          </div>
        </div>
        <div className="row">
          <div className='col-12 padB20'>
            <button className="btn btnVoltarIrPrimeiroCadastro btn-lg btn-block" onClick={back}>Voltar</button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default FormConfirmacaoCadastro;