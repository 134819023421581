import React, {useState, useEffect}  from 'react';
import styles from './styles.module.scss'
import { useGetUser } from '../../hooks/useGetUser';
import { getFaqs } from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

const FAQ = () => {

    const [faqs, setFaqs] = useState([]);
    
  
    const getList = async () => {
        const info = await getFaqs();
        if(info.data){

            const dataFaqs = info.data.map(data => {
                return {... data, open: false};
              });

            setFaqs(dataFaqs);
        }
    }

    useEffect(() => {
        getList();
    },[])

    const toggleFAQ = (faqId) => {
        setFaqs(faqs.map((faq) => {
          if (faq.faqId === faqId) {
            faq.open = !faq.open
          } else {
            faq.open = false;
          }
    
          return faq;
        }))
    }

    return (
        <div className={`${styles.wrapper}`}>
            <div className={styles.title}>
                Dúvidas
            </div>
            <div className={styles.filterWrapper}>
                <div className={styles.card}>
                    <div className={styles.subTitle}>
                        Tire suas principais dúvidas sobre o BV relacionar aqui.
                    </div>
                    { faqs && faqs.length > 0 && 
                        faqs.map((f) => (
                            <div className={styles.divColapse}>
                                <div className={styles.divColapseTitle}>
                                    <div>
                                        {f.titulo}
                                    </div>
                                    <div>
                                        <button className={styles.button} onClick={() => toggleFAQ(f.faqId)}>
                                            {
                                                f.open ? <FontAwesomeIcon icon={faCaretUp}/> : <FontAwesomeIcon icon={faCaretDown}/>
                                            }
                                        </button>
                                    </div>
                                </div>
                                <div className={`${f.open ? styles.divColapseBodyShow: styles.divColapseBody}`}>
                                    <div dangerouslySetInnerHTML={{__html: f.texto.replaceAll('\n', '<br/>')}}></div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            
        </div>
    );
}

export default FAQ;