import React from 'react';
import './styles.scss';

import Menu from '../Menu';

const HeaderParticipant = () => {
    return (
        <header>
            <div className='topHeaderInfos'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 headerFlex">
                            <Menu />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderParticipant;