import React, { useEffect, useState } from 'react';
import services from '../../services';
import globals from '../../globals';

function AutoLogout() {
  const [tempo, setTempo] = useState(3600);

  useEffect(() => {
    const count1 = setInterval(() => {
      setTempo((prevTempo) => prevTempo - 1);
    }, 1000);
    return () => {
      clearInterval(count1);
    };
  }, []);

  const formatarTempo = (tempo) => {
    const horas = Math.floor(tempo / 3600);
    const minutos = Math.floor((tempo % 3600) / 60);
    const segundos = tempo % 60;
    return `${horas.toString().padStart(2, '0')}:${minutos
      .toString()
      .padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
  };

  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    // Adicione um event listener global para o evento "mousemove"
    document.addEventListener('mousemove', handleMouseMove);

    // Lembre-se de remover o event listener quando o componente for desmontado
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const count2 = setInterval(() => {
      sair();
    }, 3600000); // == 1 hora

    setTempo(3600);
    return () => {
      clearInterval(count2);
    };
  }, [position]);

  // MOSTRAR NA ABA DO NAVEGADOR
  // useEffect(() => {
  //   document.title = 'Portal BV            ' + formatarTempo(tempo);
  // }, [tempo]);

  useEffect(() => {}, []);
  const sair = () => {
    services.storage.clear();
    // history.push(`/`);
    window.location.href = globals.REACT_APP_AUTH_APP;
  };

  return null;
  // <div style={{ position: 'absolute', zIndex: 20000, fontSize: 50 }}>
  //   <p style={{ fontSize: 20, fontWeight: 700, background: `#fff` }}>
  //     Tempo: {tempo} segundos
  //   </p>
  // </div>
}

export default AutoLogout;
