import React from 'react';

import './styles.scss';

const Focus = ({image, type, name}) => {
  return (
    <div className="focus">
        <div className="image">
            <img src={image} />
        </div>
        <div className='name'>
            {name}
        </div>
        <div className='type'>
            {type}
        </div>
    </div>
  );
}

export default Focus;