import React, {useState, useEffect}  from 'react' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Select, MenuItem, InputLabel, FormControl, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import moment from "moment";

import ProgressBar from '../../components/ProgressBar';
import styles from './styles.module.scss'

import premiumItem from '../../assets/img/premiumItem.svg';
import goldItem from '../../assets/img/goldItem.svg';
import plusItem from '../../assets/img/plusItem.svg';
import brassItem from '../../assets/img/brassItem.png';
import noneItem from '../../assets/img/noneItem.png';
import { useGetUser } from '../../hooks/useGetUser';
import { getAcoesPorPeriodoPorCnpj, getDesempenhoPorCnpj, getCnpjParceirosUsuario } from '../../services/api'

const Detalhes = () => {

    const user = useGetUser()
    const [cnpjList, setCnpjList] = useState([])
    const [acoesList, setAcoesList] = useState([])
    const [mes, setMes] = useState(moment())
    const [cnpj, setcnpj] = useState("")
    const [acao, setAcao] = useState({})

    const [dados, setDados] = useState(null);

    const mensagem = dados?.proximaCategoria ? `Faltam <b>${ dados.pontosProxima - dados.pontos} pontos</b> para atingir a categoria ${dados.proximaCategoria}` : 
        `Parabéns, você está na última categoria`;

    const badges = {
        "Sem Classificação": noneItem,
        "Compensa": brassItem,
        "Compensa Plus": plusItem,
        "Compensa Gold": goldItem,
        "Compensa Premium": premiumItem
    }

    const max = {
        Share: 300,
        SPF: 150,
        AP: 100,
        AUTO: 100
    }

    const AutoTooltip = 'teste'
    const marketShareTooltip = ' teste 2'
    const PremistaTooltip = 'teste 3'
    const AcidentesTooltip = ' teste 4'

    const getAcoes = async () => {
        if(cnpj){
            const mesInfo = mes.month();
            const anoInfo = mes.year();
            let novaData = moment(new Date(("0" + anoInfo).slice(-4),("0" + mesInfo).slice(-2),"01")).format('YYYY-MM-DD')
            const info = await getAcoesPorPeriodoPorCnpj(novaData, cnpj)
            if(info){
                setAcoesList(info)
            }
        }
    }

    const getCnpjParceiros = async () => {
        const info = await getCnpjParceirosUsuario();
        if (info.length > 0)
            setCnpjList(info.map((e) => e.cnpj));
        else
            setCnpjList([user.cnpj]);
    }

    const getDetalhes = async () => {
        const mesInfo = mes.month();
        const anoInfo = mes.year();
        let novaData = moment(new Date(("0" + anoInfo).slice(-4),("0" + mesInfo).slice(-2),"01")).format('YYYY-MM-DD');
        const info = await getDesempenhoPorCnpj(novaData, cnpj, acao.idAcao)
        if(info){
            setDados(info)
        }
    }

    useEffect(() => {
        getAcoes();
        getCnpjParceiros();
    }, [cnpj, mes])

    useEffect(() => {
        if(user){
            setcnpj(user.cnpj)
        }
    },[user])


    return(
        <div className={`${styles.wrapper}`}>
            <div className={styles.title}>
                Desempenho
            </div>
            <div className={styles.filterWrapper}>
                <div className={styles.card}>
                    <FormControl className={styles.inputSpace}>
                        <InputLabel id="select-tipo-label" className={styles.label}>
                            CNPJ
                        </InputLabel>
                        <Select
                            labelId='select-tipo-label'
                            label="Ação"
                            onChange={(e)=>{setcnpj(e.target.value)}}
                            value={cnpj}
                            variant='filled'
                            className="customFilledInput"
                        >
                            { cnpjList.map( (item) => {
                                return (
                                    <MenuItem value={item} key={item}>{item}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl className={`${styles.data} ${styles.inputSpace}`}>
                        <MuiPickersUtilsProvider locale="pt-br" libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                views={['year', 'month']}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Mês"
                                format="MMMM/yyyy"
                                value={mes}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={novaData => { setMes(novaData) }}
                                className="customFilledInput"
                                error={false}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl className={styles.inputSpace}>
                        <InputLabel id="select-tipo-label" className={styles.label}>
                            Ação
                        </InputLabel>
                        <Select
                            labelId='select-tipo-label'
                            label="Ação"
                            onChange={(e)=>{setAcao(e.target.value)}}
                            value={acao}
                            variant='filled'
                            className="customFilledInput"
                        >
                            { acoesList?.map( (item) => {
                                return (
                                    <MenuItem value={item} key={item.idAcao}>{item.nomeAcao}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl className={styles.inputSpace}>
                        <Button className={`${styles.button}`} onClick={getDetalhes}>
                            Carregar
                        </Button>
                    </FormControl>
                </div>
            </div>
            {
            !Boolean(dados) ? (
                    <div></div>
                ) : (
                <>
                    <div className={`${styles.actions}`}>
                        <div className={`${styles.card}`}>
                            <div className={`${styles.subTitle}`}>
                                {dados.nomeParceiro}
                            </div>
                            <div className={styles.firstInfo}>
                                <div className={styles.infoWrapper}>
                                    <div className={styles.infoLabel}>
                                        Periodo:
                                    </div>
                                    <div className={styles.info}>
                                        {dados.periodo}
                                    </div>
                                </div>
                                <div className={styles.infoWrapper}>
                                    <div className={styles.infoLabel}>
                                        Campanha:
                                    </div>
                                    <div className={styles.info}>
                                        {dados.campanha}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.innerCard}>
                                <div className={styles.top}>
                                    <div className={styles.label}>
                                        Categoria atual:
                                    </div>
                                    <div className={styles.categoria}>
                                        <div> {dados?.tipoParceiro?.toUpperCase()} </div>
                                        <img src={badges[dados.classificacao]}/>
                                        <div> {dados.classificacao.toUpperCase()} </div>
                                    </div>
                                </div>
                                <div className={styles.bottom}>
                                    <div className={styles.pontos}>
                                        <div className={styles.label}>
                                            Saldo de pontos:
                                        </div>
                                        <div className={styles.pontos}>
                                            { dados.totalPontos }
                                        </div>
                                    </div>
                                    <div>
                                        <ProgressBar value={dados.totalPontos} maxValue={dados.totalPontos + dados.pontosFaltantes} />
                                    </div>
                                    <div className={styles.icons}>
                                        <img src={badges[dados.classificacao]}/>
                                        <img src={badges[dados.proximaClassificacao]}/>
                                    </div>
                                    <div className={styles.message} dangerouslySetInnerHTML={{ __html: mensagem }} />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className={`${styles.logs}`}>
                        <div className={`${styles.subTitle}`}>
                            Resultados no período
                        </div>
                        <div className={styles.secondCard}>
                            <div className={styles.line}>
                                <div className={styles.label}>
                                    Período
                                </div>
                                <div className={styles.info}>
                                    {dados.periodo}
                                </div>
                            </div>
                            <div className={`${styles.line} ${styles.middleLine}`}>
                                <div className={styles.label}>
                                    Pontos ganhos no período
                                </div>
                                <div className={styles.info}>
                                    {dados.totalPontos}
                                </div>
                            </div>
                            <div className={styles.line}>
                                <div className={styles.label}>
                                    Categoria
                                </div>
                                <div className={styles.info}>
                                    {dados.classificacao}
                                </div>
                            </div>
                        </div>
                        <div className={styles.thirdCard}>
                            <div className={styles.titleCard}>
                                Resultados no período
                            </div>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoLabel}>
                                    Market Share
                                    <FontAwesomeIcon title={marketShareTooltip} type='fas' icon={faInfoCircle} className={styles.tooltype}/>  
                                </div>
                                <div className={styles.bar}>
                                    <ProgressBar value={dados.marketShare} maxValue={max.Share}/>
                                </div>
                                <div className={styles.info}>
                                    {dados.marketShare}
                                </div>
                            </div>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoLabel}>
                                    Seguro Premista
                                    <FontAwesomeIcon title={PremistaTooltip} type='fas' icon={faInfoCircle} className={styles.tooltype}/>  
                                </div>
                                <div className={styles.bar}>
                                    <ProgressBar value={dados.pontosSPF} maxValue={max.SPF}/>
                                </div>
                                <div className={styles.info}>
                                    {dados.pontosSPF}
                                </div>
                            </div>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoLabel}>
                                    Acidentes pessoais
                                    <FontAwesomeIcon title={AcidentesTooltip} type='fas' icon={faInfoCircle} className={styles.tooltype}/>  
                                </div>
                                <div className={styles.bar}>
                                    <ProgressBar value={dados.pontosAP} maxValue={max.AP}/>
                                </div>
                                <div className={styles.info}>
                                    {dados.pontosAP}
                                </div>
                            </div>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoLabel}>
                                    Linha auto
                                    <FontAwesomeIcon title={AutoTooltip} type='fas' icon={faInfoCircle} className={styles.tooltype}/>  
                                </div>
                                <div className={styles.bar}>
                                    <ProgressBar value={dados.pontosLinhaAuto} maxValue={max.AUTO}/>
                                </div>
                                <div className={styles.info}>
                                    {dados.pontosLinhaAuto}
                                </div>
                            </div>
                            <div className={styles.titleCard}>
                                Qualidade de vendas
                            </div>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoLabel}>
                                    Inadimplência
                                </div>
                                <div className={styles.info}>
                                    {dados.inadiplencia}
                                </div>
                            </div>
                            <div className={styles.infoWrapper}>
                                <div className={styles.infoLabel}>
                                    Cancelamentos
                                </div>
                                <div className={styles.info}>
                                    {dados.cancelamentos}%
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Detalhes