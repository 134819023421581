import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { getQuizList } from '../../services/api';
import QuizCard from '../../components/QuizCard';
import homeMainImg from '../../assets/img/quiz.png';
import ModalLoading from '../../components/ModalLoading';

const Quiz = () => {

    const history = useHistory();
    const [quiz, setQuiz] = useState([])
    const [verMais, setVerMais] = useState(true)
    const [modalLoading, setModalLoading] = useState(false);
    const [filter, setFilter] = useState( {page : 1, pageSize: 10, query: "" } );
    

    const getQuiz = async() => {
        setModalLoading(true)
        const data = await getQuizList(filter);
        setModalLoading(false)
        setQuiz(data.data)
    } 
    useEffect(()=>{
        (quiz && quiz.items?.length > 0 && quiz.page === quiz.totalPages ) ? setVerMais(true) : setVerMais(false); 
        getQuiz()
    },[])

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Quiz
            </div>
            <div className={styles.mainBannerWrapper}>
                <img className={styles.mainBanner} src={homeMainImg}/>
            </div>
            <div className={styles.subTitle}>
                A cada participação, você ganha pontos enquanto aprende!
            </div>
            <div className={styles.acoesWrapper}>
                {quiz && quiz.quizzes?.map(
                    (q) => <QuizCard quiz={q}/>
                )}
            </div>
            {
                quiz.items?.length === 0 &&
                <div className={styles.verMaisWrapper}> Não existem dados para exibir </div>
            }
            <ModalLoading open={modalLoading}  size={"lg"} />
        </div>
    );
  
}

export default Quiz;