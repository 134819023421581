import React, { useEffect } from 'react';

import './styles.scss'

import logoCompany from '../../assets/img/companyLogo.png'
import logoTopoCompany from '../../assets/img/companyLogoTopo.png'

const FormLogin = ({formPage, action, changes, indicarErros, modalSenha, modalPrimeiroAcesso}) => {
    const cpfMask = value => {
        return value
          .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
          .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})/, '$1-$2')
          .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
      }
 
      if(formPage.login!= null)
      formPage.login = cpfMask(formPage.login);
    const logar = () => {
       
    
        action()
    }

    useEffect(() => {
        async function init() {
        }
        init()
    }, [])

    return (
        <div className="limitContainer">
            <div className="row">
                 
                <div className="col-sm-12">
                    <div className="containerLogoTopoImage">
                        <img src={logoTopoCompany} alt=" " />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 padB120">
                    <div className="containerLogoImage">
                        <img src={logoCompany} alt=" " />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 padB20">
                <div className="input-container">
                <form autoComplete='off'>
      <input id="cpf" className="input inputForm form-control form-control-lg"   onChange={event => changes(event.target.value, 'login')}
                          value={formPage.login}
                          name="username"  
                           type="text" pattern=".+" required />
      <label className="label" htmlFor="cpf">CPF</label>
      </form>
    </div>
                    {/* <input  className="form-control form-control-lg inputForm"  for="cpf" type="text" placeholder="Informe o CPF"/> */}
                    {/* <FormControl  >
                        <TextField label="CPF" type="text"  size="small"
                            onChange={event => changes(event, 'login')}
                            value={formPage.login}
                            helperText={indicarErros && !formPage.login && 'Campo obrigatório'}
                            error={indicarErros && !formPage.login ? true : false}
                        />
                    </FormControl> */}
                    {/* <label class="label" for="cpf">CPF</label> */}
    {/* </div> */}
                </div>
                <div className="col-sm-12 padB20">
                <div className="input-container">
                <form autoComplete='off'>
      <input  id="senha" className="input inputForm form-control form-control-lg"  onChange={event => changes(event.target.value, 'senha')}  
                          
                            inputprops={{ maxLength: 20 }}  type="password" required   />
      <label className="label" htmlFor="senha">Senha</label>
      </form>
    </div>
                    {/* <input disabled="{true}" className="form-control form-control-lg inputForm" type="password" placeholder="Informe sua senha"/> */}
                    {/* <FormControl >
                        <TextField label="Senha" type="password" size="small"
                            onChange={event => changes(event, 'senha')}
                            value={formPage.senha}
                            helperText={indicarErros && !formPage.senha && 'Campo obrigatório'}
                            error={indicarErros && !formPage.senha ? true : false}
                            inputprops={{ maxLength: 20 }}
                        />
                    </FormControl> */}
                </div>
                <div className="col-sm-12 alignSenha">
                    <button   className="btn btn-link textSenha" onClick={modalSenha}>
                        Esqueci minha senha
                    </button>
                </div>
                <div className="col-sm-12 padB20">
                    <button   className=" btnEntrar " onClick={logar}>
                        Entrar
                    </button>
                </div>
                <div className="col-sm-12">
                    <button className="btnPrimeiraVez" onClick={modalPrimeiroAcesso}>
                        Primeira vez aqui? <b className="textCadastrese">Cadastre-se.</b>
                    </button>
                </div>
            </div>
            
        </div>
    )
}

export default FormLogin;