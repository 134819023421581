import React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import services from '../../services';
import { trackPromise } from 'react-promise-tracker';
import globals from '../../globals';
import qs from "qs";

export default function Espelho(props) {
  //const user = useLocation();
  const history = useHistory();

  const user = qs.parse(props.location.search, {

    ignoreQueryPrefix: true

  });

  function loginEspelho() {
    fetch(globals.REACT_APP_APIROOT_AUTO + 'login/espelho', {
      method: 'POST',
      headers: { Authorization: 'Bearer ' + user.token,
                    "Content-Type": "application/json",
     },
      body: JSON.stringify({ userId: user.userId }),
    })
        
      .then((user) => {
        return user.json();
      })
      .then((user) => {

        let objUser = user.data;

        localStorage.setItem('authSistema', JSON.stringify(objUser));
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('product', 'auto');
        localStorage.setItem('isUserCompany', false);

        //localStorage.setItem('isEspelho', user.data.claim.map(e => e.value = "JwtAcessoEspelho"));

        const claimArray = user.data.claims;
        console.log(user.data.claims);

        // Inicializa a variável que indicará se "JwtAcessoEspelho" foi encontrado
        let isEspelho = false;

        // Percorre o array para procurar "JwtAcessoEspelho"
        for (const e of claimArray) {
          if (e.value === "JwtAcessoEspelho") {
            isEspelho = true;
            break; // Se encontrado, não é necessário continuar a busca
          }
        }

        // Define o valor correspondente no localStorage
        localStorage.setItem('isEspelho', isEspelho);


        if (
          user.data.profile.cadastroCompleto &&
          user.data.profile.dataNascimento
        ) {
          history.push(`/`);
        } else {
          history.push(`/meu-cadastro`);
        }
      });
  }

  useEffect(() => {
    loginEspelho();
  }, []);

  return <></>;
}
