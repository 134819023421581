import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { getConteudoList } from '../../services/api';
import ConteudoCard from '../../components/ConteudoCard';
import ModalLoading from '../../components/ModalLoading';

const Conteudo = () => {

    const history = useHistory();
    const [conteudo, setConteudo] = useState([])
    const [modalLoading, setModalLoading] = useState(false);
    

    const getConteudo = async() => {
        setModalLoading(true)
        const data = await getConteudoList();
        setModalLoading(false)
        setConteudo(data.data)
    } 
    useEffect(()=>{
        getConteudo()
    },[])

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                Informações
            </div>
            <div className={styles.title}>
                Confira aqui diferentes conteúdos e informações para te apoiar nos seus negócios.
            </div>
            <div className={styles.acoesWrapper}>
                {conteudo?.map(
                    (q) => <ConteudoCard conteudo={q}/>
                )}
            </div>
            {
                conteudo.items?.length === 0 &&
                <div className={styles.verMaisWrapper}> Não existem dados para exibir </div>
            }
            <ModalLoading open={modalLoading}  size={"lg"} />
        </div>
    );
  
}

export default Conteudo;