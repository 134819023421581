import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { getBeneficioList } from '../../services/api';
import BeneficioGaleria from '../../components/BeneficioGaleria';
import homeMainImg from '../../assets/img/homeMain.png'
import Loading from '../../components/Loading'
import Banner from '../../components/Banner';

const GaleriaVitrine = () => {

    const history = useHistory();
    const [beneficios, setBeneficios] = useState([])
    const [verMais, setVerMais] = useState(true)
    const [modalLoading, setModalLoading] = useState(false);
    const [filter, setFilter] = useState( {page : 1, pageSize: 10, query: "" } );
    

    const getBeneficios = async() => {
        setModalLoading(true)
        const data = await getBeneficioList(filter);
        setModalLoading(false)
        setBeneficios(data.data)
    } 
    useEffect(()=>{
        (beneficios && beneficios.items?.length > 0 && beneficios.page === beneficios.totalPages ) ? setVerMais(true) : setVerMais(false); 
        getBeneficios()
    },[])

    const addPage = async () => {
        setModalLoading(true)
        const data = await getBeneficioList(filter);
        setModalLoading(false)
        const f = filter;
        f.page += 1;
        setFilter(f);
        getBeneficios([...beneficios, ...data.data])
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                 Resgates 
            </div>
            <div className={styles.mainBannerWrapper}>
                <Banner screen={"vitrine"}/>
            </div>
            <div className={styles.subTitle}>
                Confira tudo o que você pode aproveitar no BV relacionar
            </div>
            <div className={styles.acoesWrapper}>
                {beneficios && beneficios.items?.map(
                    (b) => <BeneficioGaleria beneficio={b}/>
                )}
            </div>
            {
                beneficios.items?.length === 0 &&
                <div className={styles.verMaisWrapper}> Não existem dados para exibir </div>
            }
            { verMais && (
                <div className={styles.verMaisWrapper}>
                    <button className={`customButton ${styles.verMaisButton}`} onClick={addPage} >
                        Ver mais
                    </button>
                </div>
            )}
            { modalLoading ? <Loading /> : null }
        </div>
    );
  
}

export default GaleriaVitrine;