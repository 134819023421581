import { useState, useEffect } from 'react';

import services from '../services';

export const useGetNavegationBlocked = () => {
  const [navegationBlocked, setNavegationBlocked] = useState(null);
  useEffect(() => {
    function handleStatusChange(isNavegationBlocked) {
      setNavegationBlocked(isNavegationBlocked);
    }
    const isNavegationBlocked = services.storage.get('isNavegationBlocked');
    if (isNavegationBlocked) { handleStatusChange(isNavegationBlocked) }
  }, []);

  return navegationBlocked;
}